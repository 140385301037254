import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';

export const PlayerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MuiTableContainer = styled(Box)`
  *.MuiTableCell-sizeSmall {
    padding: 6px 10px 6px 10px;
  }
`;

export const ScoutsTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const MuiButton = styled(Button)`
  &.MuiButton-outlinedSizeSmall {
    /* font-size: 1.2rem; */
    line-height: unset;
  }
`;

export const Scouts = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ScoutsContainer = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-top: 3px;
  }
`;

export const TitleContainer = styled.div`
  padding: 5px;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  img {
    height: 30px;
    margin-right: 5px;
  }
`;

export const TableRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
`;
export const InnerTableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 16px;
  align-items: center;
`;
