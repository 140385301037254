import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';
import DefaultLayout from '../pages/_layout/default';
import AuthLayout from '../pages/_layout/auth';
import AdminLayout from '../pages/_layout/admin';

import { store } from '../store';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isAuth,
  isAdmin,
  title,
  ...rest
}) {
  const { signed, admin } = store.getState().auth;

  // api.interceptors.request.use(
  //   response => {
  //     return response;
  //   },
  //   err => {
  //     return err;
  //   }
  // );

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  // if (isPrivate) {
  //   if (profile && !member && !admin)
  //     getUser(profile.id).then((res) =>
  //       store.dispatch(saveUsersSuccess(res.data))
  //     );
  // }

  if (signed && isAuth) {
    return <Redirect to="/dashboard" />;
  }
  if (!admin && isAdmin) {
    return <Redirect to="/dashboard" />;
  }

  // const Layout = isAuth ? AuthLayout : DefaultLayout;

  // eslint-disable-next-line no-nested-ternary
  const Layout = isAuth ? AuthLayout : isPrivate ? AdminLayout : DefaultLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout title={title}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isAuth: PropTypes.bool,
  isAdmin: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isAuth: false,
  isAdmin: false,
  title: '',
};
