import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ScrollBar from 'react-perfect-scrollbar';
import { Box, useMediaQuery } from '@material-ui/core';
import TableWrapper from '../../TableWrapper/index';
import Points from '../../PlayerComponents/Points';
import MuiTheme from '../../../styles/MuiTheme';

export default function RoundAppreciationTable({ data = [] }) {
  const mdUp = useMediaQuery(MuiTheme.breakpoints.up('md'));

  const Wrapper = mdUp ? ScrollBar : Box;

  return (
    <TableWrapper title="Variação por rodada">
      <Wrapper style={{ maxHeight: mdUp ? 380 : undefined }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell size="small" align="center">
                Rodada
              </TableCell>
              <TableCell size="small" align="center">
                Pontos
              </TableCell>
              <TableCell size="small" align="center">
                Variação
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.rodada}>
                <TableCell size="small" align="center">
                  #{row.rodada}
                </TableCell>
                <TableCell size="small" align="center">
                  {row.pontos.toFixed(2)}
                </TableCell>
                <TableCell size="small" align="center">
                  <Points value={row.variacao}>
                    C${row.variacao.toFixed(2)}
                  </Points>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Wrapper>
    </TableWrapper>
  );
}
