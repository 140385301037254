import { createMuiTheme } from '@material-ui/core';
import colors from './colors';

export default createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.accent,
    },
  },
  typography: {
    // Diz ao Material-UI qual é o font-size no elemento html.
    htmlFontSize: 10,
  },
});
