import React, { useState, useEffect } from 'react';

import { Grid, Box, Container, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Pagination from '@material-ui/lab/Pagination';
import Select from 'react-select/async';

import { getBasicAverage } from '../../services/catimbaStats/statistics';
import PlayerCard from '../../components/PlayerComponents/PlayerCard';
import { getFilter } from '../../services/catimbaStats/filter';
import selectStyles from '../../styles/selectStyles';
import SearchBar from '../../components/SearchBar';
import NoResults from '../../components/NoResults';
import Card from '../../components/Card';

function BasicAverage() {
  const [positions, setPositions] = useState();
  const [status, setStatus] = useState(7);
  const [clubs, setClubs] = useState();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState();
  const [totalPages, setTotalPages] = useState(1);

  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getBasicAverage({
      page,
      idPosicao: positions || undefined,
      idStatus: status || undefined,
      idClube: clubs || undefined,
      nomeAtleta: search,
    })
      .then((res) => {
        setTotalPages(res.data.totalPages);
        setPlayers(res.data.content);
      })
      .finally(() => setLoading(false));
  }, [page, status, clubs, search, positions]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <Card>
            <Typography variant="body2">
              Média Básica é a Pontuação do Jogador sem (SG, DP, GC, CV, G, A, PP)
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={2}>
          <Select
            styles={selectStyles}
            defaultValue={{ label: 'Todas as posições', value: undefined }}
            loadOptions={() => getFilter('posicoes', 'id', 'descricao')}
            onChange={(option) => setPositions(option.value)}
            cacheOptions
            defaultOptions
            isSearchable={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <Select
            styles={selectStyles}
            defaultValue={{ label: 'Provável', value: 7 }}
            loadOptions={() => getFilter('status', 'id', 'descricao')}
            onChange={(option) => setStatus(option.value)}
            cacheOptions
            defaultOptions
            isSearchable={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <Select
            styles={selectStyles}
            defaultValue={{ label: 'Todos clubes', value: undefined }}
            loadOptions={() => getFilter('clubes', 'id', 'descricao')}
            onChange={(option) => setClubs(option.value)}
            cacheOptions
            defaultOptions
            isSearchable={false}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <SearchBar
            // disabled={loading}
            onRequestSearch={setSearch}
            onCancelSearch={() => setSearch(undefined)}
          />
        </Grid>
        {!loading && !players.length && (
          <Grid item xs={12}>
            <NoResults />
          </Grid>
        )}
        {!loading
          ? players &&
            players.map((player) => (
              <Grid item xs={12}>
                <PlayerCard
                  playerId={player.id}
                  picture={player.foto}
                  teamShield={player.fotoClube}
                  position={player.detalhePosicao}
                  playerName={player.nome}
                  teamName={player.nomeClube}
                  customStatusValue={
                    player.mediaBasica && player.mediaBasica.toFixed(2)
                  }
                  customStatusTitle="Média básica"
                />
              </Grid>
            ))
          : [1, 2, 3, 4, 5, 6].map((e) => (
              <Grid key={e} item xs={12}>
                <Box width="100%">
                  <Skeleton variant="rect" width="100%" height={100} />
                </Box>
              </Grid>
            ))}
        {players && !!players.length && (
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Pagination
              count={totalPages}
              page={page + 1}
              onChange={(_, p) => setPage(p - 1)}
              siblingCount={0}
              boundaryCount={1}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default BasicAverage;
