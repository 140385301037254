import React from 'react';
import { Typography } from '@material-ui/core';
import Card from '../Card';

import { Container } from './styles';

function AdminInfoCard({ title, value }) {
  return (
    <Card style={{ height: '100%' }}>
      <Container>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="h4">{value}</Typography>
      </Container>
    </Card>
  );
}

export default AdminInfoCard;
