import React from 'react';
import { MdWarning } from 'react-icons/md';

import { Container } from './styles';

// eslint-disable-next-line react/prop-types
export default function WarnMessage({ onCancel, onConfirm, children }) {
  return (
    <Container>
      <MdWarning color="#ee4d64" size={75} />
      <span>{children}</span>
      <div>
        <button type="button" className="btn btn--cancel" onClick={onCancel}>
          Cancelar
        </button>
        <button type="button" className="btn btn--confirm" onClick={onConfirm}>
          Confirmar
        </button>
      </div>
    </Container>
  );
}
