import React from 'react';

import {
  Container,
  ImgInfo,
  TitleInfo,
  TextInfo,
  TextContainer,
} from './styles';

export default function InfoCard({ img, title, text }) {
  return (
    <Container>
      <ImgInfo src={img} />
      <TextContainer>
        <TitleInfo>{title}</TitleInfo>
        <TextInfo>{text}</TextInfo>
      </TextContainer>
    </Container>
  );
}
