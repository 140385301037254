import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ScrollBar from 'react-perfect-scrollbar';
import TableWrapper from '../../TableWrapper/index';
import Points from '../../PlayerComponents/Points';
import PlayerInfo from '../../PlayerComponents/PlayerInfo';

import { PointsContainer, Row, Label, PointsRow } from './styles';
import TwoTimes from '../../TwoTimes';

export default function CapitainTable({ data = [], totalPoints, average }) {
  return (
    <TableWrapper title="Capitão">
      <PointsContainer>
        <Row>
          <Label>Total de Pontos:</Label>
          <Points value={totalPoints}>
            {totalPoints ? totalPoints.toFixed(2) : '0.00'}
          </Points>
        </Row>
        <Row>
          <Label>Média pontos:</Label>
          <Points value={average}>{average.toFixed(2)}</Points>
        </Row>
      </PointsContainer>
      <ScrollBar style={{ maxHeight: 380 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell size="small" align="center">
                Rodada
              </TableCell>
              <TableCell size="small" align="left">
                Jogador
              </TableCell>
              <TableCell size="small" align="right  ">
                Pontos
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.rodada}>
                <TableCell size="small" align="center">
                  #{row.rodada}
                </TableCell>
                <TableCell align="center">
                  <PlayerInfo
                    size="small"
                    playerName={row.nome}
                    position={row.posicao}
                    picture={row.foto}
                    imgStyle={{
                      width: 30,
                      height: 30,
                    }}
                    horizontal
                  />
                </TableCell>
                <TableCell size="small" align="right">
                  <PointsRow>
                    <TwoTimes value={row.pontos} />

                    <Points
                      style={{
                        fontWeight: 'bold',
                        fontSize: '1.6rem',
                        marginLeft: 5,
                      }}
                      value={row.pontosCapitao}
                    >
                      {row.pontosCapitao}
                    </Points>
                  </PointsRow>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ScrollBar>
    </TableWrapper>
  );
}
