import React from 'react';

import { Typography, Avatar } from '@material-ui/core';
import Card from '../../Card';

import { Container, StatsContainer } from './styles';
import Points from '../Points';

export function SingleStat({
  description,
  details,
  value,
  horizontal = false,
  image,
  color,
}) {
  return (
    <StatsContainer horizontal={horizontal}>
      <h3>{description}</h3>
      {image && <Avatar src={image} />}
      <div>
        <p>
          <Points
            value={
              (color === 'postive' && 1) ||
              (color === 'negative' && -1) ||
              value
            }
          >
            {value || '--'}
          </Points>
        </p>
        <p>{details}</p>
      </div>
    </StatsContainer>
  );
}

function PlayerStatsContainer({ title, informations = [], credits }) {
  return (
    <Card>
      <Container>
        <Typography variant="h5">{title}</Typography>
        {informations.map((info) => (
          <SingleStat
            key={info.description}
            description={info.description}
            details={info.details}
            value={info.value}
          />
        ))}
        {/* {credits && (
          <Box width="100%" display="flex" justifyContent="center">
            <Typography variant="caption" align="center">
              Cálculo de valorização fornecido em parceria com{' '}
              <a href="https://gurudocartola.com" target="blank">
                Guru do Cartola
              </a>
            </Typography>
          </Box>
        )} */}
      </Container>
    </Card>
  );
}

export default PlayerStatsContainer;
