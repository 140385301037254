import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';
import { MdCancel } from 'react-icons/md';
import { toast } from 'react-toastify';
import AdminTableContainer from '../../components/AdminTableContainer';
import {
  getUserPayments,
  getUser,
  cancelPayment,
} from '../../services/catimbaStats/user';
import AdminTableLoading from '../../components/AdminTableLoading';
import { formatShortDateAndTime } from '../../utils/formatDate';
import NoResults from '../../components/NoResults';
import { newMember, udpdateUser } from '../../store/modules/auth/actions';
import AdminActionButtons from '../../components/AdminActionButtons';
import ModalContainer from '../../components/ModalContainer';
import WarnMessage from '../../components/WarnMessage';

export default function MyPayments() {
  const dispatch = useDispatch();
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [popup, setPopup] = useState(0);

  const user = useSelector((state) => state.auth.profile);
  const handleGetPayments = useCallback(() => {
    if (user) {
      setLoading(true);

      getUserPayments(
        location.state && location.state.user
          ? location.state.user.id
          : user.id,
        {
          page,
        }
      )
        .then((res) => {
          setPayments(res.data.content);
          setTotalPages(res.data.totalPages);
        })
        .finally(() => setLoading(false));
    }
  }, [user, location.state, page]);

  const cancelOrder = useCallback(
    async (id) => {
      setLoading(true);
      cancelPayment(id)
        .then((res) => {
          toast.success(res.data.mensagem || 'Pagamento cancelado com sucesso');
          handleGetPayments();
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            toast.error(
              err.response.data.mensagem ||
                'Ocorreu um erro ao cancelar o pagamento'
            );
            return;
          }
          toast.error('Ocorreu um erro');
          setLoading(false);
        });
    },
    [handleGetPayments]
  );

  useEffect(() => {
    handleGetPayments();
  }, [handleGetPayments]);

  useEffect(() => {
    if (
      (location && location.search.includes('collection_status=approved')) ||
      !user.assinante
    ) {
      dispatch(newMember());
      getUser(user.id).then((res) => dispatch(udpdateUser(res.data)));
    }
  }, [dispatch, location, user.id, user.assinante]);

  return location.pathname.match('meus-pagamentos') ||
    (location.pathname.match('usuario') &&
      location.state &&
      location.state.user) ? (
    <>
      <ModalContainer open={!!popup} onClose={() => setPopup(0)} position="5px">
        <WarnMessage
          onCancel={() => setPopup(0)}
          onConfirm={() => cancelOrder(popup)}
        >
          Tem certeza que deseja{' '}
          {popup.option === 'warn'
            ? 'excluir o usuário?'
            : 'cancelar o pagamento para o usuário?'}
        </WarnMessage>
      </ModalContainer>
      <AdminTableContainer
        title={
          location.state && location.state.user
            ? `Pagamentos ${location.state.user.nome}`
            : 'Meus Pagamentos'
        }
        totalPages={totalPages}
        currentPage={page}
        onPageChange={setPage}
      >
        {!loading ? (
          <TableContainer>
            {!payments.length ? (
              <NoResults text="Não existem resultados para exibir" />
            ) : (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell size="small">ID</TableCell>
                    <TableCell>Plano</TableCell>
                    <TableCell align="right">Valor</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Data de pagamento</TableCell>
                    <TableCell align="right">Meio de pagamento</TableCell>
                    <TableCell align="right">Validade</TableCell>
                    {location.state &&
                      location.state.user &&
                      user.perfil <= 2 && (
                        <TableCell align="right">Ações</TableCell>
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map((payment) => (
                    <TableRow key={payment.id}>
                      <TableCell size="small">{payment.id}</TableCell>
                      <TableCell component="th" scope="payment">
                        {payment.descricaoPlano}
                      </TableCell>
                      <TableCell align="right">
                        R$ {payment.valor.toFixed(2).replace('.', ',')}
                      </TableCell>
                      <TableCell align="right">
                        {payment.descricaoStatus}
                      </TableCell>
                      <TableCell align="right">
                        {formatShortDateAndTime(payment.data)}
                      </TableCell>
                      <TableCell align="right">
                        {payment.descricaoMeioPagamento}
                      </TableCell>
                      <TableCell align="right">
                        {payment.fimVigencia &&
                          payment.fimVigencia.split('-').reverse().join('/')}
                      </TableCell>
                      {location.state &&
                        location.state.user &&
                        user.perfil <= 2 && (
                          <TableCell align="right">
                            <AdminActionButtons
                              size="small"
                              title="Cancelar pagamento"
                              onClick={() => setPopup(payment.id)}
                            >
                              <MdCancel />
                            </AdminActionButtons>
                          </TableCell>
                        )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        ) : (
          <AdminTableLoading />
        )}
      </AdminTableContainer>
    </>
  ) : (
    <Redirect to="/usuarios" />
  );
}
