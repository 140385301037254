import styled from 'styled-components';
import { Container as MuiContainer } from '@material-ui/core';
import respond from '../../styles/respond';

export const Container = styled(MuiContainer)`
  & .MuiTableCell-root {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
export const Title = styled.div`
  padding: 1rem 1.6rem;
  display: flex;
  justify-content: space-between;

  ${respond.tabPort} {
    flex-direction: column;
    align-items: center;
  }
`;

export const SearchContainer = styled.div`
  margin-bottom: 2rem;
  max-width: 40rem;
  display: flex;
  align-items: center;

  > div {
    margin-right: 1rem;
  }
  svg {
    font-size: 2.5rem;
  }
`;
