import React from 'react';

import { Container, Wrapper } from './styles';

function ModalContainer({
  open,
  onClose,
  children,
  position,
  disableBackdropClick = false,
}) {
  return (
    <Container
      open={open}
      onClose={onClose}
      disableBackdropClick={disableBackdropClick}
    >
      <Wrapper right={position}>
        <button type="button" onClick={onClose}>
          &times;
        </button>
        {children}
      </Wrapper>
    </Container>
  );
}

export default ModalContainer;
