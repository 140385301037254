import React from 'react';

import { Container, Text, Number } from './styles';
import Points from '../../PlayerComponents/Points';

function NumberInfo({ title, value, isColored, children, ...rest }) {
  return (
    <Container {...rest}>
      <Text>{title}</Text>
      {children}

      {isColored ? (
        <Points value={value}>
          <Number>{value}</Number>
        </Points>
      ) : (
        <>
          <Number>{value}</Number>
        </>
      )}
    </Container>
  );
}

export default NumberInfo;
