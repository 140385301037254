import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Box, Container, Typography } from '@material-ui/core';
import Select from 'react-select/async';
import Skeleton from '@material-ui/lab/Skeleton';
import ScoutsComparisonTable from '../../components/GameComponents/ScoutsComparisonTable';
import { getScoutsComparison } from '../../services/catimbaStats/statistics';
import selectStyles from '../../styles/selectStyles';
import { getFilter } from '../../services/catimbaStats/filter';
import Card from '../../components/Card';

// import { Container } from './styles';

function ScoutsComparison() {
  const [idPosicao, setIdPosicao] = useState(0);
  const [tipo, setTipo] = useState(1);
  const [scout, setScout] = useState('G');
  const [rodada, setRodada] = useState(0);
  const [scoutDetail, setScoutDetail] = useState('Gols');

  const [conquered, setConquered] = useState([]);
  const [given, setGiven] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getScoutsComparison({
      scout,
      idPosicao,
      rodada,
      tipo,
    })
      .then((res) => {
        setConquered(res.data.conquistadosCedidos);
        setGiven(res.data.cedidosConquistados);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, [scout, idPosicao, rodada, tipo]);

  const highestGiven = useMemo(() => {
    let highestGivenHost = 1;
    let highestConqueredVis = 1;

    given.forEach((e) => {
      highestGivenHost =
        Number(e.cedidosCasa) > highestGivenHost
          ? Number(e.cedidosCasa)
          : highestGivenHost;

      highestConqueredVis =
        Number(e.conquistadosVisitante) > highestConqueredVis
          ? Number(e.conquistadosVisitante)
          : highestConqueredVis;
    });

    return { highestGivenHost, highestConqueredVis };
  }, [given]);

  const highestConquered = useMemo(() => {
    let highestGivenVis = 1;
    let highestConqueredHost = 1;

    conquered.forEach((e) => {
      highestGivenVis =
        Number(e.cedidosVisitante) > highestGivenVis
          ? Number(e.cedidosVisitante)
          : highestGivenVis;

      highestConqueredHost =
        Number(e.conquistadosCasa) > highestConqueredHost
          ? Number(e.conquistadosCasa)
          : highestConqueredHost;
    });

    return { highestGivenVis, highestConqueredHost };
  }, [conquered]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <Typography variant="body2">
              Nesta seção, mostramos um cruzamento entre scouts conquistados e
              scouts cedidos entre os times que se enfrentam na rodada atual.
              <br />
              Obs: Não são considerados gols-contra (GC) no cruzamento de Gols.
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Select
            styles={selectStyles}
            defaultValue={{ label: 'Casa x Fora', value: 1 }}
            loadOptions={() => getFilter('tiposCruzamento', 'id', 'descricao')}
            onChange={(option) => setTipo(option.value)}
            cacheOptions
            defaultOptions
            isSearchable={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Select
            styles={selectStyles}
            defaultValue={{ label: 'Todas as rodadas', value: 0 }}
            loadOptions={() =>
              getFilter('rodadasCruzamento', 'id', 'descricao')
            }
            onChange={(option) => setRodada(option.value)}
            cacheOptions
            defaultOptions
            isSearchable={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Select
            styles={selectStyles}
            defaultValue={{ label: 'Todos as posições', value: 0 }}
            loadOptions={() => getFilter('posicoes', 'id', 'descricao')}
            onChange={(option) => setIdPosicao(option.value)}
            cacheOptions
            defaultOptions
            isSearchable={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Select
            styles={selectStyles}
            defaultValue={{ label: 'Gols', value: 'G' }}
            loadOptions={() =>
              getFilter('scoutsCruzamento', 'codigo', 'descricao')
            }
            onChange={(option) => {
              setScout(option.value);
              setScoutDetail(option.label);
            }}
            cacheOptions
            defaultOptions
            isSearchable={false}
          />
        </Grid>
        {!loading ? (
          <>
            <Grid item xs={12} lg={6}>
              <ScoutsComparisonTable
                title={scoutDetail}
                rightSide={`Conquistado (${tipo === 1 ? 'Casa' : 'Total'})`}
                leftSide={`${' '}${' '}${' '}${' '}${' '}${' '}Cedido (${
                  tipo === 1 ? 'Fora' : 'Total'
                })`}
                data={conquered}
                highestLeft={highestConquered.highestConqueredHost}
                highestRight={highestConquered.highestGivenVis}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <ScoutsComparisonTable
                title={scoutDetail}
                inverse
                leftSide={`Conquistado (${tipo === 1 ? 'Fora' : 'Total'})`}
                rightSide={`Cedido (${
                  tipo === 1 ? 'Casa' : 'Total'
                })${' '}${' '}${' '}${' '}${' '}${' '}`}
                data={given}
                highestLeft={highestGiven.highestGivenHost}
                highestRight={highestGiven.highestConqueredVis}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} lg={6}>
              <Box width="100%">
                <Skeleton variant="rect" width="100%" height={600} />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box width="100%">
                <Skeleton variant="rect" width="100%" height={600} />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
}

export default ScoutsComparison;
