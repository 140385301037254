import React, { useMemo } from 'react';
import HSBar from 'react-horizontal-stacked-bar-chart';
import Name from '../../Name';
import Card from '../../Card';
import {
  Container,
  TeamInfo,
  Info,
  TitleContainer,
  StatusContainer,
  Status,
  StatusText,
  HSBarContainer,
} from './styles';

function SingleStatus({ value, title, highest }) {
  return (
    <Status>
      <StatusText>
        <span>{title}</span>
        <strong>{value.toFixed(2)}</strong>
      </StatusText>
      <HSBarContainer>
        <HSBar
          showTextUp
          id="hsbarAim"
          data={[
            {
              value,
              description: ' ',
              color: '#02A0FC',
            },
            {
              value: highest - Number(value),
              description: ' ',
              color: '#CCF8FE',
            },
          ]}
        />
      </HSBarContainer>
    </Status>
  );
}
function GoalsStats({
  shield,
  name,
  generalAvg,
  asHostAvg,
  takenGeneral,
  takenAsHost,
  generalGoalsDifference,
  host = true,
  position,
}) {
  const highestValue = useMemo(() => {
    let highest = 1;
    if (highest < generalAvg) {
      highest = generalAvg;
    }
    if (highest < asHostAvg) {
      highest = asHostAvg;
    }
    if (highest < takenGeneral) {
      highest = takenGeneral;
    }
    if (highest < takenAsHost) {
      highest = takenAsHost;
    }
    return highest;
  }, [generalAvg, asHostAvg, takenGeneral, takenAsHost]);
  return (
    <Card>
      <Container>
        <TitleContainer>
          <TeamInfo>
            <img src={shield} alt={`Escudo ${shield}`} />
            <Name first={name} second={host ? 'Mandante' : 'Visitante'} />
          </TeamInfo>
          <Info>
            {position}° melhor
            <br />
            {host ? 'mandante' : 'visitante'}
          </Info>
        </TitleContainer>
        <StatusContainer>
          <SingleStatus
            title="Media geral gols"
            value={generalAvg}
            highest={highestValue}
          />
          <SingleStatus
            title={`Média gols como ${host ? 'mandante' : 'visitante'}`}
            value={asHostAvg}
            highest={highestValue}
          />
          <SingleStatus
            title="Média geral gols sofridos"
            value={takenGeneral}
            highest={highestValue}
          />
          <SingleStatus
            title={`Média gols sofridos como ${
              host ? 'mandante' : 'visitante'
            }`}
            value={takenAsHost}
            highest={highestValue}
          />
        </StatusContainer>
      </Container>
    </Card>
  );
}

export default GoalsStats;
