import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar, Link } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import SmallComparison from '../SmallComparison';
import {
  PlayerContainer,
  MuiTableContainer,
  ScoutsTitle,
  MuiButton,
  Scouts,
  ScoutsContainer,
  TitleContainer,
  TableRowContainer,
  InnerTableRow,
} from './styles';
import Name from '../../Name';
import ScoutPill from '../../PlayerComponents/ScoutPill';
import scoutsToArrayConverter from '../../../utils/scoutsToArrayConverter';
import Points from '../../PlayerComponents/Points';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row({ athleteId, photo, name, position, avg, games = [] }) {
  const [open, setOpen] = React.useState(false);

  const history = useHistory();
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root} size="small">
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="left" size="small">
          <PlayerContainer>
            <Avatar src={photo} />
            <Name size="small" first={name} second={position} />
          </PlayerContainer>
        </TableCell>
        <TableCell align="right">{avg.toFixed(2)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin="1px 0px">
              <Scouts>
                <ScoutsTitle>
                  <Typography variant="h6" gutterBottom component="div">
                    Scouts
                  </Typography>

                  <MuiButton
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    component={Link}
                    onClick={() => history.push(`/atletas/${athleteId}`)}
                  >
                    Detalhes
                  </MuiButton>
                </ScoutsTitle>
                {/* <ScoutsContainer>
                  {scoutsToArrayConverter(positiveScouts).map((scout) => (
                    <ScoutPill key={scout}>{scout}</ScoutPill>
                  ))}
                  {scoutsToArrayConverter(negativeScouts).map((scout) => (
                    <ScoutPill key={scout} negative>
                      {scout}
                    </ScoutPill>
                  ))}
                </ScoutsContainer> */}
              </Scouts>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell size="small">Rodada</TableCell>
                    <TableCell size="small" align="center">
                      Jogo
                    </TableCell>
                    <TableCell align="right" size="small">
                      Pontos
                    </TableCell>
                  </TableRow>
                </TableHead>
                {/* <TableBody> */}

                {/* </TableBody> */}
              </Table>
              {games.map((game) => (
                <TableRowContainer key={game.rodada}>
                  <InnerTableRow>
                    <div component="th" size="small">
                      #{game.rodada}
                    </div>
                    <div align="center" size="small">
                      <SmallComparison
                        hostScore={game.placarClubeCasa}
                        hostShield={game.fotoClubeCasa}
                        visScore={game.placarClubeVisitante}
                        visShield={game.fotoClubeVisitante}
                      />
                    </div>
                    <div align="right" size="small">
                      <Points
                        value={game.pontos}
                        style={{ fontWeight: 'bold' }}
                      >
                        {game.pontos}
                      </Points>
                    </div>
                  </InnerTableRow>
                  <ScoutsContainer>
                    {scoutsToArrayConverter(game.scoutsPositivo).map(
                      (scout) => (
                        <ScoutPill key={scout}>{scout}</ScoutPill>
                      )
                    )}
                    {scoutsToArrayConverter(game.scoutsNegativo).map(
                      (scout) => (
                        <ScoutPill key={scout} negative>
                          {scout}
                        </ScoutPill>
                      )
                    )}
                  </ScoutsContainer>
                </TableRowContainer>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function EscalationTable({ athletes, teamShield }) {
  return (
    <MuiTableContainer component={Paper}>
      <TitleContainer>
        <img src={teamShield} alt="Escalação" />
        <Typography variant="h5">Escalação Provável</Typography>
      </TitleContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell size="small" />
            <TableCell />
            <TableCell align="right">Média</TableCell>
            {/* <TableCell align="right">Média fora</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {athletes &&
            athletes.map((athlete) => (
              <Row
                key={athlete.idAtleta}
                athleteId={athlete.idAtleta}
                photo={athlete.foto}
                name={athlete.nome}
                position={athlete.posicao}
                avg={athlete.media}
                negativeScouts={athlete.scoutsNegativo}
                positiveScouts={athlete.scoutsPositivo}
                games={athlete.jogos}
              />
            ))}
        </TableBody>
      </Table>
    </MuiTableContainer>
  );
}
