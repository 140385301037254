import React, { useState, useEffect, useCallback } from 'react';

import { Grid, Box, Button, useMediaQuery, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import Pagination from '@material-ui/lab/Pagination';
import { toast } from 'react-toastify';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import TeamInfo from '../../components/MyTeamComponents/TeamInfo';
import WarnMessage from '../../components/WarnMessage';
import { Container } from './styles';
import AddTeam from '../../components/AddTeam';
import {
  getUserTeams,
  addTeam,
  deleteTeam,
  updateTeamShield,
} from '../../services/catimbaStats/user';
import MuiTheme from '../../styles/MuiTheme';
import NoResults from '../../components/NoResults';
import Card from '../../components/Card';
import ModalContainer from '../../components/ModalContainer';

function MyTeams() {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [search, setSearch] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [warn, setWarn] = useState({ show: false, team: null });

  const user = useSelector((state) => state.auth.profile);
  const desktop = useMediaQuery(MuiTheme.breakpoints.up('md'));

  const handleGetTeam = useCallback(() => {
    if (user) {
      setLoading(true);
      setPopupOpen(false);
      getUserTeams(user.id, { page })
        .then((res) => {
          setTeams(res.data.content);
          setTotalPages(res.data.totalPages);
        })
        .finally(() => setLoading(false));
    }
  }, [page, user]);

  useEffect(() => {
    handleGetTeam();
  }, [handleGetTeam]);

  const handleAddTeam = useCallback(
    ({ teamId, userId, cartola, escudo, nome, slug, assinante }) => {
      setLoading(true);
      addTeam({
        teamId,
        userId,
        cartola,
        escudo,
        nome,
        slug,
        assinante,
      })
        .then(() => {
          toast.success('Time adicionado com sucesso!');
          handleGetTeam();
        })
        .catch((err) => {
          if (err.response.data.status === 405) {
            toast.warn(
              err.response.data.mensagem || 'Time já adicionado na sua conta!'
            );
          } else {
            toast.error('Ocorreu um erro ao adicionar o time');
          }
          setLoading(false);
        });
    },
    [handleGetTeam]
  );

  const handleDeleteTeam = useCallback(
    (id) => {
      deleteTeam({ id, idUsuario: user.id })
        .then(() => {
          handleGetTeam();
          toast.success('Time foi excluído com sucesso');
          setWarn({
            show: false,
            team: null,
          });
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.mensagem) {
            toast.error(
              err.response.data.mensagem || 'Ocorreu um erro ao deletar o time'
            );
            return;
          }
          toast.error('Ocorreu um erro ao deletar o time');
        });
    },
    [handleGetTeam, user.id]
  );

  const handleUpdateTeamShield = useCallback(() => {
    if (user) {
      setLoading(true);
      updateTeamShield(user.id)
        .then((res) => {
          toast.success(res.data.mensagem || 'Escudo atualizado com sucesso!');
          handleGetTeam();
        })
        .catch((err) => {
          setLoading(true);

          if (err && err.response && err.response.data) {
            toast.warn(err.response.data.mensagem || 'Ocorreu um erro');
            return;
          }
          toast.warn('Ocorreu um erro');
        });
    }
  }, [handleGetTeam, user]);

  return (
    <>
      <AddTeam
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        onSelect={(team) => {
          handleAddTeam({
            ...team,
            cartola: team.nome_cartola,
            escudo: team.url_escudo_png,
            camisa: team.url_camisa_svg,
            teamId: team.time_id,
            userId: user && user.id,
          });
        }}
      />
      <ModalContainer
        open={warn.show}
        onClose={() => setWarn(false)}
        position="5px"
      >
        <WarnMessage
          onCancel={() =>
            setWarn({
              show: false,
              team: null,
            })
          }
          onConfirm={() => handleDeleteTeam(warn.team.id)}
        >
          Tem certeza que deseja excluir o Time?
        </WarnMessage>
      </ModalContainer>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card>
              <Typography variant="body2">
                Aqui você acompanha estatísticas sobre o seu desempenho e também escalação  
                com  valorização em tempo real.
              </Typography>
            </Card>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          alignItems="center"
          style={{ marginBottom: '1rem', marginTop: '1rem' }}
        >
          <Grid item xs={12} lg={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => setPopupOpen(true)}
              startIcon={<AddIcon />}
            >
              Novo time
            </Button>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              startIcon={<RefreshIcon />}
              onClick={handleUpdateTeamShield}
              disabled={loading}
            >
              Atualizar
            </Button>
          </Grid>
          {/* <Grid item xs={12} lg={4}>
            <SearchBar
              onRequestSearch={setSearch}
              onCancelSearch={() => setSearch(undefined)}
            />
          </Grid> */}
        </Grid>

        <Grid container spacing={1}>
          {!loading && !teams.length && (
            <Grid item xs={12}>
              <NoResults text="Nenhum time adicionado" />
            </Grid>
          )}
          {!loading
            ? teams.map((team) => (
                <Grid id={team.id} item xs={12} md={4}>
                  <Box width="100%">
                    <TeamInfo
                      teamName={team.nome}
                      teamOwner={team.cartola}
                      teamShield={team.escudo}
                      teamId={team.id}
                      onDelete={() => setWarn({ show: true, team })}
                      myTeams
                    />
                    {/* <Skeleton variant="rect" width="100%" height={100} /> */}
                  </Box>
                </Grid>
              ))
            : [1, 2, 3, 4, 5, 6].map((e) => (
                <Grid key={e} item xs={12} md={4}>
                  <Box width="100%">
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={100}
                    />
                  </Box>
                </Grid>
              ))}
          {!!teams.length && (
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <Pagination
                count={totalPages}
                page={page + 1}
                onChange={(_, p) => setPage(p - 1)}
                siblingCount={0}
                boundaryCount={1}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default MyTeams;
