import styled from 'styled-components';

export const PointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 3rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;

  span {
    font-size: 1.6rem;
  }
`;

export const Label = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const Value = styled.span``;

export const PointsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: flex-end;
`;
