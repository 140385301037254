// import { Form as RawForm } from '@rocketseat/unform';
import PerfectScrollBar from 'react-perfect-scrollbar';
import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Banks = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const Bank = styled.img`
  height: 5rem;

  border: ${(props) =>
    props.selected ? `2px solid ${colors.primary}` : 'none'};
  border: 5px;
`;

export const OverlayContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: ${(props) => (props.selected ? `0` : '0.6')};
  background: #fff;
`;

export const CheckoutInfo = styled.div`
  height: 80vh;
  max-width: 40rem;

  h3 {
    font-size: 2.1rem;
    color: ${colors.primary};
    margin-bottom: 2rem;
  }
  ul {
    list-style-type: disc;
    font-size: 1.8rem;
    color: #000;
    text-align: justify;
    padding: 1rem;
    margin: 1.5rem 3rem;

    span {
      display: block;
    }

    li {
      margin-bottom: 1rem;
    }
  }
  button {
    display: block;
    margin: 0 auto;
    background: ${colors.primary};
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1.8rem;
    color: #fff;
    margin-top: 1rem;
    font-weight: 900;
    min-width: 8rem;
  }
`;

export const Scroll = styled(PerfectScrollBar)`
  max-height: 60vh;
  padding: 5px 10px;
`;

export const Form = styled.form`
  span {
    color: #fb6f91;
    font-size: 1.4rem;
    align-self: flex-start;
    margin: 0 0 10px;
    font-weight: bold;
  }
`;
