import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Number = styled.span`
  font-size: 1.6rem;
  font-weight: 600;
`;

export const Text = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
`;
