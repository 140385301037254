import styled from 'styled-components';

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PlayerStats = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    &:not(:last-child) {
      border-right: 1px solid #b4b4b4;
    }

    p {
      font-size: 1.8rem;
      line-height: 21px;
    }
  }
`;

export const GridContaier = styled.div`
  div {
    font-family: DM Sans;
    text-align: right;
    font-family: Roboto;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  p {
    font-size: 1.2rem;
    text-align: right;
  }

  svg {
    border-radius: 20px;
    height: 1rem;
    font-size: 2rem;
  }
`;
