import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import history from '../../services/history';

import { GameContainer } from './styles';

function DashboardTeam({ games = [] }) {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: '5px 6px' }}>Posição</TableCell>
            <TableCell align="right" style={{ padding: '5px 6px' }}>
              Mandante
            </TableCell>
            <TableCell />
            <TableCell style={{ padding: '5px 6px' }}>Visitante</TableCell>
            <TableCell style={{ padding: '5px 6px' }}>Posição</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {games.map((game) => (
            <TableRow
              key={game.idPartida}
              onClick={() => history.push(`/jogos/${game.idPartida}`)}
              style={{ cursor: 'pointer' }}
            >
              <TableCell style={{ padding: '0 6px' }}>
                {game.posicaoClubeCasa}°
              </TableCell>
              <TableCell align="right" style={{ padding: '0 6px' }}>
                <GameContainer style={{ justifyContent: 'flex-end' }}>
                  {game.nomeClubeCasa}{' '}
                  <img
                    alt={`Escudo ${game.nome}`}
                    src={game.fotoClubeCasa}
                    style={{ marginLeft: '10px' }}
                  />{' '}
                </GameContainer>
              </TableCell>
              <TableCell align="center" style={{ padding: '0 6px' }}>
                <GameContainer style={{ justifyContent: 'center' }}>
                  {game.placarCasa} x {game.placarVisitante}
                </GameContainer>
              </TableCell>
              <TableCell align="left" style={{ padding: '0 6px' }}>
                <GameContainer style={{ justifyContent: 'flex-start' }}>
                  <img
                    alt={`Escudo ${game.nome}`}
                    src={game.fotoClubeVisitante}
                    style={{ marginRight: '10px' }}
                  />{' '}
                  {game.nomeClubeVisitante}
                </GameContainer>
              </TableCell>
              <TableCell align="right">{game.posicaoClubeVisitante}°</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DashboardTeam;
