import React from 'react';
import { Typography } from '@material-ui/core';
import Card from '../../Card';

import { Container, Stats } from './styles';
import { SingleStat } from '../../PlayerComponents/PlayerStats';

function Highlight({ lower, higher, lowerDescription, higherDescription }) {
  return (
    <Card style={{ height: '100%' }}>
      <Container>
        <Typography variant="h5" align="center">
          Destaques
        </Typography>
        <Stats>
          <SingleStat
            value={higher && higher.toFixed(2)}
            description="Maior pontuação"
            details={higherDescription}
            horizontal
          />
          <SingleStat
            value={higher && lower.toFixed(2)}
            description="Menor Pontuação"
            details={lowerDescription}
            horizontal
          />
        </Stats>
      </Container>
    </Card>
  );
}

export default Highlight;
