/* eslint-disable react/prop-types */
import React from 'react';

import {
  TextField,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Field, Form as FormikForm } from 'formik';
import { format } from 'date-fns';
import AdminForm from '../../../components/AdminForm';

export default function Form({
  readOnly,
  coupon = null,
  create,
  update,
  loading,
}) {
  async function handleSubmit(values) {
    const data = (() => ({
      ...values,
      validade:
        typeof values.validade === 'string'
          ? values.validade.split('/').reverse().join('-')
          : format(values.validade, 'dd/MM/yyyy')
              .split('/')
              .reverse()
              .join('-'),
      status: Number(values.status),
    }))();

    if (coupon) {
      update({ ...data, id: coupon.id });
    } else {
      create(data);
    }
  }

  return (
    <AdminForm
      initialValues={{
        nome: coupon ? coupon.nome : '',
        validade: coupon ? new Date(`${coupon.validade} `) : new Date(),
        status: coupon ? coupon.status : '',
        percentual: coupon ? coupon.percentual : 0,
      }}
      handleSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <FormikForm>
          <Field
            disabled={readOnly || loading}
            margin="normal"
            required
            id="nome"
            label="Nome"
            name="nome"
            autoFocus
            as={TextField}
          />
          <Field
            disabled={readOnly || loading}
            margin="normal"
            number
            label="Percetual de desconto"
            id="percentual"
            name="percentual"
            as={TextField}
          />
          <KeyboardDatePicker
            autoOk
            disabled={readOnly || loading}
            name="validade"
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="validade"
            label="Validade"
            KeyboardButtonProps={{
              'aria-label': 'Selecionar uma data',
            }}
            minDate={new Date()}
            value={values.validade}
            onChange={(date, value) => {
              setFieldValue('validade', date);
            }}
            required
          />
          {/* <Field
            disabled={readOnly || loading}
            margin="normal"
            required
            id="validade"
            label="Validade"
            name="validade"
            autoFocus
            as={TextField}
          /> */}
          <FormControl component="fieldset">
            <FormLabel component="legend">Status</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Field
                    name="status"
                    disabled={readOnly || loading}
                    color="primary"
                    component={Switch}
                    checked={Boolean(values.status)}
                    onChange={() => setFieldValue('status', !values.status)}
                  />
                }
                label={values.status ? 'Habilitado' : 'Desabilitado'}
              />
            </FormGroup>
          </FormControl>

          {!readOnly && (
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              type="submit"
            >
              Salvar
            </Button>
          )}
        </FormikForm>
      )}
    </AdminForm>
  );
}
