/* eslint-disable react/prop-types */
import React from 'react';
import { Formik } from 'formik';
import { Scroll, Container } from './styles';
import Card from '../Card';

export default function AdminForm({
  initialValues,
  schema,
  handleSubmit,
  children,
}) {
  return (
    <Container maxWidth="sm">
      <Card>
        <Scroll>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {children}
          </Formik>
        </Scroll>
      </Card>
    </Container>
  );
}
