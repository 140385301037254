import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  font-size: 0.9rem;

  display: flex;
  align-items: center;
`;

export const Label = styled.span``;

export const Value = styled.span`
  font-weight: bold;
  background: ${colors.accent};
  border-radius: 50px;
  padding: 1px 2px;
  color: #fff;
  margin-left: 2px;
`;
