import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Grid,
} from '@material-ui/core';
import Select from 'react-select/async';
import { MdDelete, MdEdit, MdRemoveRedEye } from 'react-icons/md';

import { toast } from 'react-toastify';
import AdminActionButtons from '../../components/AdminActionButtons';
import { Action } from '../../components/AdminActionButtons/styles';
import AdminTableContainer from '../../components/AdminTableContainer';
import ModalContainer from '../../components/ModalContainer';
import Form from './Form';
import {
  updatePlan,
  createPlan,
  deletePlan,
} from '../../services/catimbaStats/adminPlans';
import WarnMessage from '../../components/WarnMessage';
import AdminTableLoading from '../../components/AdminTableLoading';
import selectStyles from '../../styles/selectStyles';

export default function AdminBlogPost() {
  const [popup, setPopup] = useState({
    show: false,
    option: '',
    post: null,
  });
  // const [posts, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleGetPlans = useCallback(() => {
    // setLoading(true);
    // getPlans()
    //   .then((res) => setPlans(res.data))
    //   .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    handleGetPlans();
  }, [handleGetPlans]);

  const handleClose = useCallback(() => {
    setPopup({ show: false, option: '', post: null });
  }, []);

  const handleUpdatePlan = useCallback(
    (data) => {
      setLoading(true);

      updatePlan(data)
        .then(() => {
          setPopup({
            show: false,
            option: '',
            post: null,
          });
          handleGetPlans();
          toast.success('Plano atualizado com sucesso!');
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.mensagem
          ) {
            toast.warn(err.response.data.mensagem);
            return;
          }
          toast.warn('Ocorreu um erro');
        })
        .finally(() => setLoading(false));
    },
    [handleGetPlans]
  );

  const handleCreatePlan = useCallback(
    (data) => {
      setLoading(true);

      createPlan(data)
        .then(() => {
          setPopup({
            show: false,
            option: '',
            post: null,
          });
          handleGetPlans();
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.mensagem
          ) {
            toast.warn(err.response.data.mensagem);
            return;
          }
          toast.warn('Ocorreu um erro');
        })
        .finally(() => setLoading(false));
    },
    [handleGetPlans]
  );

  const handleDeletePlan = useCallback(
    (id) => {
      setLoading(true);

      deletePlan(id)
        .then(() => {
          setPopup({
            show: false,
            option: '',
            post: null,
          });
          handleGetPlans();
          toast.success('Plano deletado com sucesso!');
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.mensagem
          ) {
            toast.warn(err.response.data.mensagem);
            return;
          }
          toast.warn('Ocorreu um erro');
        })
        .finally(() => setLoading(false));
    },
    [handleGetPlans]
  );

  return (
    <>
      <ModalContainer
        open={popup.show}
        onClose={handleClose}
        position={popup.option === 'warn' && '5px'}
      >
        {popup.option === 'warn' ? (
          <WarnMessage
            onCancel={() =>
              setPopup({
                show: false,
                option: '',
                post: null,
              })
            }
            onConfirm={() => handleDeletePlan(popup.post.id)}
          >
            Tem certeza que deseja excluir o Plano?
          </WarnMessage>
        ) : (
          <Form
            post={popup.post}
            update={handleUpdatePlan}
            create={handleCreatePlan}
            readOnly={popup.option === 'view'}
          />
        )}
      </ModalContainer>
      <Container maxWidth="lg" style={{ marginBottom: 10 }}>
        <Grid container justify="flex-end">
          <Grid item xs={12} md={6} lg={4}>
            <Select
              styles={selectStyles}
              defaultValue={{ label: 'Bons e baratos', value: undefined }}
              // loadOptions={() => getFilter('posicoes', 'id', 'descricao')}
              // onChange={(option) => setPositions(option.value)}
              cacheOptions
              defaultOptions
              isSearchable={false}
            />
          </Grid>
        </Grid>
      </Container>
      <AdminTableContainer
        title="Dicas"
        addFunc={() =>
          setPopup({
            ...popup,
            show: true,
            option: 'add',
          })
        }
      >
        <TableContainer>
          {!loading ? (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {/* <TableCell size="small">ID</TableCell> */}
                  <TableCell>Rodada</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[].map((post) => (
                  <TableRow key={post.id}>
                    <TableCell size="small">{post.id}</TableCell>

                    <TableCell align="right">
                      <Action>
                        <AdminActionButtons
                          size="small"
                          title="Visualizar"
                          onClick={() =>
                            setPopup({
                              show: true,
                              option: 'view',
                              post,
                            })
                          }
                        >
                          <MdRemoveRedEye />
                        </AdminActionButtons>
                        <AdminActionButtons
                          size="small"
                          title="Editar"
                          onClick={() => {
                            setPopup({
                              ...popup,
                              post,
                              show: true,
                            });
                          }}
                        >
                          <MdEdit />
                        </AdminActionButtons>
                        <AdminActionButtons
                          size="small"
                          title="Excluir"
                          onClick={() => {
                            setPopup({
                              ...popup,
                              post,
                              option: 'warn',
                              show: true,
                            });
                          }}
                        >
                          <MdDelete />
                        </AdminActionButtons>
                      </Action>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <AdminTableLoading />
          )}
        </TableContainer>
      </AdminTableContainer>
    </>
  );
}
