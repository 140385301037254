import api from './_api';

const base = '/meioPagamento';

export function getPaymentMethods({ page = 0, size = 50 }) {
  return api.get(base, {
    params: {
      page,
      size,
    },
  });
}

export function createPaymentMethod({
  nome,
  tipo,
  status,
  percentualDesconto,
  key,
  token,
  urlCallBack,
}) {
  return api.post(base, {
    nome,
    tipo,
    status,
    percentualDesconto,
    key,
    token,
    urlCallBack,
  });
}

export function updatePaymentMethod({
  id,
  nome,
  tipo,
  status,
  percentualDesconto,
  key,
  token,
  urlCallBack,
}) {
  return api.put(base, {
    id,
    nome,
    tipo,
    status,
    percentualDesconto,
    key,
    token,
    urlCallBack,
  });
}

export function deletePaymentMethod(id) {
  return api.delete(`base/${id}`);
}
