import produce from 'immer';

const INITIAL_STATE = {
  positions: undefined,
  status: undefined,
  orderBy: undefined,
  clubs: undefined,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@playerCache/STORE': {
        const { positions, status, orderBy, clubs } = action.payload;
        draft.positions = positions;
        draft.status = status;
        draft.orderBy = orderBy;
        draft.clubs = clubs;
        break;
      }

      case '@playerCache/CLEAR': {
        draft = {
          positions: undefined,
          status: undefined,
          orderBy: undefined,
          clubs: undefined,
        };
        break;
      }

      default:
    }
  });
}
