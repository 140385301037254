import api from './_api';

const base = '/usuario';

export function getUsers({ page = 0, filtro, size = 50 }) {
  return api.get(base, {
    params: {
      page,
      filtro,
      size,
    },
  });
}

export function freePlan(idUsuario, idPlano = 0) {
  return api.post(`/pagar/liberarPlano`, {
    idUsuario,
    idPlano,
  });
}

// export function createPlan({ titulo, valor, descricao, status }) {
//   return api.post('/planos', {
//     titulo,
//     valor,
//     descricao,
//     status,
//   });
// }

// export function updatePlan({ id, titulo, valor, descricao, status }) {
//   return api.put('/planos', {
//     id,
//     titulo,
//     valor,
//     descricao,
//     status,
//   });
// }
