/* eslint-disable react/prop-types */
import React from 'react';
import { Paper, Typography, Box } from '@material-ui/core';

import FilterListIcon from '@material-ui/icons/FilterList';
import AddBox from '@material-ui/icons/AddBox';
import Refresh from '@material-ui/icons/Refresh';
import Pagination from '@material-ui/lab/Pagination';
import SearchBar from '../SearchBar';

import { Title, SearchContainer, Container } from './styles';
import AdminActionButtons from '../AdminActionButtons';

export default function AdminTableContainer({
  title,
  filterFunc,
  addFunc,
  setSearch,
  updateFunc,
  children,
  totalPages,
  currentPage,
  onPageChange,
  totalRegisters,
}) {
  return (
    <Container maxWidth="lg">
      <Paper>
        <Title>
          <Typography variant="h6" id="tableTitle">
            {title}
          </Typography>
          <SearchContainer>
            {setSearch && (
              <SearchBar
                className="transparent-bg"
                placeholder="Pesquisar"
                onRequestSearch={setSearch}
                onCancelSearch={() => setSearch(undefined)}
              />
            )}

            {filterFunc && (
              <AdminActionButtons title="Filtrar" onClick={filterFunc}>
                <FilterListIcon />
              </AdminActionButtons>
            )}

            {addFunc && (
              <AdminActionButtons title="Adicionar" onClick={addFunc}>
                <AddBox />
              </AdminActionButtons>
            )}
            {updateFunc && (
              <AdminActionButtons title="Atualizar" onClick={updateFunc}>
                <Refresh />
              </AdminActionButtons>
            )}
          </SearchContainer>
        </Title>
        {children}
        <Box
          display="flex"
          justifyContent="center"
          paddingBottom="1rem"
          flexDirection="column"
          alignItems="center"
        >
          {!!totalRegisters && (
            <Typography variant="body2" gutterBottom>
              Total de registros: {totalRegisters}
            </Typography>
          )}
          {!!totalPages && (
            <Pagination
              count={totalPages}
              page={currentPage + 1}
              onChange={(_, p) => onPageChange(p - 1)}
              siblingCount={0}
              boundaryCount={1}
            />
          )}
        </Box>
      </Paper>
    </Container>
  );
}
