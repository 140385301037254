import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import colors from '../../styles/colors';
// import grafico from '../../assets/icons/grafico.svg';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: "auto"
  },
});

export default function HomeCard({svgimg, title, text}) {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={3}>
      <Box
        height="200px"
        bgcolor={colors.primary}
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <SvgLoader width="100" height="100" path={svgimg}>
          <SvgProxy selector="*" fill="#fff" />
        </SvgLoader>
      </Box>
      <CardContent>
        <div style={{height: 64}}>
        <Typography gutterBottom variant="h5" component="h2" align="center">
          {title}
        </Typography>
        </div>
        <Typography variant="body2" color="textSecondary" component="p">
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
}
