import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';

import {
  PlayerPhoto,
  PlayerPhotoContainer,
  PlayerTeamName,
  PlayerTeamPhoto,
  ScoutsContainer,
  PlayerTeamPhotoContainer,
  PlayerName,
  PlayerNameContainer,
  PlayerPosition,
  BackgroudPaper,
} from './styles';
import ScoutPill from '../PlayerComponents/ScoutPill';
import Points from '../PlayerComponents/Points';

function PlayerDetails({
  price,
  generalAvg,
  basicAvg,
  needs,
  appreciation,
  hostAvg,
  visAvg,
  playerPicture,
  teamPicture,
  teamName,
  playerName,
  playerPosition,
  last,
  positiveScouts = [],
  negativeScouts = [],
  points,
  pointsExpected,
  appreciationExpected,
  avgs,
  appreciations,
}) {
  return (
    <Grid
      item
      container
      xs={12}
      lg={9}
      spacing={0}
      // style={{ borderRadius: '8px 0 0 0' }}
      component={Paper}
    >
      <BackgroudPaper item xs={12} md={3} style={{ borderRadius: '8px 0 0 0' }}>
        <PlayerPhotoContainer>
          <PlayerTeamPhotoContainer>
            <PlayerTeamPhoto src={teamPicture} alt={`Escudo ${teamName}`} />
            <PlayerTeamName>
              {teamName ? teamName.slice(0, 3) : teamName}
            </PlayerTeamName>
          </PlayerTeamPhotoContainer>
          <PlayerPhoto src={playerPicture} alt={playerName} />
          <PlayerNameContainer>
            <PlayerName>{playerName}</PlayerName>
            <PlayerPosition>{playerPosition}</PlayerPosition>
          </PlayerNameContainer>
        </PlayerPhotoContainer>
      </BackgroudPaper>

      <BackgroudPaper
        item
        container
        xs={12}
        md={9}
        spacing={1}
        style={{ borderRadius: '0 8px 0 0', padding: 10 }}
      >
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2">Preço</Typography>
          <Typography>C$ {price && price.toFixed(2)}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2">Pontos</Typography>
          <Typography variant="body2" component="span">
            {points} em {avgs.numJogosGeral} {avgs.numJogosGeral > 1 ? ' jogos' : 'jogo'}
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2">Últ. Valorização</Typography>
          <Typography variant="body2" component="span">
            {appreciations.ultima.toFixed(2)} com {appreciations.pontos} pontos
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2">Min. p/ Valorizar</Typography>
          {/* <Typography>{minAppreciation}</Typography> */}
          <Points value={needs}>
            <Typography>{needs}</Typography>
          </Points>
        </Grid>

        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2">Média Geral</Typography>
          <Typography>
            {avgs.mediaGeral}{' '}
            <Typography variant="body2" component="span">
              em {avgs.numJogosGeral} {avgs.numJogosGeral > 1 ? ' jogos' : 'jogo'}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2">Média Casa</Typography>
          <Typography variant="body2" component="span">
            {avgs.mediaCasa.toFixed(2)} em {avgs.numJogosCasa}{avgs.numJogosCasa > 1 ? ' jogos' : ' jogo'}
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2">Média Fora</Typography>
          <Typography variant="body2" component="span">
            {avgs.mediaFora.toFixed(2)} em {avgs.numJogosFora} {avgs.numJogosFora > 1 ? ' jogos' : ' jogo'}
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2">Média Básica</Typography>
          <Typography variant="body2" component="span">
            {avgs.mediaBasica.toFixed(2)} em {avgs.numJogosBasica} {avgs.numJogosBasica > 1 ? 'jogos' : 'jogo'}
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2">Pontuação Esperada</Typography>
          <Points value={pointsExpected}>
            <Typography>{pointsExpected}</Typography>
          </Points>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="subtitle2">Valorização Esperada</Typography>
          <Points value={appreciationExpected}>
            <Typography>{appreciationExpected}</Typography>
          </Points>
        </Grid>
        {/* <Grid item xs={6} md={4}>
          <Typography variant="subtitle2">Valorização</Typography>
          <Points value={appreciation}>
            <Typography>{appreciation}</Typography>
          </Points>
        </Grid> */}
      </BackgroudPaper>
      <BackgroudPaper item xs={12}>
        <ScoutsContainer>
          {positiveScouts.map((scout) => (
            <ScoutPill key={scout}>
              <span style={{ fontSize: '1.2rem' }}>{scout}</span>
            </ScoutPill>
          ))}
          {negativeScouts.map((scout) => (
            <ScoutPill key={scout} negative>
              <span style={{ fontSize: '1.2rem' }}>{scout}</span>
            </ScoutPill>
          ))}
        </ScoutsContainer>
      </BackgroudPaper>
    </Grid>
  );
}

export default PlayerDetails;
