export const scoutsPositivos = {
  a: 'Assistência',
  ds: 'Desarme',
  sg: 'Saldo de Gols',
  fs: 'Falta Sofrida',
  ft: 'Finalização Trave',
  ff: 'Finalização para Fora',
  g: 'Gol',
  fd: 'Finalização Defendida',
  dp: 'Defesas de pênalti',
  de: 'Defesas',
  ps: 'Pênalti Sofrido'
};

export const scoutsNegativos = {
  fc: 'Faltas cometidas',
  gc: 'Gol Contra',
  ca: 'Cartão Amarelo',
  cv: 'Cartão Vermelho',
  gs: 'Gol Sofrido',
  pe: 'Passe Errado',
  pi: 'Passe Incompleto',
  i: 'Impedimento',
  pp: 'Penalti Perdido',
};
