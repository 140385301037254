import React, { useEffect, useState, useCallback } from 'react';
import { Container, Grid, Box, useMediaQuery, Button } from '@material-ui/core';
import Select from 'react-select/async';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import PlayerCard from '../../components/PlayerComponents/PlayerCard';
import PlayerComparisonModal from '../../components/PlayerComponents/PlayerComparisonModal';
import { getPlayersList as testFn } from '../../services/catimbaStats/players';
import selectStyles from '../../styles/selectStyles';
import SearchBar from '../../components/SearchBar';
import scoutsToArrayConverter from '../../utils/scoutsToArrayConverter';
import MuiTheme from '../../styles/MuiTheme';
import { getFilter } from '../../services/catimbaStats/filter';
import NoResults from '../../components/NoResults';
import { storeCache } from '../../store/modules/playerCache/actions';

const getPlayersList = testFn();

export default function Players() {
  const [players, setPlayers] = useState([]);
  const [orderBy, setOrderBy] = useState();
  const [positions, setPositions] = useState();
  const [status, setStatus] = useState({ label: 'Provável', value: 7 });
  const [clubs, setClubs] = useState();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState();

  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [compare, setCompare] = useState(false);
  const [playersToCompare, setPlayersToCompare] = useState([]);

  const desktop = useMediaQuery(MuiTheme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const playerCache = useSelector((state) => state.playerCache);
  const history = useHistory();

  useMount(() => {
    if (history.action === 'POP') {
      const {
        positions: positionsAux,
        status: statusAux,
        orderBy: orderByAux,
        clubs: clubsAux,
      } = playerCache;

      if (orderByAux) setOrderBy(orderByAux);
      if (positionsAux) setPositions(positionsAux);
      if (statusAux) setStatus(statusAux);
      if (clubsAux) setClubs(clubsAux);
    }
  });

  useEffect(() => {
    if (!compare) {
      setPlayersToCompare([]);
    }
  }, [compare]);

  useEffect(() => {
    setLoading(true);
    getPlayersList({
      page,
      idPosicao: (positions && positions.value) || undefined,
      idStatus: (status && status.value) || undefined,
      ordena: orderBy && orderBy.value,
      idClube: (clubs && clubs.value) || undefined,
      nomeAtleta: search,
    })
      .then((res) => {
        if (res && res.data) {
          setPlayers(res.data.content);
          setTotalPages(res.data.totalPages);
        }
      })
      .catch()
      .finally(() => setLoading(false));

    return () => {
      dispatch(storeCache({ positions, status, orderBy, clubs }));
    };
  }, [positions, status, orderBy, clubs, page, search, dispatch]);

  const handleSelectPlayerToCompare = useCallback(
    (userId, selected) => {
      if (userId && !selected) {
        setPlayersToCompare((playerToCompare) =>
          playerToCompare.filter((player) => player.id !== userId)
        );
        return;
      }

      setPlayersToCompare((playerToCompare) => [
        ...playerToCompare,
        players.find((player) => player.id === userId),
      ]);
    },
    [players, setPlayersToCompare]
  );

  const handleClose = useCallback(() => {
    setCompare(false);
  }, []);

  const handleSetFilter = (option, type) => {
    switch (type) {
      case 'position':
        setPositions(option);
        break;
      case 'status':
        setStatus(option);
        break;
      case 'order':
        setOrderBy(option);
        break;
      case 'club':
        setClubs(option);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <PlayerComparisonModal
        open={compare && playersToCompare.length === 2}
        playerA={playersToCompare[0]}
        playerB={playersToCompare[1]}
        onClose={handleClose}
      />
      <Container maxWidth="lg">
        <Grid
          container
          spacing={1}
          alignItems="center"
          style={{ marginBottom: '1rem' }}
        >
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <Select
              styles={selectStyles}
              defaultValue={{ label: 'Todas as posições', value: undefined }}
              loadOptions={() => getFilter('posicoes', 'id', 'descricao')}
              onChange={(option) => handleSetFilter(option, 'position')}
              value={positions}
              cacheOptions
              defaultOptions
              isSearchable={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <Select
              styles={selectStyles}
              defaultValue={{ label: 'Provável', value: 7 }}
              loadOptions={() => getFilter('status', 'id', 'descricao')}
              onChange={(option) => handleSetFilter(option, 'status')}
              value={status}
              cacheOptions
              defaultOptions
              isSearchable={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <Select
              styles={selectStyles}
              defaultValue={{ label: 'Média', value: undefined }}
              loadOptions={() => getFilter('ordenacao', 'codigo', 'descricao')}
              onChange={(option) => handleSetFilter(option, 'order')}
              value={orderBy}
              cacheOptions
              defaultOptions
              isSearchable={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <Select
              styles={selectStyles}
              defaultValue={{ label: 'Todos clubes', value: undefined }}
              loadOptions={() => getFilter('clubes', 'id', 'descricao')}
              onChange={(option) => handleSetFilter(option, 'club')}
              value={clubs}
              cacheOptions
              defaultOptions
              isSearchable={false}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <SearchBar
              // disabled={loading}
              onRequestSearch={setSearch}
              onCancelSearch={() => setSearch(undefined)}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <Button
              disabled={loading}
              variant="outlined"
              onClick={() => setCompare(!compare)}
            >
              {compare ? 'Fechar Comparação' : 'Comparar Jogadores'}
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          {!loading && !players.length && (
            <Grid item xs={12}>
              <NoResults text="Não há dados para exibir" />
            </Grid>
          )}
          {!loading
            ? players.map((player) => (
                <Grid key={player.id} item xs={12} sm={6} md={12}>
                  <PlayerCard
                    playerId={player.id}
                    picture={player.foto}
                    teamShield={player.fotoClube}
                    position={player.detalhePosicao}
                    positiveScouts={scoutsToArrayConverter(
                      player.scoutsPositivo
                    )}
                    negativeScouts={scoutsToArrayConverter(
                      player.scoutsNegativo
                    )}
                    average={player.media}
                    games={player.jogos}
                    price={player.preco}
                    appreciation={player.variacao}
                    nextGameHostShield={player.confronto.fotoClubeCasa}
                    nextGameVisShield={player.confronto.fotoClubeVisitante}
                    playerName={player.nome}
                    teamName={player.nomeClube}
                    comparable={compare}
                    onSelectPlayer={handleSelectPlayerToCompare}
                    minAppreciation={
                      player.minimoValorizacao &&
                      player.minimoValorizacao.toFixed(2)
                    }
                    statusPlayer={player.idStatus}
                  />
                </Grid>
              ))
            : [1, 2, 3, 4, 5, 6].map((e) => (
                <Grid key={e} item xs={12} sm={6} md={12}>
                  <Box width="100%">
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={desktop ? 100 : 200}
                    />
                  </Box>
                </Grid>
              ))}
        </Grid>
        {!!players.length && (
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Pagination
              count={totalPages}
              page={page + 1}
              onChange={(_, p) => setPage(p - 1)}
              siblingCount={0}
              boundaryCount={1}
            />
          </Grid>
        )}
      </Container>
    </>
  );
}
