import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const FirstName = styled.span`
  font-size: ${({ size }) => (size === 'small' ? '1.4rem' : '1.8rem')};
  color: #333;
  font-weight: 600;
`;

export const Surname = styled.span`
  font-size: ${({ size }) => (size === 'small' ? '1.2rem' : '1.4rem')};
  color: #bbb;
  font-weight: 400;
`;

export const StatusPlayer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    font-size: 16px;
    margin: 3px;
  }
`;
