import styled, { css } from 'styled-components';
import MuiTheme from '../../../styles/MuiTheme';
import colors from '../../../styles/colors';

export const Container = styled.div`
  justify-content: center;
  align-items: center;

  flex: 1;

  ${MuiTheme.breakpoints.up('sm')} {
    height: 220px;
  }

  h5 {
    text-align: center;
  }
  .MuiTypography-caption {
    font-size: 1rem;
  }
  a {
    color: ${colors.accent};
  }
`;

export const StatsContainer = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: ${({ horizontal }) => (horizontal ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 1.6rem;
    line-height: 23px;
    color: #144b20;
  }

  p {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 21px;
    color: #000000;

    & + p {
      ${({ horizontal }) =>
        horizontal
          ? ''
          : css`
              margin-left: 10px;
            `};
      font-size: 1.2rem;
      line-height: 20px;
      color: #979797;
    }
  }

  div {
    display: flex;
    flex-direction: ${({ horizontal }) => (horizontal ? 'column' : 'row')};
    align-items: center;
  }
`;
