import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TeamName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    height: 3rem;
    margin-right: 10px;
  }
  span {
    font-size: 1.8rem;
  }
`;
