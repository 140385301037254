import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import history from '../../services/history';

import { GameContainer } from './styles';

function DashboardBestScoutsTable({ players, title }) {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: '6px 10px 6px 5px' }}>
              {title}
            </TableCell>
            <TableCell align="right" style={{ padding: '6px 5px 6px 0px' }}>
              qtde
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {players &&
            players.map((player, i) => (
              <TableRow
                key={player.id}
                onClick={() => history.push(`/atletas/${player.id}`)}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                <TableCell style={{ padding: '6px 10px 6px 5px' }}>
                  <GameContainer style={{ justifyContent: 'flex-start' }}>
                      <img
                          alt={`Escudo ${player.nome}`}
                          src={player.fotoClube}
                        />{' '}
                      {player.nome}
                  </GameContainer>
                </TableCell>
                <TableCell
                  align="right"
                  style={{ padding: '6px 10px 6px 5px' }}
                >                
                  {title === "Gol" && (
                    player.scoutsPositivo.g
                  )}

                  {title === "Assistência" && (
                    player.scoutsPositivo.a
                  )}

                  {title === "Desarme" && (
                    player.scoutsPositivo.ds
                  )}

                  {title === "Defesa" && (
                    player.scoutsPositivo.de
                  )}

                  {title === "Finalização" && (
                    player.scoutsPositivo.ff + player.scoutsPositivo.ft + player.scoutsPositivo.fd
                  )}

                  {title === "Saldo Gol" && (
                    player.scoutsPositivo.sg
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DashboardBestScoutsTable;
