import React from 'react';

import {
  Container,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  Box,
} from '@material-ui/core';

// import Card from '../../components/Card';
import MuiTheme from '../../styles/MuiTheme';
import homeDemo from '../../assets/home-demo.png';
import {
  Wrapper,
  Hero,
  Spotlight,
  ImageContainer,
  Section,
  Background,
  CelphoneImg,
  InfoContainer,
  PhoneContainer,
} from './styles';
import history from '../../services/history';
import HomeCard from '../../components/HomeCard';
import InfoCard from '../../components/InfoCard';
import colors from '../../styles/colors';
import TestimonialCards from '../../components/TestimonialCards';
import trophy from '../../assets/trophy.svg';
import target from '../../assets/target.svg';
import strategy from '../../assets/strategy.svg';
import graphic from '../../assets/graphic.svg';
import celphone from '../../assets/celular.png';
import grafico from '../../assets/icons/grafico.svg';
import strategyicon from '../../assets/icons/strategy.svg';
import bolavoando from '../../assets/icons/bolavoando.svg';
import comparar from '../../assets/icons/comparar.svg';
import valori from '../../assets/valor.svg';
import gol from '../../assets/icons/gol.svg';
import testemonial1 from '../../assets/gle82.png';
import testemonial3 from '../../assets/cartoleirocampeao.png';
import testemonial5 from '../../assets/manecartola.png';

function Home() {
  const desktop = useMediaQuery(MuiTheme.breakpoints.up('md'));

  return (
    <Wrapper>
      <Hero>
        <Container>
          <Typography
            variant="h3"
            component="h1"
            align="center"
            color="inherit"
          >
{/*             <span>Catimba Stats</span>
            <br /> */}
            Estatísticas campeãs
            <p>
              Vencer não está na sorte, está nos <Spotlight>números</Spotlight>
            </p>
          </Typography>
          <Button
            onClick={() => history.push('/planos-e-precos')}
            variant="contained"
            color="secondary"
          >
            Seja um campeão
          </Button>
        </Container>
      </Hero>
      <Container maxWidth="lg">
        <Section>
          <Typography variant="h4" align="center" gutterBottom>
            O que é o Catimba Stats?
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={7}>
              <ImageContainer src={homeDemo} />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <Typography variant="body1" align="justify">
                <br />
                <p>
                  E se você pudesse ter dados, estatísticas, índices de
                  probabilidades precisos e automações que te ajudem a tomar
                  melhores decisões mais rápido na hora de montar sua escalação
                  para o Cartola FC?
                </p>
                <br />
                <p>
                  O catimba stats é a maior ferramenta de estatísticas do
                  fantasy game Cartola FC! Com ela você tem acesso a
                  estatísticas valiosas que o Cartola FC não disponibiliza.
                </p>
                <br />
                <p>
                  Alavanque seus resultados de forma inacreditável e se destaque
                  como nunca.
                </p>
              </Typography>
            </Grid>
          </Grid>
        </Section>
      </Container>
      <Background bg="#FEFEFE">
        <Container maxWidth="lg">
          <Section>
            <Box width="100%" justifyContent="center" marginBottom="30px">
              <Typography variant="h4" align="center">
                Tenha acesso a recursos incríveis!
              </Typography>
            </Box>
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12} md={6} lg={4}>
                <HomeCard
                  svgimg={grafico}
                  title="MÉDIA BÁSICA"
                  text="A média básica reflete como um jogador pontua APENAS com os scouts mais básicos do jogo."
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <HomeCard
                  svgimg={strategyicon}
                  title="ANÁLISE DO JOGADOR"
                  text="A análise de jogador, permite você analisar cada histórico do jogador que desejar."
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <HomeCard
                  svgimg={bolavoando}
                  title="PONTUAÇÃO CEDIDA"
                  text="Pontuação média cedida de cada clube, como mandante, visitante, por posição e rodadas."
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <HomeCard
                  svgimg={comparar}
                  title="COMPARAR JOGADORES"
                  text="Compare médias e scouts dos jogadores que desejar."
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <HomeCard
                  svgimg={valori}
                  title="MÍNIMO P/ VALORIZAR"
                  text="Pontuação mínima necessária que o jogador precisa fazer para valorizar na rodada."
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <HomeCard
                  svgimg={gol}
                  title="GOLS A FAVOR X CONTRA"
                  text="Tabela de Gols a favor, Gols Contra e Saldo de gols."
                />
              </Grid>
            </Grid>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              marginTop="30px"
              marginBottom="80px"
            >
              {/* <Button
                onClick={() => history.push('/planos-e-precos')}
                variant="contained"
                color="secondary"
                size="large"
              >
                Assine agora
              </Button> */}
            </Box>
          </Section>
        </Container>
      </Background>
      <Background bg={colors.primary}>
        <Container maxWidth="lg">
          <Section>
            <InfoContainer>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <InfoCard
                        img={trophy}
                        title="TOP20 MAIS ESCALADOS"
                        text="Veja os jogadores mais escalados até o momento em cada rodada e decida se vai junto ou se vai escalar outros e mitar sem eles."
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InfoCard
                        img={graphic}
                        title="ESTATISTICAS DO SEU TIME"
                        text="Não são só as estatísticas do seu time, é muito mais! Acompanhe até 20 times, saiba tudo sobre eles em detalhes."
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InfoCard
                        img={strategy}
                        title="CRUZAMENTO DE SCOUTS"
                        text="Uma ferramenta sensacional para indicar as melhores opções da rodada, a ferramenta mostra um cruzamento entre scouts conquistados e cedidos entre os times que se enfrentam na rodada atual."
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InfoCard
                        img={target}
                        title="PONTARIA"
                        text="Na hora de escalar um atacante isso importa bastante. Chamamos de Pontaria a porcentagem de acerto nas finalizações de um jogador. São acertos (A) os scouts G, FD e FT, e são erros (E) o scout FF."
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  style={{ overflow: !desktop && 'hidden' }}
                >
                  <PhoneContainer>
                    <CelphoneImg
                      src={celphone}
                      alt="Imagem ilustrative de um celular"
                    />
                  </PhoneContainer>
                </Grid>
              </Grid>
            </InfoContainer>
          </Section>
        </Container>
      </Background>
      {/* <Background bg="#FEFEFE">
        <Container maxWidth="md">
          <Section>
            <Box width="100%" justifyContent="center" marginBottom="30px">
              <Typography variant="h4" align="center" gutterBottom>
                O que os{' '}
                <strong style={{ color: colors.accent }}>#parceiros</strong> do{' '}
                <strong style={{ color: colors.primary }}>Catimba Stats</strong>{' '}
                dizem
              </Typography>
            </Box>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} md={6}>
                <TestimonialCards
                  avatar={testemonial1}
                  name="Gle82"
                  type="Youtuber"
                  text="O Catimba Stats é uma plataforma completa! São informações detalhadas de jogadores e clubes.
                          Recomendo pra você que busca ser ainda mais competitivo nas suas ligas no Cartola FC, e assertivo na escolha dos jogadores"
                  link="https://www.youtube.com/channel/UCRyvi4JdP67BJTw4wKffItA"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TestimonialCards
                  avatar={testemonial5}
                  name="Mané Cartola"
                  type="Youtuber"
                  text="Quer ir bem nas suas ligas? Vem então com o Mané e o Catimba Stats! Ferramenta indispensável para quem quer mitar! Cartola não é sorte... é estratégia e estudo! Venha fazer parte desse time vencedor!"
                  link="https://www.youtube.com/channel/UCSmiUW87hNXZCI88St_pGMQ"
                />
              </Grid>
               <Grid item xs={12} md={4}>
                <TestimonialCards
                  avatar={testemonial3}
                  name="Cartoleiro Campeão"
                  type="Instagram"
                  text="Catimba Stats é uma ferramenta essencial para os cartoleiros que querem escalar jogadores com fundamento estatístico e assim se diferenciar daqueles que acham que Cartola é sorte."
                  link="https://www.instagram.com/cartoleirocampeao/"
                />
              </Grid>
               <Grid item xs={12} md={4}>
                <TestimonialCards
                  avatar={testemonial2}
                  name="Camila Campos"
                  type="Youtuber"
                  text="Pensar, analisar e comparar jogadores são ações essenciais. Pensando nisso, o Catimba Stats fornece conteúdo
                        de qualidade com dados estatísticos, promovendo regularidade e estratégia nas escalações."
                  link="https://www.youtube.com/channel/UC7BDQDUpiKV1myR9XTL7-Vw"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TestimonialCards
                  avatar={testemonial4}
                  name="CartolaFC TOP"
                  type="Instagram"
                  text="Com uma ferramenta qualificada, que disponibiliza estatísticas e mostra as melhores probabilidades, sem achismo, fica mais facil escolher os melhores jogadores.
                        Aqui você tem tudo pra se dar bem e Mitar junto com a gente."
                  link="https://www.instagram.com/cartolafctop/"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TestimonialCards
                  avatar={testemonial6}
                  name="C.D FC"
                  type="Instagram"
                  text="Uma ferramenta de apoio a decisão para escalar time no fantasy game Cartola FC. Catimba Stats reuni os melhores dados para te ajudar na escalacao! Chega de achismo, junte-se aos mitos e saia vencedor!"
                  link="https://www.instagram.com/cartoladicas/"
                />
              </Grid>
            </Grid>
          </Section>
        </Container>
      </Background> */}
      <Background bg="#FEFEFE">
        <Container maxWidth="md">
          <Section>
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography
                variant="h4"
                align="center"
                style={{ color: colors.primary, marginBottom: 50 }}
              >
                Tá esperando o que?
                <br /> Junte-se aos maiores mitos!
              </Typography>
              <Button
                onClick={() => history.push('/planos-e-precos')}
                variant="contained"
                color="secondary"
                size="large"
              >
                Assine agora
              </Button>
            </Box>
          </Section>
        </Container>
      </Background>
    </Wrapper>
  );
}

export default Home;
