import React, { useEffect } from 'react';
import { Container, Typography } from '@material-ui/core';
// import { Container } from './styles';

function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container maxWidth="md" style={{ padding: '20px 10px' }}>
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        style={{ fontSize: '3.0rem' }}
      >
        Termos e condições de uso
      </Typography>

      <Typography
        variant="body1"
        align="justify"
        style={{ fontSize: '1.6rem' }}
      >
        Olá, ficamos muito felizes com a sua decisão de contratar os nossos
        serviços. Para que possamos fazê-lo de forma transparente, com qualidade
        e segurança para você, elaboramos esse Termo e Condições de uso.
        <br />
        <br />
        1. Ao se cadastrar no site do CATIMBA STATS o usuário aceita,
        automaticamente, os termos e condições de uso.
        <br />
        2. O CATIMBA STATS presta serviços de estatísticas por meio de jogos
        eletrônicos recreativos.
        <br />
        3. Ao cadastrar uma senha no site, ela será de uso pessoal e
        intransferível. O usuário se compromete a não repassa-la a ninguém.
        <br />
        4. Para se cadastrar no site e contratar os serviços prestados pelo
        CATIMBA STATS, é indicado que o usuário possua, no mínimo, 18 anos no
        momento da realização do cadastro, e que seja absolutamente capaz.
        Porém, caso não tenha a idade exigida, poderá participar, desde que
        devidamente assistido ou representado pelos pais ou responsáveis. Neste
        caso, os atos praticados pelo usuário com idade menor que 18 anos, são
        de exclusiva responsabilidade dos pais e responsáveis.
        <br />
        <br />
        5. SOBRE REQUISITOS DE CADASTRO
        <br />
        5.1 O usuário que não atender os requisitos será desligado do CATIMBA
        STATS.
        <br />
        5.2 É de inteira responsabilidade do usuário a veracidade dos dados
        fornecidos no momento da realização do cadastro.
        <br />
        5.3 Caso algum dado tenha sido informado de maneira equivocada, o
        usuário deverá imediatamente, acessar o sistema e proceder com a
        retificação dos dados fornecidos.
        <br />
        5.4 A conta será de uso exclusivo do usuário, que se compromete a não
        repassar os dados para nenhuma outra pessoa ou empresa. Qualquer
        violação de segurança deverá, imediatamente, ser informada ao CATIMBA
        STATS.
        <br />
        <br />
        6 SOBRE PAGAMENTOS
        <br />
        6.1 Pagamento deverá ser realizado mediante os métodos disponíveis no
        site, sendo que o usuário NÃO PODE realizar o pagamento sem gerar o
        pedido de participação e sem consultar a conta disponível, pois elas
        podem sofrer alterações. Caso o usuário efetue o pagamento em uma conta
        não disponível ele será reembolsado. É de responsabilidade do usuário
        realizar o pagamento, pelo meio fornecido somente pelo site do CATIMBA
        STATS. NÃO SERÃO ACEITOS DEPÓSITOS POR ENVELOPE, caso isso aconteça, o
        CATIMBA STATS não possui obrigação de reembolsar o usuário. O CATIMBA
        STATS não se responsabilizará por equívocos praticados pelo usuário no
        momento da efetivação da transação bancária, tais como: valores
        equivocados e dados digitados incorretamente.
        <br />
        6.2 Cabe ao usuário realizar a participação por meio do próprio site{' '}
        <a href="https://catimbastats.com.br" target="blank">
          https://catimbastats.com.br
        </a>
        , NÃO É NECESSÁRIO ENVIAR O COMPROVANTE DE PAGAMENTO.
        <br />
        6.3 Havendo qualquer problema ao realizar o pagamento, o usuário deverá
        entrar em contato com o CATIMBA STATS via Whatsapp, instagram ou e-mail
        contato@catimbastats.com. Quando o sistema recebe um e-mail,
        automaticamente, é aberto um chamado para responder a solicitação do
        usuário, caso não receba esse e-mail de retorno com o protocolo do seu
        atendimento, quer dizer que sua solicitação não foi registrada, sendo
        assim, o usuário deverá tentar novamente ou procurar nossa equipe
        através do Facebook:{' '}
        <a href="https://www.facebook.com/catimbastats" target="blank">
          www.facebook.com/catimbastats
        </a>{' '}
        ou
        <a href="https://www.instagram.com/catimbastats" target="blank">
          www.instagram.com/catimbastats
        </a>
        . A solicitação será respondida em até 24 horas da abertura do chamado.
        <br />
        6.4 Após aprovação de pagamento em nosso site, o usuário receberá um
        e-mail do CATIMBA STATS. Caso a solicitação não seja aprovada ou
        apresente algum problema, o usuário deverá entrar em contato em tempo
        hábil para que seu problema seja resolvido e seu time incluso na liga
        desejada. Se o usuário não solucionar o problema em tempo hábil, sua
        solicitação só poderá ser atendida na competição seguinte de acordo com
        o calendário de competições.
        <br />
        6.5 Caso o pagamento tenha sido realizado pelo mercado pago o plano do
        usuário será ativo automaticamente. Porém, caso o pagamento fique com o
        status em análise ou pendente, isso quer dizer que, a interação entre o
        mercado pago e sua operadora de cartão não foi aprovada. Essa situação
        não está sob controle do CATIMBA STATS, pois se trata de uma relação
        entre o Mercado Pago e a Operadora de Cartão de Crédito. Neste caso,
        será necessário que o usuário entre em contato com a sua operadora de
        cartão para solucionar o problema ou realize um novo procedimento.
        <br />
        7 A partir do momento em que o usuário requerer a sua inclusão na
        competição é obrigação do mesmo acompanhar no sistema, verificando se
        seu pedido foi finalizado.
        <br />
        8 Os Serviços de Entretenimento serão disponibilizados tendo como base o
        calendário do Campeonato Brasileiro.
        <br />
        9 Os regulamentos esportivos serão disponibilizados nas páginas de cada
        liga, sendo todos estes, partes integrantes deste regulamento.
        <br />
        10 O CATIMBA STATS não se responsabilizará por quaisquer problemas que
        aconteçam no site e que tenham sido ocasionados por terceiros, tais como
        vírus e ataques de hackers. O CATIMBA STATS também não garante que a
        prestação de serviços no site será realizada de maneira ininterrupta.
        <br />
        11 O usuário poderá desistir do serviço em até 7 dias após a compra.
        <br />
        12 Nenhuma parte do site poderá ser reproduzida ou utilizada sem
        autorização expressa do CATIMBA STATS.
        <br />
        13 Todos os direitos autorais dos usuários serão preservados, conforme a
        nossa política de privacidade.
        <br />
        14 Este termo está sujeito a modificações, porém, se houver, o usuário
        será informado levando em conta a data de publicação das mesmas.
        <br />
        15 Qualquer problema relativo ao site, pagamentos ou quaisquer outras
        controvérsias deverão ser comunicadas ao CATIMBA STATS por meio do
        e-mail
        <br />
        contato@catimbastats.com. 16 O CATIMBA STATS não se responsabilizará por
        qualquer link ou propaganda veiculada no site que não tenha sido
        divulgada exclusivamente pelo CATIMBA STATS.
        <br />
        17 Todas as imagens e informações contidas na página pertencem ao
        CATIMBA STATS e não poderão ser reproduzidas por qualquer pessoa ou
        empresa, pois são protegidas por direitos autorais.
        <br />
        Muito obrigada por fazer parte da Equipe do Catimba Stats!
      </Typography>
    </Container>
  );
}

export default TermsAndConditions;
