import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { passwordUpdate } from '../../../services/catimbaStats/auth';
import { Wrapper } from '../sharedStyles';

export default function UpdatePassword() {
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.auth.profile);

  const schema = Yup.object().shape({
    currentPassword: Yup.string().required('Senha atual não pode estar vazia'),
    password: Yup.string()
      .min(6, 'No mínimo 6 digitos')
      .required('Senha não pode estar vazia'),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), undefined],
      'As senhas devem ser iguais'
    ),
  });

  const handleSubmit = useCallback(
    ({ password, currentPassword }) => {
      setLoading(true);
      passwordUpdate({
        id: user.id,
        password,
        oldPassword: currentPassword,
      }).finally(() => setLoading(false));
    },
    [user]
  );

  return (
    <Container component="main" maxWidth="xs">
      <Wrapper>
        <Formik
          initialValues={{
            password: '',
            passwordConfirmation: '',
            currentPassword: '',
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Field
                name="currentPassword"
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Senha atual"
                    id="currentPassword"
                    type="password"
                    autoFocus
                    error={errors.currentPassword && touched.currentPassword}
                    helperText={
                      touched.currentPassword && errors.currentPassword
                    }
                  />
                )}
              />
              <Field
                name="password"
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Nova Senha"
                    id="password"
                    autoComplete="password"
                    type="password"
                    error={errors.password && touched.password}
                    helperText={touched.password && errors.password}
                  />
                )}
              />

              <Field
                name="passwordConfirmation"
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="passwordConfirmation"
                    label="Confirmação de nova senha"
                    autoComplete="passwordConfirmation"
                    type="password"
                    error={
                      errors.passwordConfirmation &&
                      touched.passwordConfirmation
                    }
                    helperText={
                      touched.passwordConfirmation &&
                      errors.passwordConfirmation
                    }
                  />
                )}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className
                disabled={loading}
              >
                Confirmar
              </Button>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </Container>
  );
}
