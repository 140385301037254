import React, { useCallback } from 'react';
import { Form as FormikForm, Field } from 'formik';
import { TextField, Button, InputAdornment, Grid } from '@material-ui/core';
import { format } from 'date-fns';
import AdminForm from '../../../components/AdminForm';

// import { Container } from './styles';

function Form({ readOnly, plan: post = null, create, update, loading }) {
  const handleSubmit = useCallback(
    async (values) => {
      if (!post) {
        await create({
          ...values,
          vigencia: values.vigencia.split('/').reverse().join('-'),
        });
        return;
      }
      await update({
        ...post,
        ...values,
        vigencia: values.vigencia.split('/').reverse().join('-'),
      });
    },
    [create, post, update]
  );

  return (
    <AdminForm
      initialValues={{
        titulo: post ? post.titulo : '',
        descricao: post ? post.descricao : '',
        valor: post ? post.valor : '',
        status: post ? post.status : true,
        destaque: post ? post.destaque : false,
        vigencia: post
          ? new Date(`${post.vigencia} `)
          : format(new Date(), 'dd/MM/yyyy'),
      }}
      handleSubmit={handleSubmit}
    >
      {() => (
        <FormikForm>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={3}>
              <Field name="rodada">
                {({ field }) => (
                  <TextField
                    {...field}
                    disabled={readOnly || loading}
                    margin="normal"
                    required
                    label="Rodada"
                    id="titulo"
                    autoFocus
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Field name="Preço">
                {({ field }) => (
                  <TextField
                    {...field}
                    disabled={readOnly || loading}
                    margin="normal"
                    required
                    label="Preço C$"
                    autoFocus
                    type="number"
                    startAdornment={
                      <InputAdornment position="start">R$</InputAdornment>
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Field name="titulo">
                {({ field }) => (
                  <TextField
                    {...field}
                    disabled={readOnly || loading}
                    margin="normal"
                    required
                    fullWidth
                    label="Título"
                    id="titulo"
                    autoFocus
                  />
                )}
              </Field>
            </Grid>
          </Grid>

          <Field name="descricao">
            {({ field }) => (
              <TextField
                {...field}
                disabled={readOnly || loading}
                margin="normal"
                required
                label="Descrição"
                id="descricao"
                autoFocus
                multiline
              />
            )}
          </Field>

          {!readOnly && (
            <Button type="submit" variant="contained" color="primary">
              Salvar
            </Button>
          )}
        </FormikForm>
      )}
    </AdminForm>
  );
}

export default Form;
