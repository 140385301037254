import styled from 'styled-components';
import { Container as MuiContainer } from '@material-ui/core';
import MuiTheme from '../../../styles/MuiTheme';

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 300px;

  span.recharts-legend-item-text {
    font-size: 1.4rem;
  }
  text.recharts-polar-radius-axis-tick-value {
    font-size: 1.2rem;
  }

  div.recharts-legend-wrapper {
    bottom: -10px !important;
  }
`;

export const Players = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;

  > div {
    &:last-child {
      align-items: flex-end;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  > h6 {
    margin-bottom: -2rem;
  }
`;

export const Title = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
`;

export const Wrapper = styled(MuiContainer)`
  position: relative;
  button {
    position: absolute;
    top: -5px;
    right: 20px;
    background: none;
    border: none;
    font-size: 3rem;
    z-index: 100;

    ${MuiTheme.breakpoints.up('sm')} {
      right: 30px;
    }
  }
`;

export const PlayersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;

  > div {
    flex: 1;
  }
`;
