import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// import { Container } from './styles';

function AdBanner() {
  const history = useHistory();

  useEffect(() => {
    try {
      window.adsbygoogle = window.adsbygoogle || [];
      window.adsbygoogle.push({});
    } catch (e) {}
  }, [history.location]);

  return (
    <div key={history.location.pathname}>
      <ins
        className="adsbygoogle"
        style={{ height: 30 }}
        data-ad-client="ca-pub-9222482921461579"
        data-ad-slot="f08c47fec0942fa0"
      />
    </div>
  );
}

export default AdBanner;
