import React from 'react';

// import { Container } from './styles';

function Points({ children, value, positiveColor, negativeColor, style = {} }) {
  return (
    <span
      style={{
        color: value >= 0 ? positiveColor || 'green' : negativeColor || 'red',
        ...style,
      }}
    >
      {children}
    </span>
  );
}

export default Points;
