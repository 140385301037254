import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Card from '../../Card';

import {
  Container,
  ShortStatistics,
  PlayerContainer,
  ScoutsContainer,
  ComparableCheckBox,
} from './styles';
import PlayerInfo from '../PlayerInfo';
import NumberInfo from '../../StatisticsComponents/NumberInfo';
import ScoutPill from '../ScoutPill';
import SmallComparison from '../../GameComponents/SmallComparison';
import { SingleStat } from '../PlayerStats';
import MuiTheme from '../../../styles/MuiTheme';

function PlayerCard({
  playerId,
  picture,
  teamShield,
  position,
  positiveScouts = [],
  negativeScouts = [],
  average,
  games,
  price,
  appreciation,
  nextGameHostShield,
  nextGameVisShield,
  playerName,
  teamName,
  minAppreciation,
  comparable = false,
  card = false,
  short = false,
  points,
  onSelectPlayer,
  customStatusValue,
  customStatusTitle,
  statusPlayer,
}) {
  const lg = useMediaQuery('(min-width: 360px)');
  const mdUp = useMediaQuery(MuiTheme.breakpoints.up('md'));
  const history = useHistory();

  return (
    <Container
      onClick={() =>
        !card && !comparable ? history.push(`atletas/${playerId}`) : {}
      }
    >
      <Card>
        <PlayerContainer card={card} comparable={comparable}>
          {comparable && (
            <ComparableCheckBox
              color="primary"
              onChange={(event) =>
                onSelectPlayer
                  ? onSelectPlayer(playerId, event.target.checked)
                  : {}
              }
            />
          )}
          <div className="scouts">
            <PlayerInfo
              playerName={playerName}
              picture={picture}
              position={position}
              teamName={teamName}
              teamShield={teamShield}
              horizontal={lg && !card}
              statusPlayer={statusPlayer}
            />

            <ScoutsContainer>
              {positiveScouts.map((scout) => (
                <ScoutPill key={scout}>{scout}</ScoutPill>
              ))}
              {negativeScouts.map((scout) => (
                <ScoutPill key={scout} negative>
                  {scout}
                </ScoutPill>
              ))}
            </ScoutsContainer>
          </div>
          <ShortStatistics short={short} card={card}>
            <div className="statistics">
              {short ? (
                <>
                  <SingleStat
                    description="Pontos"
                    value={points}
                    details={`em ${games} jogos`}
                    horizontal
                  />
                  <SingleStat
                    description="Preço"
                    value={price && price.toFixed(2)}
                    details="cartoletas"
                    horizontal
                  />
                </>
              ) : (
                <>
                  {customStatusValue !== undefined && (
                    <NumberInfo
                      className="customStat"
                      title={customStatusTitle}
                      value={customStatusValue}
                      isColored
                    />
                  )}
                  {price !== undefined && (
                    <NumberInfo
                      title="Preço"
                      value={`C$ ${price.toFixed(2)}`}
                    />
                  )}
                  {appreciation !== undefined && (
                    <NumberInfo
                      style={{
                        '& > span': appreciation >= 0 ? 'green' : 'red',
                      }}
                      title="Variação"
                      isColored
                      value={appreciation.toFixed(2)}
                    />
                  )}
                  {minAppreciation !== undefined && (
                    <NumberInfo
                      title="Mín. valorizar"
                      value={minAppreciation}
                      style={
                        !mdUp
                          ? {
                              gridColumnStart: 1,
                              gridColumnEnd: 3,
                            }
                          : undefined
                      }
                    />
                  )}
                  {games !== undefined && (
                    <NumberInfo title="Jogos" value={games} />
                  )}
                  {average !== undefined && (
                    <NumberInfo title="Média" value={average.toFixed(2)} />
                  )}
                  {(!!nextGameHostShield || !!nextGameVisShield) && (
                    <NumberInfo
                      title="Próximo jogo"
                      value={
                        <SmallComparison
                          hostShield={nextGameHostShield}
                          visShield={nextGameVisShield}
                        />
                      }
                      className="next-game"
                    />
                  )}
                </>
              )}
            </div>
            {/* {lg && !card && (
              <ScoutsContainer className="scouts">
                {positiveScouts.map((scout) => (
                  <ScoutPill key={scout}>{scout}</ScoutPill>
                ))}
                {negativeScouts.map((scout) => (
                  <ScoutPill key={scout} negative>
                    {scout}
                  </ScoutPill>
                ))}
              </ScoutsContainer>
            )} */}
          </ShortStatistics>
        </PlayerContainer>
      </Card>
    </Container>
  );
}

export default PlayerCard;
