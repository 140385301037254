export default {
  types: { 1: 'Rodada', 2: 'Mensal', 3: 'Turno', 4: 'Anual' },
  size: 50,
  adminListSize: 20,
  leagueTypes: [
    {
      value: 1,
      label: 'Rodada',
    },
    {
      value: 2,
      label: 'Mensal',
    },
    {
      value: 3,
      label: 'Turno',
    },
    {
      value: 4,
      label: 'Anual',
    },
  ],
  modalidades: [
    {
      value: 1,
      label: 'Normal',
    },
    {
      value: 2,
      label: 'Premiada',
    },
    {
      value: 3,
      label: 'Gratuita',
    },
  ],
  perfil: [
    {
      value: 5,
      label: 'Usuário comum',
    },
    {
      value: 4,
      label: 'Assinante',
    },
    {
      value: 3,
      label: 'Moderador',
    },
    {
      value: 2,
      label: 'Administrador',
    },
    {
      value: 1,
      label: 'Super Adminstrador',
    },
  ],
  paymentMethodType: [
    {
      value: 1,
      label: 'Parceiro',
    },
    {
      value: 2,
      label: 'Manual',
    },
  ],
  bankAccountType: [
    {
      value: 1,
      label: 'Corrente',
    },
    {
      value: 2,
      label: 'Poupança',
    },
    {
      value: 3,
      label: 'Carteria Digital',
    },
  ],
  manualPaymentStatus: [
    {
      value: 1,
      label: 'Pendente',
    },
    {
      value: 12,
      label: 'Aprovado',
    },
  ],
  marketStatus: {
    4: 'Em manutenção',
    1: 'Aberto',
    2: 'Fechado',
  },
  marketStatusColors: {
    4: '#333FFF',
    1: 'green',
    2: '#E22D2D',
  },
};
