import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';

// import { Container } from './styles';

export default function AdminActionButtons({
  onClick,
  children,
  title,
  ...rest
}) {
  return (
    <Tooltip title={title}>
      <IconButton
        size="small"
        aria-label={title}
        onClick={() => onClick()}
        {...rest}
      >
        {children}
      </IconButton>
    </Tooltip>
  );
}
