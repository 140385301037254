import styled from 'styled-components';

export const Container = styled.div``;

export const TeamInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-right: 10px;
    height: 40px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Info = styled.span`
  border-left: 1px solid #ddd;
  font-size: 1.4rem;
  padding-left: 10px;
`;

export const StatusContainer = styled.div``;

export const Status = styled.div`
  margin-top: 1.5rem;
`;

export const StatusText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.4rem;
`;

export const HSBarContainer = styled.div`
  svg {
    border-radius: 20px;
    height: 1rem;
  }
`;
