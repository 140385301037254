import React from 'react';
import Name from '../../Name';
import { LogoContainer } from './styles';

// import { Container } from './styles';

function TeamData({ shield, teamName, teamOwner }) {
  return (
    <LogoContainer>
      <img alt={`Escudo ${teamName}`} src={shield} />
      <Name first={teamName} second={teamOwner} />
    </LogoContainer>
  );
}

export default TeamData;
