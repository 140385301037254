import React, { useEffect, useState } from 'react';
import { Grid, Box, Container, Typography } from '@material-ui/core';
import Select from 'react-select/async';
import Skeleton from '@material-ui/lab/Skeleton';
import GameInfo from '../../components/GameComponents/GameInfo';
import selectStyles from '../../styles/selectStyles';
import { getFilter } from '../../services/catimbaStats/filter';
import { getPontosCedidos } from '../../services/catimbaStats/statistics';
import Card from '../../components/Card';
import NoResults from '../../components/NoResults';

function GivenPoints() {
  const [idPosicao, setIdPosicao] = useState(0);
  const [mando, setMando] = useState(1);
  const [rodada, setRodada] = useState(0);
  // const [search, setSearch] = useState('');

  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getPontosCedidos({
      idPosicao,
      mando,
      rodada,
    })
      .then((res) => {
        setTeams(res.data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, [idPosicao, rodada, mando]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <Typography variant="body2">
              Veja pontuação média cedida por cada clube.
              <br />
              Utilize os filtros abaixo escolher mando de campo, posição e
              número de rodadas a serem consideradas.
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Select
            styles={selectStyles}
            defaultValue={{ label: 'Todas as posições', value: 0 }}
            loadOptions={() => getFilter('posicoes', 'id', 'descricao')}
            onChange={(option) => setIdPosicao(option.value)}
            cacheOptions
            defaultOptions
            isSearchable={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Select
            styles={selectStyles}
            defaultValue={{ label: 'Como Mandante', value: 1 }}
            loadOptions={() => getFilter('tiposMando', 'id', 'descricao')}
            onChange={(option) => setMando(option.value)}
            cacheOptions
            defaultOptions
            isSearchable={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Select
            styles={selectStyles}
            defaultValue={{ label: 'Todas as rodadas', value: 0 }}
            loadOptions={() =>
              getFilter('rodadasCruzamento', 'id', 'descricao')
            }
            onChange={(option) => setRodada(option.value)}
            cacheOptions
            defaultOptions
            isSearchable={false}
          />
        </Grid>
        {/* <Grid item xs={12} lg={6}>
        <SearchBar
          // disabled={loading}
          onRequestSearch={setSearch}
          onCancelSearch={() => setSearch(undefined)}
        />
      </Grid> */}
        {!loading && !teams.length && (
          <Grid item xs={12}>
            <NoResults />
          </Grid>
        )}
        {!loading
          ? teams.map((team) => (
              <Grid item xs={12}>
                <GameInfo
                  teamName={team.nome}
                  shield={team.foto}
                  value={team.mediaCedida.toFixed(2)}
                />
              </Grid>
            ))
          : [1, 2, 3, 4, 5, 6].map((e) => (
              <Grid key={e} item xs={12} sm={6} md={12}>
                <Box width="100%">
                  <Skeleton variant="rect" width="100%" height={100} />
                </Box>
              </Grid>
            ))}
      </Grid>
    </Container>
  );
}

export default GivenPoints;
