import styled, { css } from 'styled-components';
import { Paper } from '@material-ui/core';

export const CardWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 35px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-decoration: none;
  border: 2px solid transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  img {
    height: 3rem;
  }

  ${({ selected }) =>
    selected &&
    css`
      background: #ccc;
    `}
`;

export const InnerCard = styled(Paper)`
  width: 6rem;
  height: 6rem;
  /* box-shadow: 0px 3px 6px #00000029; */
  border-radius: 4px;
  position: relative;
  cursor: pointer;
`;
