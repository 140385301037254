import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import { Link as Link1 } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import logo from '../../../assets/logo.png';
import { passwordRecover } from '../../../services/catimbaStats/auth';
import { Wrapper } from '../sharedStyles';

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Insira um e-mail válido')
      .required('E-mail não pode ser vazio'),
  });

  const handleSubmit = useCallback(({ email }) => {
    setLoading(true);
    passwordRecover(email).finally(() => setLoading(false));
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <Wrapper>
        <Link1 to="/">
          <img src={logo} alt="Logo do Catimba Stats" />
        </Link1>
        <Typography variant="h5">Vamos recuperar o seu acesso</Typography>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Field
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    autoComplete="email"
                    autoFocus
                    error={errors.email && touched.email}
                    helperText={touched.email && errors.email}
                  />
                )}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className
                disabled={loading}
              >
                Enviar
              </Button>

              <Grid container>
                <Grid item xs>
                  <Link href="/recuperar" variant="body2">
                    Já tem um código?
                  </Link>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </Container>
  );
}

// import React from 'react';
// import { Link } from 'react-router-dom';
// import * as Yup from 'yup';

// import { useDispatch, useSelector } from 'react-redux';
// import AuthForm, { Input, Actions, Action } from '../../components/AuthForm';
// import { recoverRequest } from '../../store/modules/auth/actions';
// // import { Container } from './styles';

// const schema = Yup.object().shape({
//   email: Yup.string()
//     .email('Insira um email válido')
//     .required('Email não pode estar vazio'),
// });

// export default function ForgotPassword() {
//   const dispatch = useDispatch();

//   const loading = useSelector(state => state.auth.loading);

//   function handleSubmit({ email }) {
//     dispatch(recoverRequest(email));
//   }
//   return (
//     <AuthForm
//       schema={schema}
//       title="Vamos recuperar seu acesso!"
//       submitText="Recuperar senha"
//       onSubmit={handleSubmit}
//       loading={loading}
//     >
//       <Input name="email" placeholder="Digite seu email" />
//       <Actions>
//         <Action>
//           Lembrou senha? <Link to="/login">Login</Link>
//         </Action>

//         <Action>
//           Já tem o código? <Link to="/recuperar">Recuperar</Link>
//         </Action>
//       </Actions>
//     </AuthForm>
//   );
// }
