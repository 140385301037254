import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// declare global {
//   interface Window {
//     gtag?: (
//       key: string,
//       trackingId: string,
//       config: { page_path: string }
//     ) => void;
//   }
// }

export const useTracking = (trackingId = 'UA-136680203-1') => {
  const { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return;
      if (!trackingId) {
        // eslint-disable-next-line no-console
        console.info(
          'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
        );
        return;
      }
      window.gtag('config', trackingId, { page_path: location.pathname });
    });

    return unlisten;
  }, [trackingId, listen]);
};
