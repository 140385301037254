import styled from 'styled-components';
// import {colors} from '../../environment';

export const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  padding: 30px;
  max-width: 500px;

  span {
    font-size: 2rem;
    text-align: center;
    margin: 2rem 0;
  }

  div {
    display: flex;
    justify-content: flex-start;
    .btn {
      border: 0;
      color: #fff;
      border-radius: 5px;
      padding: 10px;

      &--confirm {
        margin-left: 10px;
        background: #ee4d64;
      }

      &--cancel {
        background: #999;
      }
    }
  }
`;
