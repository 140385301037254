import React, { useMemo } from 'react';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CircularProgress } from '@material-ui/core';
import { Container, TableRowContainer, InnerTableRow } from './styles';
import { scoutsPositivos, scoutsNegativos } from '../../../utils/scouts';

import ScoutPill from '../ScoutPill';

export default function ComparisonPlayerTable({ playerA, playerB, rows = [] }) {
  const convertedPositiveScoutsToArray = useMemo(() => {
    const arr = [];
    Object.keys(playerA.scoutsPositivo).forEach((key) => {
      if (playerA.scoutsPositivo[key] || playerB.scoutsPositivo[key]) {
        arr.push({
          playerA: playerA.scoutsPositivo[key],
          description: scoutsPositivos[key],
          playerB: playerB.scoutsPositivo[key],
        });
      }
    });

    return arr;
  }, [playerA.scoutsPositivo, playerB.scoutsPositivo]);

  const convertedNegativeScoutsToArray = useMemo(() => {
    const arr = [];
    Object.keys(playerA.scoutsNegativo).forEach((key) => {
      if (playerA.scoutsNegativo[key] && playerB.scoutsNegativo[key]) {
        arr.push({
          playerA: playerA.scoutsNegativo[key],
          description: scoutsNegativos[key],
          playerB: playerB.scoutsNegativo[key],
        });
      }
    });

    return arr;
  }, [playerA.scoutsNegativo, playerB.scoutsNegativo]);

  return (
    <Container>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell size="medium" align="left" style={{ minWidth: '50%' }}>
              {playerA.nome}
            </TableCell>
            <TableCell size="small" align="center" />
            <TableCell size="medium" align="right" style={{ minWidth: '50%' }}>
              {playerB.nome}
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      {rows.map((row) => (
        <TableRowContainer>
          <InnerTableRow key={row.description}>
            {row.valueA ? (
              <ScoutPill color="#000" width="45px">
                {row.valueA}
              </ScoutPill>
            ) : (
              <CircularProgress />
            )}

            <text>{row.description}</text>
            {row.valueB ? (
              <ScoutPill
                width="45px"
                color="#000"
                style={{
                  marginLeft: 'auto',
                }}
              >
                {row.valueB}
              </ScoutPill>
            ) : (
              <CircularProgress />
            )}
          </InnerTableRow>
        </TableRowContainer>
      ))}

      {convertedPositiveScoutsToArray.map((scout) => (
        <TableRowContainer>
          <InnerTableRow>
            <ScoutPill width="45px">{scout.playerA}</ScoutPill>

            <text>{scout.description}</text>

            <ScoutPill width="45px" style={{ marginLeft: 'auto' }}>
              {scout.playerB}
            </ScoutPill>
          </InnerTableRow>
        </TableRowContainer>
      ))}

      {convertedNegativeScoutsToArray.map((scout) => (
        <TableRowContainer>
          <InnerTableRow>
            <ScoutPill width="45px" negative>
              {scout.playerA}
            </ScoutPill>

            <text>{scout.description}</text>

            <ScoutPill width="45px" negative style={{ marginLeft: 'auto' }}>
              {scout.playerB}
            </ScoutPill>
          </InnerTableRow>
        </TableRowContainer>
      ))}
    </Container>
  );
}
