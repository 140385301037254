import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  padding: 10px;
  border: 1px solid black;
  margin: 0 15px;
  border-radius: 5px;
  justify-content: center;
`;

export const Title = styled.h2`
  color: #000;
  font-size: 16px;
  font-weight: 400;
`;

export const SubTitleContainer = styled.div`
  padding: 5px;
  background-color: ${(props) => props.color};
  border-radius: 5px;
  margin: 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
`;

export const SubTitle = styled.h2`
  color: #000;
`;

export const GameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    height: 20px;
    margin-right: 5px;
  }
`;
