import styled, { css } from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Modal as MuiModal } from '@material-ui/core';
import respond from '../../styles/respond';
import colors from '../../styles/colors';

// export const Container = styled.div``;

export const Modal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaymentRow = styled.div`
  text-align: center;
  font-size: 1.4rem;
`;

export const Container = styled.div`
  height: 90vh;
  max-width: 40rem;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  width: 95vw;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  ${respond.phone} {
    width: 85vw;
  }
`;

export const Scroll = styled(PerfectScrollBar)`
  max-height: 80vh;
  padding: 5px 10px;
`;

export const Title = styled.h2`
  font-size: 2.6rem;
  font-weight: bold;
  color: ${colors.primary};

  margin-bottom: 2rem;
`;

export const Item = styled.div`
  padding-bottom: 1.8rem;
  border-bottom: 1px solid #eee;
  margin-bottom: 1.2rem;

  &:last-child {
    border-bottom: none;
  }
`;

export const SubTitle = styled.h3`
  font-size: 1.9rem;
  font-weight: bold;
  color: ${colors.primary};
`;

export const Row = styled.div`
  font-size: 1.6rem;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-top: 1rem;
  padding: 1.3rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 2rem;

  ${respond.phone} {
    min-width: 22rem;
  }

  ${(props) => (props.pointer ? 'cursor: pointer;' : null)}

  &.gray-bg {
    border: none;
    background: #ededed;
  }
  &.none-bg {
    border: none;
    background: none;
    padding: 0;
    margin-bottom: -1.2rem;
    margin-top: 3rem;
  }

  &.no-padding {
    padding: 0;
  }

  &.grid {
    /* flex: 1; */
  }

  svg {
    cursor: pointer;
  }
`;

export const Team = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 2.5rem;
    margin-right: 1rem;
  }
  span {
    font-size: 1.8rem;
  }
`;

export const Text = styled.span`
  color: ${colors.primary};
  font-weight: bold;

  &.black {
    color: #000;
  }
`;

export const Icon = styled.div`
  color: ${colors.primary};
  display: flex;
  align-items: center;
`;

export const BigText = styled.span`
  font-weight: bold;
  font-size: 1.8rem;

  ${({ previous }) =>
    previous &&
    css`
      font-weight: normal;
      font-size: 1.4rem;
      text-decoration: line-through;
      margin-right: 5px;
    `}
`;

export const LeaguesPrice = styled.span`
  font-weight: bold;
  color: #535353;
  margin-right: 0.8rem;
`;

export const Info = styled.div`
  text-align: right;
  font-size: 1.4rem;
  font-weight: bold;
  color: #535353;

  margin-top: 0.5rem;
`;

export const PaymentOption = styled.div`
  ${(props) =>
    props.selected ? `background: ${colors.primary}; color: #fff` : null};
  padding: 1rem;
  border-radius: 5px;
  font-weight: bold;
  min-height: 6rem;
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${respond.phone} {
    font-size: 1.3rem;
  }
`;

export const Option = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
  }
`;

export const Warn = styled.span`
  font-size: 1.5rem;
  text-align: justify;
  padding: 0.5rem;
  margin: 1rem 0;
  border-radius: 5px;
  box-shadow: 0.3rem 0.3rem 0.5rem rgba(0, 0, 0, 0.2);
`;

export const PaymentButton = styled.button`
  margin-top: 10px;
  font-size: 1.9rem;
  font-weight: bold;
  color: #fff;
  background: ${(props) => (props.disabled ? null : colors.primary)};

  ${(props) => (props.disabled ? 'cursor: not-allowed;' : null)};

  padding: 1rem;
  border: none;
  border-radius: 5px;
`;

export const AddAllButton = styled.button`
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  margin-bottom: 5px !important;
  color: #fff;
  background: ${colors.primary};
  font-size: 1.4rem;
`;

export const CouponContainer = styled.div`
  margin-top: 20px;

  ${({ success }) =>
    success &&
    css`
      .MuiFormHelperText-filled {
        color: green;
      }
    `}
`;

export const TotalContainer = styled.div``;
