import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const ScoutsContainer = styled.div`
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 39px);
  grid-gap: 3px;
`;

export const PlayerPhotoContainer = styled.div`
  position: relative;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* @media (min-width: 768px) {
    align-items: unset;
  } */
`;

export const PlayerPhoto = styled.img`
  max-width: 130px;
`;

export const PlayerTeamPhotoContainer = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PlayerTeamPhoto = styled.img`
  height: auto;
  width: 30px;
`;

export const PlayerTeamName = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
`;

export const PlayerNameContainer = styled.div`
  display: flex;
  /* position: absolute; */
  bottom: 0;
  flex-direction: column;
  align-items: center;
  background-color: #000000b9;
  color: #fff;
  border-radius: 8px;
  padding: 5px;
  width: 100%;
`;

export const PlayerName = styled.span`
  font-size: 1.8rem;
`;

export const PlayerPosition = styled.span`
  font-size: 1.6rem;
`;

export const BackgroudPaper = styled(Grid)`
  /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  border-radius: 4px;

  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  background-color: #fff;
  padding: 0;
`;
