import styled from 'styled-components';
import muiTheme from '../../../styles/MuiTheme';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Position = styled.span``;

export const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-child {
    margin-bottom: 5px;
  }

  ${muiTheme.breakpoints.up('sm')} {
    flex-direction: row;
    > div:first-child {
      margin-bottom: 0px;
      margin-right: 10px;
    }
  }
`;

export const CapitainPoints = styled.span`
  color: #555;
  margin-right: 2px;
  font-size: 1.2rem;
`;

export const Number = styled.span`
  font-size: 1.6rem;
  font-weight: 600;
`;

export const PlayerContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ScoutsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  > div {
    margin-top: 3px;
    margin-right: 3px;
  }
`;

export const PointsContainer = styled.div`
  display: flex;

  > span:last-child {
    margin-left: 3px;
  }
`;
