import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    margin-bottom: 10px;
  }
`;

export const RoundContainer = styled.div`
  display: flex;
  padding: 5px 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const ScoutsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > div {
    margin-top: 3px;
  }
`;

export const Wrapper = styled.div`
  *.MuiTableCell-sizeSmall {
    padding: 6px 6px 6px 6px;
  }
`;

export const PointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  span {
    &:last-child {
      font-weight: bold;
      font-size: 18px;
    }
  }
`;

export const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  * {
    justify-content: flex-start;
  }
`;
