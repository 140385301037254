import api from './_api';

export async function getFilter(path, valueKey, labelKey) {
  return api.get(`filtros/${path}`).then((res) => {
    const formattedOption = res.data.map((option) => ({
      value: option[valueKey],
      label: option[labelKey],
      ...option,
    }));
    return formattedOption;
  });
}
