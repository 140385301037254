import React from 'react';
import { Container, Grid } from '@material-ui/core';
import GoalsTable from '../../components/TeamComponents/GoalsTable';
import Name from '../../components/Name';

import { TeamContainer } from './styles';

function TeamStats() {
  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <TeamContainer>
            <img
              src="https://s.glbimg.com/es/sde/f/organizacoes/2016/05/03/inter65.png"
              alt="Nome do time"
            />
            <Name first="Inter" second="Rodada #38" />
          </TeamContainer>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <GoalsTable title="Gols prós casa" />
        </Grid>
        <Grid item xs={12} md={6}>
          <GoalsTable title="Gols prós fora" />
        </Grid>
        <Grid item xs={12} md={6}>
          <GoalsTable title="Gols sofridos casa" />
        </Grid>
        <Grid item xs={12} md={6}>
          <GoalsTable title="Gols sofridos fora" />
        </Grid>
      </Grid>
    </Container>
  );
}

export default TeamStats;
