import React from 'react';
import { Paper, TableContainer, Typography } from '@material-ui/core';
import { TitleContainer } from './styles';

// import { Container } from './styles';

function TableWrapper({ children, title, subtitle, center }) {
  return (
    <TableContainer component={Paper}>
      <TitleContainer center={center}>
        <Typography variant="h5" gutterBottom={!!subtitle}>
          {title}
        </Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </TitleContainer>
      {children}
    </TableContainer>
  );
}

export default TableWrapper;
