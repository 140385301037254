import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { useMediaQuery } from '@material-ui/core';
import TableWrapper from '../TableWrapper/index';

import MuiTheme from '../../styles/MuiTheme';

import { GameContainer } from './styles';

const ContentTable = ({ data, type }) => (
  <Table aria-label="simple table">
    <TableHead>
      <TableRow>
        {/* <TableCell>Rank</TableCell> */}
        <TableCell size="small" align="left">
          Clube
        </TableCell>
        <TableCell size="small" align="left">
          Pontos
        </TableCell>
        <TableCell size="small" align="left">
          {type === '1' ? 'Média' : 'Jogos'}
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {data.map((row, i) => (
        <TableRow key={row.idClube}>
          {/* <TableCell component="th" scope="row">
            {i + 1}
          </TableCell> */}
          <TableCell align="left">
            <GameContainer>
              <img alt={`Escudo ${row.nome}`} src={row.foto} />
              {row.nome}
            </GameContainer>
          </TableCell>
          <TableCell size="small" align="left">
            {row.totalPontos ? row.totalPontos.toFixed(2) : '0.00'}
          </TableCell>
          <TableCell size="small" align="left">
            {type === '1'
              ? row.mediaPontos
                ? row.mediaPontos.toFixed(2)
                : '0.00'
              : row.mediaPontos
              ? row.mediaPontos
              : '0'}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);
export default function TeamRank({ title, subtitle, data = [], type }) {
  const desktop = useMediaQuery(MuiTheme.breakpoints.up('md'));
  return (
    <TableWrapper title={title} subtitle={subtitle}>
      {/* <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <SubTitleContainer color={color}>
        <SubTitle>{subtitle}</SubTitle>
      </SubTitleContainer> */}
      {desktop ? (
        <PerfectScrollBar style={{ maxHeight: 'calc(100vh - 250px)' }}>
          <ContentTable data={data} type={type} />
        </PerfectScrollBar>
      ) : (
        <ContentTable data={data} type={type} />
      )}
    </TableWrapper>
  );
}
