import React from 'react';
import { useHistory } from 'react-router-dom';
import Card from '../../Card';
import PlayerInfo from '../../PlayerComponents/PlayerInfo';
import NumberInfo from '../../StatisticsComponents/NumberInfo';
import TwoTimes from '../../TwoTimes';

import scoutsToArrayConverter from '../../../utils/scoutsToArrayConverter';
import ScoutPill from '../../PlayerComponents/ScoutPill';
import {
  Container,
  Number,
  PlayerContent,
  PointsContainer,
  ScoutsContainer,
  StatsContainer,
} from './styles';

function EscalationPlayer({
  name,
  teamShield,
  team,
  position,
  picture,
  appreciation,
  points,
  capitain,
  positiveScouts,
  negativeScouts,
  playerId,
}) {
  const history = useHistory();
  return (
    <Card
      style={{ cursor: 'pointer' }}
      onClick={() => history.push(`/atletas/${playerId}`)}
    >
      <Container>
        {/* <Position>GOL</Position> */}
        <PlayerContent>
          <PlayerInfo
            picture={picture}
            playerName={name}
            position={position}
            teamName={team}
            teamShield={teamShield}
            horizontal
            capitain={capitain}
          />
          <ScoutsContainer>
            {positiveScouts &&
              scoutsToArrayConverter(positiveScouts).map((scout) => (
                <ScoutPill key={scout}>{scout}</ScoutPill>
              ))}
            {negativeScouts &&
              scoutsToArrayConverter(negativeScouts).map((scout) => (
                <ScoutPill negative key={scout}>
                  {scout}
                </ScoutPill>
              ))}
          </ScoutsContainer>
        </PlayerContent>
        <StatsContainer>
          <NumberInfo title="Pontos">
            <PointsContainer>
              {capitain && (
                <TwoTimes value={points && (points / 1.5).toFixed(2)} />
              )}{' '}
              <Number>{points && points.toFixed(2)}</Number>
            </PointsContainer>
          </NumberInfo>
          <NumberInfo
            title="Valorização"
            value={appreciation && appreciation.toFixed(2)}
            isColored
          />
        </StatsContainer>
      </Container>
    </Card>
  );
}

export default EscalationPlayer;
