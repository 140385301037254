import React from 'react';
import { Container, Grid } from '@material-ui/core';
import GoalsTable from '../../components/TeamComponents/GoalsTable';
import TeamData from '../../components/MyTeamComponents/TeamData';

// import { Container } from './styles';

function RealTeamDetails() {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={1} style={{ marginBottom: 5 }}>
        <Grid item xs={12} md={6} lg={3}>
          <TeamData
            shield="https://s.glbimg.com/es/sde/f/organizacoes/2016/05/03/inter65.png"
            teamName="Internacional"
            teamOwner="Rodada #01"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <GoalsTable title="Gols prós Casa" />
        </Grid>
        <Grid item xs={12} md={6}>
          <GoalsTable title="Gols prós Fora" />
        </Grid>
        <Grid item xs={12} md={6}>
          <GoalsTable title="Gols sofridos Casa" />
        </Grid>
        <Grid item xs={12} md={6}>
          <GoalsTable title="Gols sofridos Fora" />
        </Grid>
      </Grid>
    </Container>
  );
}

export default RealTeamDetails;
