import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Grid, useMediaQuery } from '@material-ui/core';
import ScrollBar from 'react-perfect-scrollbar';
import RadarChartComponent from '../../RadarChart';

import Card from '../../Card';

import { ChartContainer, Container, Wrapper, PlayersContainer } from './styles';
// import { playerA, playerB } from '../../../services/player';
import ComparisonPlayerTable from '../ComparisonPlayerTable';
import { getPlayerStats } from '../../../services/catimbaStats/players';
import PlayerInfo from '../PlayerInfo';
import MuiTheme from '../../../styles/MuiTheme';

function PlayerComparison({ playerA, playerB, onCloseClick }) {
  function createData(valueA, description, valueB) {
    return { valueA, description, valueB };
  }

  const [playerADetails, setPlayerADetails] = useState();
  const [playerBDetails, setPlayerBDetails] = useState();

  const md = useMediaQuery(MuiTheme.breakpoints.up('md'));
  // const [playerALoading, setPlayerALoading] = useState(true);
  // const [playerBLoading, setPlayerBLoading] = useState(true);
  const [rows, setRows] = useState([
    createData(playerA.jogos, 'Jogos', playerA.jogos),
    createData(playerA.media, 'Média Geral', playerA.media),
    createData(undefined, 'Média Casa', undefined),
    createData(undefined, 'Média Fora', undefined),
    createData(undefined, 'Média básica', undefined),
  ]);

  useEffect(() => {
    // setPlayerALoading(true);

    getPlayerStats(playerA.id)
      .then((res) => {
        setPlayerADetails(res.data);
      })
      .catch(() => {});
    // .finally(() => setPlayerALoading(false));

    // setPlayerBLoading(true);
    getPlayerStats(playerB.id)
      .then((res) => setPlayerBDetails(res.data))
      .catch(() => {});
    // .finally(() => setPlayerBLoading(false));
  }, [playerA.id, playerB.id]);

  const convertScoutsToGraphData = useCallback(
    (scoutsA = playerA.scoutsPositivo, scoutsB = playerB.scoutsPositivo) => {
      const dat = [];
      Object.keys(scoutsA).forEach((key) => {
        if (scoutsA[key] || scoutsB[key]) {
          dat.push({
            scout: key.toUpperCase(),
            playerA: scoutsA[key],
            playerB: scoutsB[key],
          });
        }
      });
      return dat;
    },
    [playerA, playerB]
  );

  useEffect(() => {
    if (playerADetails && playerBDetails) {
      setRows([
        createData(playerA.jogos, 'Jogos', playerB.jogos),
        createData(playerA.media, 'Média Geral', playerB.media),
        createData(
          playerADetails.medias.mediaCasa.toFixed(2),
          'Média Casa',
          playerBDetails.medias.mediaCasa.toFixed(2)
        ),
        createData(
          playerADetails.medias.mediaFora.toFixed(2),
          'Média Fora',
          playerBDetails.medias.mediaFora.toFixed(2)
        ),
        createData(
          playerADetails.medias.mediaBasica.toFixed(2),
          'Média Básica',
          playerBDetails.medias.mediaBasica.toFixed(2)
        ),
      ]);
    }
  }, [playerADetails, playerBDetails, playerA, playerB]);

  return (
    <Wrapper maxWidth="sm">
      <button type="button" onClick={onCloseClick}>
        &times;
      </button>
      <ScrollBar style={{ maxHeight: '90vh' }}>
        <Card>
          <PlayersContainer>
            <PlayerInfo
              playerName={playerA.nome}
              teamShield={playerA.fotoClube}
              position={playerA.detalhePosicao}
              picture={playerA.foto}
              size="small"
              horizontal={md}
            />
            <PlayerInfo
              playerName={playerB.nome}
              teamShield={playerB.fotoClube}
              position={playerB.detalhePosicao}
              picture={playerB.foto}
              alignRight
              size="small"
              horizontal={md}
            />
          </PlayersContainer>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Card>
                <Container>
                  <Typography variant="subtitle1">Scouts Positivos</Typography>
                  <ChartContainer>
                    <RadarChartComponent
                      data={convertScoutsToGraphData(
                        playerA.scoutsPositivo,
                        playerB.scoutsPositivo
                      )}
                      playerAName={playerA.nome}
                      playerBName={playerB.nome}
                    />
                  </ChartContainer>
                </Container>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card>
                <Container>
                  <Typography variant="subtitle1">Scouts Negativos</Typography>
                  <ChartContainer>
                    <RadarChartComponent
                      data={convertScoutsToGraphData(
                        playerA.scoutsNegativo,
                        playerB.scoutsNegativo
                      )}
                      playerAName={playerA.nome}
                      playerBName={playerB.nome}
                    />
                  </ChartContainer>
                </Container>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <ComparisonPlayerTable
                playerA={playerA}
                playerB={playerB}
                rows={rows}
              />
            </Grid>
          </Grid>
        </Card>
      </ScrollBar>
    </Wrapper>
  );
}
export default PlayerComparison;
