export function storeCache({ positions, status, orderBy, clubs }) {
  return {
    payload: { positions, status, orderBy, clubs },
    type: '@playerCache/STORE',
  };
}

export function clearCache() {
  return {
    type: '@playerCache/CLEAR',
  };
}
