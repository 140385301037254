import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ScrollBar from 'react-perfect-scrollbar';
import { Box, useMediaQuery } from '@material-ui/core';
import TableWrapper from '../../TableWrapper/index';
import Points from '../Points';
import MuiTheme from '../../../styles/MuiTheme';

export default function AverageTable({ data = [] }) {
  const mdUp = useMediaQuery(MuiTheme.breakpoints.up('md'));
  const Wrapper = mdUp ? ScrollBar : Box;

  return (
    <TableWrapper title="M. básica X M. geral">
      <Wrapper style={{ maxHeight: mdUp ? 385 : undefined }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Rodada</TableCell>
              <TableCell size="small" align="left">
                Pontos
              </TableCell>
              <TableCell size="small" align="left">
                M.Básica
              </TableCell>
              <TableCell size="small" align="left">
                M.Geral
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.rodada}>
                <TableCell component="th" scope="row">
                  #{row.rodada}
                </TableCell>
                <TableCell size="small" align="left">
                  {row.pontos}
                </TableCell>
                <TableCell size="small" align="left">
                  <Points value={row.mediaBasica}> {row.mediaBasica}</Points>
                </TableCell>
                <TableCell size="small" align="ledt">
                  <Points value={row.mediaBasica}> {row.mediaGeral}</Points>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Wrapper>
    </TableWrapper>
  );
}
