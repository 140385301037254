import api from './_api';

export function getPlans() {
  return api.get('/planos');
}

export function createPlan({
  titulo,
  valor,
  descricao,
  status,
  destaque,
  vigencia,
}) {
  return api.post('/planos', {
    titulo,
    valor,
    descricao,
    status: Number(status),
    destaque: Number(destaque),
    vigencia,
  });
}

export function updatePlan({
  id,
  titulo,
  valor,
  descricao,
  status,
  destaque,
  vigencia,
}) {
  return api.put('/planos', {
    id,
    titulo,
    valor,
    descricao,
    status: Number(status),
    destaque: Number(destaque),
    vigencia,
  });
}

export function deletePlan(id) {
  return api.delete(`/planos/${id}`);
}
