import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import logo from '../../../assets/logo.png';
import { login } from '../../../services/catimbaStats/auth';
import { Wrapper } from '../sharedStyles';
import GoBackAndHome from '../../../components/GoBackAndHome';
import history from '../../../services/history';

export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Insira um e-mail válido')
      .required('E-mail não pode ser vazio'),
    password: Yup.string().required('Senha não pode estar vazio'),
  });

  const handleSubmit = useCallback(({ email, password }) => {
    setLoading(true);
    login(email, password).finally(() => setLoading(false));
  }, []);

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs">
      {/* <GoBackAndHome /> */}
      <Wrapper elevation={1}>
        <Link to="/">
          <img src={logo} alt="Logo do Catimba Stats"/>
        </Link>
        <Typography variant="h5">Entre na sua conta Catimba Stats</Typography>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Field
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    autoComplete="email"
                    autoFocus
                    error={errors.email && touched.email}
                    helperText={touched.email && errors.email}
                  />
                )}
              />
              <Field
                name="password"
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Senha"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    error={errors.password && touched.password}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {!showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className
                disabled={loading}
              >
                Entrar
              </Button>

              <Grid container>
                <Grid item xs>
                  <Link to="/esqueci">
                    <Typography color="primary" variant="body2">
                      Esqueci minha senha
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs>
                  <Link to="/cadastro">
                    <Typography color="primary" variant="body2">
                      Não posui conta? Cadastre-se
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </Container>
  );
}
