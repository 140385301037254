import React, { useState, useEffect, useMemo } from 'react';
import { Container, Grid } from '@material-ui/core';
import GeneralVision from '../../components/MyTeamComponents/GeneralVision';
import BestPlayers from '../../components/MyTeamComponents/BestPlayers';
import RoundAppreciationTable from '../../components/MyTeamComponents/RoundAppreciationTable';
import ChartComponent from '../../components/ChartComponents/Chart';
import CapitainTable from '../../components/MyTeamComponents/CapitainTable/index';
import PerPositionTable from '../../components/MyTeamComponents/PerPositionTable';
import { getMyTeamStats } from '../../services/catimbaStats/statistics';
import Highlight from '../../components/MyTeamComponents/Highlight';
import Loader from '../../components/Loader';
// import { Container } from './styles';

function MyTeamStats({ match }) {
  const [team, setTeam] = useState();

  useEffect(() => {
    if (match.params) {
      getMyTeamStats(match.params.id).then((res) => setTeam(res.data));
    }
  }, [match.params]);

  const pointsPerPositionChartData = useMemo(() => {
    const data = [];
    if (team) {
      data.push(['', team.nome]);
      Object.keys(team.pontosPosicao).forEach((key) => {
        data.push([
          key.substr(0, 3).toUpperCase(),
          team.pontosPosicao[key].pontos,
        ]);
      });
    }
    return data;
  }, [team]);

  const capitainChartData = useMemo(() => {
    const data = [];
    if (team) {
      data.push(['', team.nome]);
      team.capitao.pontuadores.forEach((cap) => {
        data.push([`#${cap.rodada}`, cap.pontos]);
      });
    }
    return data;
  }, [team]);

  const appreciationChartData = useMemo(() => {
    const data = [];

    if (team) {
      data.push(['', team.nome]);

      team.variacao.forEach((appreciation) => {
        data.push([`# ${appreciation.rodada}`, appreciation.variacao]);
      });
    }

    return data;
  }, [team]);

  return team ? (
    <Container>
      <Grid container spacing={1} style={{ marginBottom: 5 }}>
        <Grid item xs={12} md={6} lg={4}>
          <GeneralVision
            teamName={team.nome}
            teamOwner={team.cartola}
            shield={team.foto}
            average={team.geral.media}
            total={team.geral.total}
            patrimonio={team.geral.patrimonio}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={4}>
          <Highlight
            higher={team.destaque.maiorPontuacao}
            lower={team.destaque.menorPontuacao}
            lowerDescription={`Rodada #${team.destaque.rodadaMenorPontuacao}`}
            higherDescription={`Rodada #${team.destaque.rodadaMaiorPontuacao}`}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <BestPlayers
            title="Pontuadores"
            descriptionP1={`Rodada #${team.pontuadores.maiorPontuador.rodada}`}
            pictureP1={team.pontuadores.maiorPontuador.foto}
            playerNameP1={team.pontuadores.maiorPontuador.nome}
            titleP1="Maior Pontuador"
            valueP1={team.pontuadores.maiorPontuador.pontos}
            positionP1={team.pontuadores.maiorPontuador.posicao}
            descriptionP2={`Rodada #${team.pontuadores.menorPontuador.rodada}`}
            pictureP2={team.pontuadores.menorPontuador.foto}
            playerNameP2={team.pontuadores.menorPontuador.nome}
            titleP2="Menor Pontuador"
            valueP2={team.pontuadores.menorPontuador.pontos}
            positionP2={team.pontuadores.menorPontuador.posicao}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <BestPlayers
            title="Preço"
            descriptionP1={`Rodada #${team.preco.maiorPreco.rodada}`}
            pictureP1={team.preco.maiorPreco.foto}
            playerNameP1={team.preco.maiorPreco.nome}
            titleP1="Maior Preço"
            valueP1={team.preco.maiorPreco.preco}
            positionP1={team.preco.maiorPreco.posicao}
            descriptionP2={`Rodada #${team.preco.menorPreco.rodada}`}
            pictureP2={team.preco.menorPreco.foto}
            playerNameP2={team.preco.menorPreco.nome}
            titleP2="Menor Preço"
            valueP2={team.preco.menorPreco.preco}
            positionP2={team.preco.menorPreco.posicao}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PerPositionTable
            data={[
              {
                posicao: 'Goleiro',
                pontos:
                  typeof team.pontosPosicao.goleiro.pontos === 'number'
                    ? team.pontosPosicao.goleiro.pontos.toFixed(2)
                    : 0,
                media:
                  typeof team.pontosPosicao.goleiro.media === 'number'
                    ? team.pontosPosicao.goleiro.media.toFixed(2)
                    : 0,
              },
              {
                posicao: 'Lateral',
                pontos:
                  typeof team.pontosPosicao.lateral.pontos === 'number'
                    ? team.pontosPosicao.lateral.pontos.toFixed(2)
                    : 0,
                media:
                  typeof team.pontosPosicao.lateral.media === 'number'
                    ? team.pontosPosicao.lateral.media.toFixed(2)
                    : 0,
              },
              {
                posicao: 'Zagueiro',
                pontos:
                  typeof team.pontosPosicao.zagueiro.pontos === 'number'
                    ? team.pontosPosicao.zagueiro.pontos.toFixed(2)
                    : 0,
                media: team.pontosPosicao.zagueiro.media.toFixed(2),
              },
              {
                posicao: 'Meia',
                pontos:
                  typeof team.pontosPosicao.meia.pontos === 'number'
                    ? team.pontosPosicao.meia.pontos.toFixed(2)
                    : 0,
                media: team.pontosPosicao.meia.media.toFixed(2),
              },
              {
                posicao: 'Atacante',
                pontos:
                  typeof team.pontosPosicao.atacante.pontos === 'number'
                    ? team.pontosPosicao.atacante.pontos.toFixed(2)
                    : 0,
                media: team.pontosPosicao.atacante.media.toFixed(2),
              },
              {
                posicao: 'Técnico',
                pontos:
                  typeof team.pontosPosicao.tecnico.pontos === 'number'
                    ? team.pontosPosicao.tecnico.pontos.toFixed(2)
                    : 0,
                media: team.pontosPosicao.tecnico.media.toFixed(2),
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <ChartComponent
            title="Pontos por posição"
            data={pointsPerPositionChartData}
            height={315}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CapitainTable
            data={team.capitao.pontuadores}
            average={team.capitao.mediaPontos}
            totalPoints={team.capitao.totalPontos}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <ChartComponent
            title="Capitão"
            type="Line"
            height={405}
            data={capitainChartData}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <RoundAppreciationTable data={team.variacao} />
        </Grid>
        <Grid item xs={12} lg={8}>
          <ChartComponent
            title="Variação por rodada"
            type="Line"
            height={340}
            data={appreciationChartData}
          />
        </Grid>
      </Grid>
    </Container>
  ) : (
    <Loader />
  );
}

export default MyTeamStats;
