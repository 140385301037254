import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useMediaQuery, Button } from '@material-ui/core';
import logo from '../../assets/logo-stats.png';
import {
  Container,
  Navbar,
  Menu,
  MobileLogo,
  MobileMenuButton,
  BurgerMenuWrapper,
  NavLink,
  ButtonsWrapper,
} from './styles';
import { signOut } from '../../store/modules/auth/actions';
import colors from '../../styles/colors';
import history from '../../services/history';

export default function Header() {
  const dispatch = useDispatch();
  const desktop = useMediaQuery('(min-width:1024px)');

  const [open, setOpen] = useState();

  const auth = useSelector((state) => state.auth);

  function isMenuOpen(state) {
    setOpen(state.isOpen);
  }

  const styles = {
    bmBurgerButton: {
      position: 'absolute',
      width: '36px',
      height: '36px',
      left: '1rem',
      top: '0.8rem',
    },
    bmBurgerBars: {
      background: '#fff',
      height: '1px',
      width: '20px',
    },
    bmBurgerBarsHover: {
      background: '#a90000',
    },
    bmCrossButton: {
      height: '24px',
      width: '24px',
    },
    bmCross: {
      background: '#fff',
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
    },
    bmMenu: {
      background: colors.primary,
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em',
      textAlign: 'center',
    },
    bmMorphShape: {
      fill: '#fff',
    },
    bmItemList: {
      color: '#fff',
      padding: '0.8em',
      display: 'flex',
      flexDirection: 'column',
    },
    bmItem: {
      display: 'inline-block',
      color: '#fff',
      fontWeight: 'bold',
      borderBottom: '1px solid rgba(255,255,255,0.2)',
      fontSize: '1.3rem',
      padding: '1.2rem 0',
      textTransform: 'uppercase',
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
    },
  };

  function handleHideMenu() {
    setOpen(false);
  }

  function handleExit() {
    dispatch(signOut());
    setOpen(false);
  }
  // TODO: melhorar o menu para membros logados, já que deverá haver apenas um menu

  // TODO: alterar componente de botoes para um link do react router dom, assim está totalmente incorreto
  return (
    <Container>
      {desktop && (
        <>
          <Navbar>
            <Link to="/">
              <img src={logo} alt="Logo Stats" />
            </Link>
            <NavLink exact activeClassName="selected" to="/">
              Home
            </NavLink>
            <NavLink activeClassName="selected" to="/planos-e-precos">
              Assine já
            </NavLink>
            {/* <a
              href="https://chat.whatsapp.com/LSGjbnAI8JGDYMfAkq5XMj"
              target="blank"
            >
              Suporte
            </a> */}
          </Navbar>
          <ButtonsWrapper>
            {auth.signed ? (
              <>
                <Button onClick={() => handleExit()} variant="outlined">
                  Sair
                </Button>
                <Button
                  onClick={() => history.push('/dashboard')}
                  variant="contained"
                  color="primary"
                >
                  Área do sócio
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => history.push('/login')}
                  variant="outlined"
                >
                  Entrar
                </Button>
                <Button
                  onClick={() => history.push('/cadastro')}
                  variant="contained"
                  color="primary"
                >
                  Cadastrar
                </Button>
              </>
            )}
          </ButtonsWrapper>
        </>
      )}
      {!desktop && (
        <BurgerMenuWrapper>
          {auth.signed ? (
            <Menu styles={styles} isOpen={open} onStateChange={isMenuOpen}>
              <Link to="/" onClick={handleHideMenu}>
                Home
              </Link>
              <Link to="/planos-e-precos" onClick={handleHideMenu}>
                Assine já
              </Link>
              {/* <a
                href="https://chat.whatsapp.com/LSGjbnAI8JGDYMfAkq5XMj"
                target="blank"
              >
                Suporte
              </a> */}

              <MobileMenuButton
                className="white"
                to="/dashboard"
                onClick={handleHideMenu}
              >
                Área de sócio
              </MobileMenuButton>
              <MobileMenuButton to="/" onClick={handleExit}>
                Sair
              </MobileMenuButton>
            </Menu>
          ) : (
            <Menu styles={styles} isOpen={open} onStateChange={isMenuOpen}>
              <Link to="/" onClick={handleHideMenu}>
                Home
              </Link>
              <Link to="/planos-e-precos" onClick={handleHideMenu}>
                Assine já
              </Link>
              {/* <a
                href="https://chat.whatsapp.com/LSGjbnAI8JGDYMfAkq5XMj"
                target="blank"
              >
                Suporte
              </a> */}

              <MobileMenuButton to="/login" onClick={handleHideMenu}>
                Entrar
              </MobileMenuButton>
              <MobileMenuButton
                to="/cadastro"
                className="white"
                onClick={handleHideMenu}
              >
                Cadastrar
              </MobileMenuButton>
            </Menu>
          )}
          <MobileLogo to="/">
            <img src={logo} alt="Logo Catimba" />
          </MobileLogo>
        </BurgerMenuWrapper>
      )}
    </Container>
  );
}
