import styled from 'styled-components';
import { Container } from '@material-ui/core';

export const Wrapper = styled(Container)`
  margin-bottom: 2rem;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SelectContainer = styled.div`
  margin: 1rem 0;
  max-width: 20rem;
`;
