import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

// import { Container } from './styles';

function AdminTableLoading() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      padding="1rem"
      width="100%"
      heigth="100px"
    >
      <CircularProgress />
    </Box>
  );
}

export default AdminTableLoading;
