import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;
export const Badge = styled.button`
  background: none;
  border: 0;
  position: relative;

  display: flex;
  align-items: center;

  font-weight: bold;
`;

export const OptionsContainer = styled.div`
  height: 260px;
  padding: 0.5rem 1.5rem;
`;

export const Options = styled.div`
  position: absolute;
  width: 100px;
  left: calc(50% - 45px);
  top: calc(100% + 20px);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  padding: 15px 5px;
  display: ${props => (props.visible ? 'block' : 'none')};
  z-index: 1101;

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 20px);
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid rgba(0, 0, 0, 0.6);
  }
`;

export const Option = styled.div`
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  color: #fff;
  font-size: 1.4rem;

  margin-bottom: 0.7rem;
  padding-bottom: 0.7rem;
  border-bottom: 1px solid #666;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const Wrapper = styled.button`
  background: none;
  border: 0;
  position: relative;
  color: unset;
`;
