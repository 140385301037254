import { Grid, Box, useMediaQuery, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import ChartComponent from '../../components/ChartComponents/Chart';
import DashboardPlayerTable from '../../components/DashboardPlayerTable';
import DashboardBestScoutsTable from '../../components/DashboardBestScoutsTable';
import DashboardTeam from '../../components/DashboardTeam';
import { getDashboardInfo } from '../../services/catimbaStats/statistics';
import { getGames } from '../../services/catimbaStats/games';
import MuiTheme from '../../styles/MuiTheme';
import { Container } from './styles';
// import { Container } from './styles';

function Dashboard() {
  const [info, setInfo] = useState();
  const [bestAvgTeam, setBestAvgTeam] = useState([['Time', 'Pontos']]);
  const [givenPointsAvg, setGivenPointsAvg] = useState([['Time', 'Pontos']]);
  const [playersByPosition, setPlayersByPosition] = useState();
  const [playersByScouts, setPlayersByScouts] = useState();
  const [partidas, setPartidas] = useState();

  const desktop = useMediaQuery(MuiTheme.breakpoints.up('md'));

  const sortTeamAvg = (best = [], keyName = '') => {
    const data = [['Time', 'Pontos']];

    best.forEach((team) => {
      data.push([team.nome, team[keyName]]);
    });

    return data;
  };

  const organizeInfo = (data) => {
    const given = sortTeamAvg(data.pontuacaoMediaCedida, 'mediaCedida');
    const best = sortTeamAvg(data.pontuacaoMedia, 'mediaPontos');
    const byPosition = data.jogadoresMelhoresMedias;
    const byScouts = data.jogadoresMelhoresScouts;

    setPlayersByScouts(byScouts);
    setPlayersByPosition(byPosition);
    setBestAvgTeam(best);
    setGivenPointsAvg(given);
  };

  useEffect(() => {
    getDashboardInfo().then((res) => {
      organizeInfo(res.data);
      setInfo(res.data);
    });

    getGames(undefined, undefined).then((res) => {
      setPartidas(res.data.partidas);
    });
  }, []);

  return (
    <Grid container spacing={2}>
      {info ? (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom align="center">
              Resumo da Rodada {info.rodada}
            </Typography>
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} lg={3}>
            <ChartComponent
              subtitle="Top 5 clubes com melhores médias"
              data={bestAvgTeam}
              options={{
                bars: 'horizontal',
              }}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            {partidas && <DashboardTeam games={partidas} />}
          </Grid>

          <Grid item xs={12} lg={3}>
            <ChartComponent
              subtitle="Top 5 clubes que mais cedem pontos"
              data={givenPointsAvg}
              options={{
                bars: 'horizontal',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Top 5 jogadores com melhores médias por posição
            </Typography>
          </Grid>
          <Grid item md={2} xs={6}>
            {playersByPosition && (
              <DashboardPlayerTable
                players={playersByPosition.goleiros}
                title="Goleiro"
              />
            )}
          </Grid>
          <Grid item md={2} xs={6}>
            {playersByPosition && (
              <DashboardPlayerTable
                players={playersByPosition.laterais}
                title="Lateral"
              />
            )}
          </Grid>
          <Grid item md={2} xs={6}>
            {playersByPosition && (
              <DashboardPlayerTable
                players={playersByPosition.zagueiros}
                title="Zagueiro"
              />
            )}
          </Grid>
          <Grid item md={2} xs={6}>
            {playersByPosition && (
              <DashboardPlayerTable
                players={playersByPosition.meias}
                title="Meia"
              />
            )}
          </Grid>
          <Grid item md={2} xs={6}>
            {playersByPosition && (
              <DashboardPlayerTable
                players={playersByPosition.atacantes}
                title="Atacante"
              />
            )}
          </Grid>
          <Grid item md={2} xs={6}>
            {playersByPosition && (
              <DashboardPlayerTable
                players={playersByPosition.tecnicos}
                title="Técnico"
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Top 5 jogadores com melhores números por scout
            </Typography>
          </Grid>
          <Grid item md={2} xs={6}>
            {playersByScouts && (
              <DashboardBestScoutsTable
                players={playersByScouts.gols}
                title="Gol"
              />
            )}
          </Grid>
          <Grid item md={2} xs={6}>
            {playersByScouts && (
              <DashboardBestScoutsTable
                players={playersByScouts.assistencias}
                title="Assistência"
              />
            )}
          </Grid>
          <Grid item md={2} xs={6}>
            {playersByScouts && (
              <DashboardBestScoutsTable
                players={playersByScouts.desarmes}
                title="Desarme"
              />
            )}
          </Grid>
          <Grid item md={2} xs={6}>
            {playersByScouts && (
              <DashboardBestScoutsTable
                players={playersByScouts.finalizações}
                title="Finalização"
              />
            )}
          </Grid>
          <Grid item md={2} xs={6}>
            {playersByScouts && (
              <DashboardBestScoutsTable
                players={playersByScouts.defesas}
                title="Defesa"
              />
            )}
          </Grid>
          <Grid item md={2} xs={6}>
            {playersByScouts && (
              <DashboardBestScoutsTable
                players={playersByScouts.saldogols}
                title="Saldo Gol"
              />
            )}
          </Grid>
        </>
      ) : (
        [1, 2, 3, 4, 5, 6].map((e) => (
          <Grid key={e} item xs={12} md={4}>
            <Box width="100%">
              <Skeleton
                variant="rect"
                width="100%"
                height={desktop ? 300 : 200}
              />
            </Box>
          </Grid>
        ))
      )}
    </Grid>
  );
}

export default Dashboard;
