export default function scoutsToArrayConverter(scouts = {}) {
  const arr = [];

  if (scouts) {
    if (typeof scouts === 'string') {
      return scouts ? scouts.trim().split(',') : [];
    }
    Object.keys(scouts).forEach((key) => {
      if (scouts[key]) arr.push(`${key.toUpperCase()}:${scouts[key]}`);
    });
  }

  return arr;
}
