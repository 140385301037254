import api from './_api';

const base = '/cupom';

export function getCoupon({ page = 0, size = 50 }) {
  return api.get(base, {
    params: {
      page,
      size,
    },
  });
}

export function createCoupon({ nome, validade, status, percentualDesconto }) {
  return api.post(base, {
    nome,
    validade,
    status,
    percentualDesconto,
  });
}

export function updateCoupon({
  id,
  nome,
  validade,
  status,
  percentualDesconto,
}) {
  return api.put(base, {
    id,
    nome,
    validade,
    status,
    percentualDesconto,
  });
}

export function deleteCoupon(id) {
  return api.delete(`${base}/${id}`);
}

export function couponReport() {
  return api.get(`${base}/relatorio`);
}
