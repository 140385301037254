import axios from 'axios';
import api from './_api';

// var CancelToken = axios.CancelToken;
// var call1 = CancelToken.source();

export function getPlayersList() {
  let call;
  return async ({
    page = 0,
    size = 50,
    idPosicao,
    idStatus,
    ordena = 'media',
    idClube,
    nomeAtleta,
  }) => {
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();
    return api
      .get(`atletas`, {
        cancelToken: call.token,
        params: {
          page,
          size,
          idPosicao,
          idStatus,
          ordena,
          idClube,
          nomeAtleta,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          return Promise.reject(thrown);
        }
        return Promise.reject(thrown);
      });
  };
}

export async function getPlayerStats(id) {
  return api.get(`atletas/${id}`);
}
