import { toast } from 'react-toastify';
import api from './_api';
import history from '../history';
import { store } from '../../store';
import {
  saveUsersSuccess,
  signOut as actionSignOut,
} from '../../store/modules/auth/actions';

export async function login(email, password) {
  await api
    .post(
      '/login',
      {},
      {
        params: { email, senha: password },
        headers: { Channel: process.env.NODE_ENV === 'development' ? 2 : 1 },
        // headers: { Channel: 1 },
      }
    )
    .then((response) => {
      const user = response.data;
      // yield put(signInSuccess(user));
      store.dispatch(saveUsersSuccess(user));

      toast.success('Login realizado com sucesso!');

      api.defaults.headers.common = {
        ...api.defaults.headers.common,
        Authorization: user.token,
      };

      if (response.data.assinante) {
        history.push('/dashboard');
      } else {
        history.push('/planos-e-precos');
      }
    })
    .catch((err) => {
      if (err && err.response && err.response.data) {
        if (err.response.data.status === 406) {
          history.push('/confirmacao');

          toast.warn(
            err.response.data.mensagem || 'Usuário não validou o email!'
          );
          return;
        }

        if (err.response.data.status === 405) {
          history.push('/recuperar');

          toast.warn(
            err.response.data.mensagem || 'Usuário não validou o email!'
          );
          return;
        }

        if (err.response.data.mensagem) {
          toast.error(err.response.data.mensagem);
          return;
        }
      }
      toast.error('Algo deu errado, por favor tente novamente');
    });
}

export async function register({
  nome,
  email,
  senha,
  cpf,
  celular,
  cep,
  rua,
  numero,
  complemento = '',
  bairro,
  cidade,
  uf,
}) {
  await api
    .post(
      '/usuario',
      {
        nome,
        email,
        senha,
        cpf: cpf.replace(/[^0-9]/g, ''),
        celular: celular.replace(/[^0-9]/g, ''),
        cep: cep.replace(/[^0-9]/g, ''),
        rua,
        numero,
        complemento,
        bairro,
        cidade,
        uf,
      },
      {}
    )
    .then((response) => {
      history.push('/confirmacao');
      toast.success(
        response.data.mensagem ||
          'Cadastro efetuado com sucesso! Valide sua conta para continuar'
      );
    })
    .catch((err) => {
      if (err.response.data.mensagem) {
        toast.error(err.response.data.mensagem);
        return;
      }
      toast.error('Algo deu errado, por favor tente novamente');
    });
}

export async function updateProfile({
  id,
  nome,
  email,
  senha,
  cpf,
  celular,
  cep,
  rua,
  numero,
  complemento = '',
  bairro,
  cidade,
  uf,
}) {
  await api
    .put('/usuario', {
      id: Number(id),
      nome,
      email,
      senha,
      cpf,
      celular,
      cep,
      rua,
      numero,
      complemento,
      bairro,
      cidade,
      uf,
    })
    .then(() => {
      history.push('/meus-times');
      toast.success('Cadastro alterado com sucesso!');
    })
    .catch((err) => {
      if (err.response.data.mensagem) {
        toast.error(err.response.data.mensagem);
        return;
      }

      toast.error('Algo deu errado, por favor tente novamente');
    });
}

export async function passwordRecover(email) {
  await api
    .post(`usuario/solicitarResetSenha?email=${email}`)
    .then((response) => {
      history.push('/recuperar');
      toast.success(
        response.data.mensagem || 'Insira o código recebido por email'
      );
    })
    .catch((err) => {
      if (err.response.data.mensagem) {
        toast.error(err.response.data.mensagem);
        return;
      }

      toast.error('Algo deu errado, por favor tente novamente');
    });
}

export async function passwordValidation({ email, code }) {
  await api
    .post(`usuario/validarResetSenha?email=${email}&codigo=${code}`)
    .then((response) => {
      history.push({
        pathname: '/nova-senha',
        state: {
          id: response.data.id,
          token: response.data.tokenValidacao,
        },
      });

      toast.success(
        response.data.mensagem || 'Ótimo! Agora insira uma nova senha'
      );
    })
    .catch((err) => {
      if (err.response.data.mensagem) {
        toast.error(err.response.data.mensagem);
        return;
      }

      toast.error('Algo deu errado, por favor tente novamente');
    });
}

export async function passwordReset({ id, password, token }) {
  await api
    .put(`usuario/resetarSenha`, {
      id: Number(id),
      novaSenha: password,
      tokenValidacao: token,
    })
    .then((response) => {
      toast.success(
        response.data.mensagem || 'Tudo certo! Faça seu login para continuar.'
      );
      store.dispatch(actionSignOut());
      history.push('/login');
    })
    .catch((err) => {
      if (err.response.data.mensagem) {
        toast.error(err.response.data.mensagem);
        return;
      }

      toast.error('Algo deu errado, por favor tente novamente');
    });
}

export async function passwordUpdate({ id, password, oldPassword }) {
  await api
    .put(`usuario/alterarSenha`, {
      id: Number(id),
      senha: oldPassword,
      novaSenha: password,
    })
    .then((response) => {
      toast.success(
        response.data.mensagem ||
          'Senha alterada com sucesso! Faça seu login novamente'
      );
      store.dispatch(actionSignOut());

      history.push('/login');
    })
    .catch((err) => {
      if (err.response.data.mensagem) {
        toast.error(err.response.data.mensagem);
        return;
      }

      toast.error('Algo deu errado, por favor tente novamente');
    });
}

export async function codeSend(email) {
  await api
    .post(`usuario/enviarCodigo?email=${email}`)
    .then((response) => {
      history.push('/confirmacao');
      toast.success(
        response.data.mensagem || 'Insira o código recebido para continuar'
      );
    })
    .catch((err) => {
      if (err.response.data.mensagem) {
        toast.error(err.response.data.mensagem);
        return;
      }

      toast.error('Algo deu errado, por favor tente novamente');
    });
}

export async function codeValidation({ email, code }) {
  await api
    .post(`usuario/validarCodigo?email=${email}&codigo=${code}`)
    .then((response) => {
      history.push('/login');
      toast.success(
        response.data.mensagem || 'Tudo certo! Faça seu login para continuar.'
      );
    })
    .catch((err) => {
      if (err.response.data.mensagem) {
        toast.error(err.response.data.mensagem);
        return;
      }

      toast.error('Algo deu errado, por favor tente novamente');
    });
}

export function signOut() {
  store.dispatch(actionSignOut());

  history.push('/');

  api.defaults.headers.common = {};
}

export async function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;
  // console.tron.log(token);
  if (token) {
    api.defaults.headers.common = {
      ...api.defaults.headers.common,
      Authorization: token,
    };
  }
}
