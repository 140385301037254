import React, { useState, useEffect } from 'react';
import Sidebar from 'react-sidebar';
import {
  MdArrowBack,
  MdPeople,
  MdAccountBalanceWallet,
  MdAttachMoney,
  MdExitToApp,
  MdShuffle,
  MdGpsFixed,
  MdTimeline,
  MdSecurity,
  MdSwapHoriz,
  MdViewAgenda,
  MdDoneAll,
  MdAccountCircle,
  MdSettings,
  MdCreditCard,
  MdModeEdit,
  MdVpnKey,
  MdLocalAtm,
  MdMenu,
  MdOpenInBrowser,
  MdDashboard,
} from 'react-icons/md';
import { GiSoccerBall } from 'react-icons/gi';
import { FaNewspaper } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import MuiContainer from '@material-ui/core/Container';
import { Typography, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory, useLocation } from 'react-router-dom';
import logo from '../../assets/logo-stats.png';
import {
  Container,
  SidebarMenu,
  Title,
  Link,
  Separator,
  HeaderContainer,
  Burguer,
  User,
  Wrapper,
  MobileHeader,
  StylesWrapper,
  ButtonIcon,
} from './styles';
import { signOut } from '../../store/modules/auth/actions';
import Dropdown from '../Dropdown';
import UserIcon from '../UserIcon';
import MuiTheme from '../../styles/MuiTheme';

const mql = window.matchMedia(`(min-width: 900px)`);

export default function AdminSidebar({ children, title }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarDocked, setSidebarDocked] = useState(mql.matches);
  const history = useHistory();
  const user = useSelector((state) => state.auth.profile);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const mdUp = useMediaQuery(MuiTheme.breakpoints.up('md'));

  function mediaQueryChanged() {
    setSidebarDocked(mql.matches);
    setSidebarOpen(false);
  }

  useEffect(() => {
    mql.addListener(mediaQueryChanged);

    return () => {
      mql.removeListener(mediaQueryChanged);
    };
  });

  function handleToggleSideBar(isOpen) {
    setSidebarOpen(isOpen);
  }

  function handleExpanded() {
    setOpen(!open);
  }

  const adminPaths = [
    '/usuarios',
    '/meios-de-pagamento',
    '/planos',
    '/financeiro',
    '/cupom',
    '/relatorio-cupom',
  ];
  const myAccountPaths = [
    '/meus-pagamentos',
    '/alterar-cadastro',
    '/alterar-senha',
  ];

  return (
    user && (
      <StylesWrapper
        expanded={!sidebarDocked || open || expanded}
        onMouseLeave={() => setExpanded(false)}
      >
        <Sidebar
          sidebar={
            <SidebarMenu>
              {/* {sidebarDocked &&
                (open ? (
                  <ButtonIcon onClick={handleExpanded}>
                    <MdChevronLeft color="#eee" size={30} />
                  </ButtonIcon>
                ) : (
                  <ButtonIcon onClick={handleExpanded}>
                    <MdChevronRight color="#eee" size={30} />
                  </ButtonIcon>
                ))} */}
              <Title>
                <img alt="Logo Catimba Scores" src={logo} />{' '}
                {user.perfil < 4 ? (
                  <span>Administração</span>
                ) : (
                  <span>Aréa do sócio</span>
                )}
              </Title>
              {user.perfil <= 2 && (
                <>
                  <Dropdown
                    open={expanded || open ? undefined : false}
                    title="Administrador"
                    icon={<MdSettings />}
                    selected={adminPaths.includes(location.pathname)}
                  >
                    {user.perfil === 1 && (
                      <Link
                        exact
                        activeClassName="selected"
                        to="/meios-de-pagamento"
                        onClick={() => handleToggleSideBar(false)}
                      >
                        <MdAccountBalanceWallet />
                        <span>Meios de pagamento</span>
                      </Link>
                    )}
                    <Link
                      exact
                      activeClassName="selected"
                      to="/usuarios"
                      onClick={() => handleToggleSideBar(false)}
                    >
                      <MdPeople />
                      <span>Usuários</span>
                    </Link>
                    <Link
                      exact
                      activeClassName="selected"
                      to="/planos"
                      onClick={() => handleToggleSideBar(false)}
                    >
                      <MdViewAgenda />
                      <span>Planos</span>
                    </Link>

                    <Link
                      exact
                      activeClassName="selected"
                      to="/financeiro"
                      onClick={() => handleToggleSideBar(false)}
                    >
                      <MdAttachMoney />
                      <span>Financeiro</span>
                    </Link>

                    <Link
                      exact
                      activeClassName="selected"
                      to="/cupom"
                      onClick={() => handleToggleSideBar(false)}
                    >
                      <MdLocalAtm />
                      <span>Cupom</span>
                    </Link>

                    <Link
                      exact
                      activeClassName="selected"
                      to="/relatorio-cupom"
                      onClick={() => handleToggleSideBar(false)}
                    >
                      <MdMenu />
                      <span>Relatório Cupom</span>
                    </Link>
                  </Dropdown>
                </>
              )}

              <Dropdown
                open={expanded || open ? undefined : false}
                title="Minha Conta"
                icon={<MdAccountCircle />}
                selected={myAccountPaths.includes(location.pathname)}
              >
                <Link
                  exact
                  activeClassName="selected"
                  to="/meus-pagamentos"
                  onClick={() => handleToggleSideBar(false)}
                >
                  <MdCreditCard />
                  <span>Meus Pagamentos</span>
                </Link>
                <Link
                  exact
                  activeClassName="selected"
                  to="/alterar-cadastro"
                  onClick={() => handleToggleSideBar(false)}
                >
                  <MdModeEdit />
                  <span>Alterar cadastro</span>
                </Link>
                <Link
                  exact
                  activeClassName="selected"
                  to="/alterar-senha"
                  onClick={() => handleToggleSideBar(false)}
                >
                  <MdVpnKey />
                  <span>Alterar senha</span>
                </Link>
              </Dropdown>

              <Link
                exact
                activeClassName="selected"
                to="/dashboard"
                onClick={() => handleToggleSideBar(false)}
              >
                <MdDashboard />
                <span>Dashboard</span>
              </Link>
              <Link
                exact
                activeClassName="selected"
                to="/meus-times"
                onClick={() => handleToggleSideBar(false)}
              >
                <MdSecurity />
                <span>Desempenho</span>
              </Link>
              <Link
                exact
                activeClassName="selected"
                to="/atletas"
                onClick={() => handleToggleSideBar(false)}
              >
                <MdPeople />
                <span>Atletas</span>
              </Link>
              <Link
                exact
                activeClassName="selected"
                to="/jogos"
                onClick={() => handleToggleSideBar(false)}
              >
                <GiSoccerBall />
                <span>Jogos</span>
              </Link>

              <Link
                exact
                activeClassName="selected"
                to="/cruzamento-scouts"
                onClick={() => handleToggleSideBar(false)}
              >
                <MdShuffle />
                <span>Cruzamento scouts</span>
              </Link>
              <Link
                exact
                activeClassName="selected"
                to="/pontos-cedidos"
                onClick={() => handleToggleSideBar(false)}
              >
                <MdSwapHoriz />
                <span>Pontos Cedidos</span>
              </Link>
              <Link
                exact
                activeClassName="selected"
                to="/pontaria"
                onClick={() => handleToggleSideBar(false)}
              >
                <MdGpsFixed />
                <span>Pontaria</span>
              </Link>
              <Link
                exact
                activeClassName="selected"
                to="/gols-pros-e-sofridos"
                onClick={() => handleToggleSideBar(false)}
              >
                <GiSoccerBall />
                <span>Gols</span>
              </Link>
              <Link
                exact
                activeClassName="selected"
                to="/variacao"
                onClick={() => handleToggleSideBar(false)}
              >
                <MdAttachMoney />
                <span>Mín. valorização</span>
              </Link>
              <Link
                exact
                activeClassName="selected"
                to="/media-basica"
                onClick={() => handleToggleSideBar(false)}
              >
                <MdTimeline />
                <span>Média Básica</span>
              </Link>
              <Link
                exact
                activeClassName="selected"
                to="/ranking-clubes"
                onClick={() => handleToggleSideBar(false)}
              >
                <MdOpenInBrowser />
                <span>Ranking Clubes</span>
              </Link>
              <Link
                exact
                activeClassName="selected"
                to="/mais-escalados"
                onClick={() => handleToggleSideBar(false)}
              >
                <MdDoneAll />
                <span>Mais escalados</span>
              </Link>
              <Link
                exact
                activeClassName="selected"
                to="/noticias"
                onClick={() => handleToggleSideBar(false)}
              >
                <FaNewspaper />
                <span>Notícias</span>
              </Link>

              {/* <Dropdown title="Estatísticas" icon={<MdEqualizer />}>

            </Dropdown> */}
              <Separator />

              <Link exact to="/">
                <MdArrowBack />
                <span>Tela inicial</span>
              </Link>
              {/* <Link exact to="/" onClick={() => dispatch(signOut())}>
                <MdExitToApp />
                <span>Sair</span>
              </Link> */}
            </SidebarMenu>
          }
          open={sidebarOpen}
          docked={sidebarDocked}
          touchHandleWidth={0}
          onSetOpen={handleToggleSideBar}
          styles={{
            sidebar: {
              background: '#373640',
              zIndex: 3,
              overflowX: 'hidden',
              display: 'flex',
            },
          }}
          sidebarClassName="sidebar"
        >
          <Container
            onMouseEnter={() => setExpanded(false)}
            onMouseLeave={() => setExpanded(true)}
          >
            <MuiContainer maxWidth="lg">
              <HeaderContainer mobile={!sidebarDocked}>
                {!sidebarDocked && (
                  <Burguer onClick={handleToggleSideBar}>
                    <div />
                    <div />
                    <div />
                  </Burguer>
                )}
                {sidebarDocked && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      zIndex: 10,
                    }}
                  >
                    {history.location.pathname.split('/').length > 2 && (
                      <IconButton
                        aria-label="go-back"
                        style={{ marginRight: 15 }}
                        onClick={() => history.goBack()}
                      >
                        <ArrowBackIcon fontSize="large" />
                      </IconButton>
                    )}
                    <Typography variant="h5" component="h2">
                      {title}
                    </Typography>
                  </div>
                )}

                {mdUp ? (
                  <UserIcon onClick={() => dispatch(signOut())}>
                    <User>{user?.email}</User>
                  </UserIcon>
                ) : (
                  <User>{user?.email}</User>
                )}

              </HeaderContainer>
            </MuiContainer>

            <Wrapper>
              {!sidebarDocked && (
                <MuiContainer>
                  <MobileHeader>
                    {history.location.pathname.split('/').length > 2 && (
                      <IconButton
                        aria-label="go-back"
                        style={{ marginRight: 15 }}
                        onClick={() => history.goBack()}
                      >
                        <ArrowBackIcon fontSize="large" />
                      </IconButton>
                    )}
                    <Typography variant="h5" component="h2">
                      {title}
                    </Typography>
                  </MobileHeader>
                </MuiContainer>
              )}
              {children}
            </Wrapper>
          </Container>
        </Sidebar>
      </StylesWrapper>
    )
  );
}
