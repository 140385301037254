/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  MdEdit,
  MdRemoveRedEye,
  MdAttachMoney,
  MdLockOpen,
  MdVpnKey,
} from 'react-icons/md';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import WarnMessage from '../../components/WarnMessage';
import AdminActionButtons from '../../components/AdminActionButtons';
import AdminTableContainer from '../../components/AdminTableContainer';

import Form from './Form';
import ModalContainer from '../../components/ModalContainer';
import globalConst from '../../utils/globalConst';
import { Action } from '../../components/AdminActionButtons/styles';
import { getUsers, freePlan } from '../../services/catimbaStats/adminUsers';
import { put, post, erase } from '../../services/catimbaStats/admin';
import AdminTableLoading from '../../components/AdminTableLoading';
import { passwordRecover } from '../../services/catimbaStats/auth';

export default function AdminUsers() {
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [totalRegisters, setTotalRegisters] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState({
    show: false,
    option: '',
    user: false,
  });

  const history = useHistory();

  const handleGetUsers = useCallback(() => {
    setLoading(true);

    getUsers({ page, filtro: query })
      .then((res) => {
        setUsers(res.data.content);
        setTotalPages(res.data.totalPages);
        setTotalRegisters(res.data.totalElements);
      })
      .finally(() => setLoading(false));
  }, [page, query]);

  useEffect(() => {
    handleGetUsers();
  }, [handleGetUsers]);

  const handleClosePopup = useCallback(
    (update = false) => {
      setPopup({
        show: false,
        option: '',
        method: null,
      });

      if (update) {
        handleGetUsers();
      }
    },
    [handleGetUsers]
  );

  const handleUpdateUser = useCallback(
    (data) => {
      setLoading(true);

      put('/usuario', data, () => handleClosePopup(true)).finally(() =>
        setLoading(false)
      );
    },
    [handleClosePopup]
  );

  const handleCreateUser = useCallback(
    (data) => {
      setLoading(true);

      post('/usuario', data, () => handleClosePopup(true)).finally(() =>
        setLoading(false)
      );
    },
    [handleClosePopup]
  );

  const handleDeleteUser = useCallback(
    (id) => {
      setLoading(true);

      erase(`/usuario/${id}`, () => handleClosePopup(true)).finally(() =>
        setLoading(false)
      );
    },
    [handleClosePopup]
  );

  const handleFreePlan = useCallback(
    (id) => {
      setLoading(true);
      freePlan(id)
        .then((res) => {
          if (res.data) {
            toast.success(res.data.mensagem || 'Ação realizada com sucesso');
          } else {
            toast.success('Ação realizada com sucesso');
          }
          handleClosePopup(true);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.mensagem
          ) {
            toast.warn(err.response.data.mensagem);
            return;
          }
          toast.error('Ocorreu um erro');
        });
    },
    [handleClosePopup]
  );

  const handleResetPassword = useCallback(
    async (email) => {
      passwordRecover(email).then(() => {
        handleClosePopup();
        handleGetUsers();
      });
    },
    [handleClosePopup, handleGetUsers]
  );

  const getWarnText = useCallback((option) => {
    switch (option) {
      case 'warn':
        return 'excluir o usuário?';
      case 'freePlan':
        return 'liberar o plano para o usuário?';
      case 'resetPass':
        return 'resetar senha do usuário?';
      default:
    }
  }, []);

  const getWarnFn = useCallback(
    (option) => {
      switch (option) {
        case 'warn':
          return handleDeleteUser(popup.user.id);
        case 'freePlan':
          return handleFreePlan(popup.user.id);
        case 'resetPass':
          return handleResetPassword(popup.user.email);
        default:
          break;
      }
    },
    [handleDeleteUser, handleFreePlan, handleResetPassword, popup]
  );
  return (
    <>
      <ModalContainer
        open={popup.show}
        onClose={() => handleClosePopup()}
        position={
          popup.option === 'warn' ||
          popup.option === 'freePlan' ||
          (popup.option === 'resetPass' && '5px')
        }
      >
        {popup.option === 'warn' ||
        popup.option === 'freePlan' ||
        popup.option === 'resetPass' ? (
          <WarnMessage
            onCancel={() =>
              setPopup({
                show: false,
                option: '',
                user: null,
              })
            }
            onConfirm={() => getWarnFn(popup.option)}
          >
            Tem certeza que deseja {getWarnText(popup.option)}
          </WarnMessage>
        ) : (
          <Form
            user={popup.user}
            update={handleUpdateUser}
            create={handleCreateUser}
            readOnly={popup.option === 'view'}
          />
        )}
      </ModalContainer>
      <AdminTableContainer
        title="Usuários"
        addFunc={() =>
          setPopup({
            ...popup,
            show: true,
            option: 'add',
          })
        }
        setSearch={setQuery}
        totalPages={totalPages}
        currentPage={page}
        onPageChange={setPage}
        totalRegisters={totalRegisters}
      >
        <TableContainer>
          {!loading ? (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell size="small">ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Perfil</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell size="small">{user.id}</TableCell>
                    <TableCell component="th" scope="user">
                      {user.nome}
                    </TableCell>
                    <TableCell align="right">{user.email}</TableCell>
                    <TableCell align="right">
                      {user.status ? 'Habilitado' : 'Bloqueado'}
                    </TableCell>
                    <TableCell align="right">
                      {globalConst.perfil.map(
                        (option) => option.value === user.perfil && option.label
                      )}
                    </TableCell>
                    {/* <TableCell align="right">
                      {user.emailValidado === 1 ? 'Sim' : 'Não'}
                    </TableCell>
                    <TableCell align="right">
                      {user.resetPwSolicitado === 1 ? 'Sim' : 'Não'}
                    </TableCell>
                    <TableCell align="right">
                      {user.resetPwConfirmado === 1 ? 'Sim' : 'Não'}
                    </TableCell> */}
                    <TableCell align="right">
                      <Action>
                        <AdminActionButtons
                          size="small"
                          title="Liberar plano"
                          onClick={() =>
                            setPopup({
                              show: true,
                              option: 'freePlan',
                              user,
                            })
                          }
                        >
                          <MdLockOpen />
                        </AdminActionButtons>
                        <AdminActionButtons
                          size="small"
                          title="Pagamentos do usuário"
                          onClick={() =>
                            history.push(`usuarios/${user.id}/pagamentos`, {
                              user,
                            })
                          }
                        >
                          <MdAttachMoney />
                        </AdminActionButtons>
                        <AdminActionButtons
                          size="small"
                          title="Visualizar"
                          onClick={() =>
                            setPopup({
                              show: true,
                              option: 'view',
                              user,
                            })
                          }
                        >
                          <MdRemoveRedEye />
                        </AdminActionButtons>

                        <AdminActionButtons
                          size="small"
                          title="Editar"
                          onClick={() =>
                            setPopup({
                              show: true,
                              option: 'edit',
                              user,
                            })
                          }
                        >
                          <MdEdit />
                        </AdminActionButtons>
                        <AdminActionButtons
                          size="small"
                          title="Reset senha"
                          onClick={() =>
                            setPopup({
                              show: true,
                              option: 'resetPass',
                              user,
                            })
                          }
                        >
                          <MdVpnKey />
                        </AdminActionButtons>
                        {/* <AdminActionButtons
                          size="small"
                          title="Excluir"
                          onClick={() =>
                            setPopup({
                              show: true,
                              option: 'warn',
                            })
                          }
                        >
                          <MdDelete />
                        </AdminActionButtons> */}
                      </Action>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <AdminTableLoading />
          )}
        </TableContainer>
      </AdminTableContainer>
    </>
  );
}
