import styled from 'styled-components';
import { Grid, Container as MuiContainer } from '@material-ui/core';

export const Container = styled(MuiContainer)`
  padding: 10px;
`;

export const GridContainer = styled(Grid)`
  background: #fff;
  margin: 4px !important;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  max-width: calc(100vw - 20px);

  *.MuiGrid-spacing-xs-1 {
    margin: 0px !important;
  }
`;
