import styled from 'styled-components';

export const TitleContainer = styled.div`
  padding: 5px;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  ${({ center }) => center && 'text-align: center'}
`;
