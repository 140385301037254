import styled from 'styled-components';

export const TitleContainer = styled.div`
  padding: 5px;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const TableTitle = styled.div`
  display: flex;
  flex-direction: column;
  font {
    color: #979797;
  }
`;

export const Game = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GameRound = styled.span`
  font-size: 1.2rem;
  margin-bottom: 5px;
`;
