import React from 'react';
import Card from '../../Card';

import { Container, TeamName } from './styles';
import NumberInfo from '../../StatisticsComponents/NumberInfo';
import Name from '../../Name';

function GameInfo({ teamName, value, shield }) {
  return (
    <Card>
      <Container>
        <TeamName>
          <img src={shield} alt="code" />
          <Name first={teamName} />
        </TeamName>
        <NumberInfo title="Média Cedida" value={value} />
      </Container>
    </Card>
  );
}

export default GameInfo;
