import React, { useState, useEffect, useCallback } from 'react';
import ReactPixel from 'react-facebook-pixel';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, TextField, Grid } from '@material-ui/core';
import { formatPrice } from '../../utils/formatPrice';

import {
  Container,
  Title,
  Item,
  SubTitle,
  Row,
  PaymentOption,
  Option,
  Scroll,
  Warn,
  BigText,
  CouponContainer,
  TotalContainer,
} from './styles';
import PayMeeCheckout from './PayMeeCheckout';
import api from '../../services/catimbaStats/_api';
import ModalContainer from '../ModalContainer';

export default function Cart({ onClose, plan, open, setLink }) {
  const user = useSelector((state) => state.auth.profile);

  const [option, setOption] = useState();
  const [loading, setLoading] = useState(false);
  const [methods, setMethods] = useState([]);
  const [couponLoading, setCouponLoading] = useState(false);
  const [couponError, setCouponError] = useState('');
  const [couponInput, setCouponInput] = useState('');
  const [coupon, setCoupon] = useState();

  useEffect(() => {
    setLoading(true);
    api
      .get('/meioPagamento')
      .then((res) => {
        setMethods(res.data.content);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const validateCoupon = useCallback((value) => {
    setCouponLoading(true);
    setCoupon(undefined);
    api
      .get(`cupom/validar`, { params: { nome: value } })
      .then((res) => setCoupon(res.data))
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 404) {
            setCouponError('Cupom não encontrado!');
          }
          if (err.response.status === 406) {
            setCouponError('O cupom está expirado!');
          }
        }
      })
      .finally(() => setCouponLoading(false));
  }, []);

  function renderMercadoLivre(idUsuario, plano) {
    async function handleMercadoLivreCheckout() {
      setLoading(true);

      await api
        .post('/pagar/mercadoPago', {
          idUsuario,
          idPlano: plano.id,
          idCupom: coupon ? coupon.id : undefined,
        })
        .then((response) => {
          ReactPixel.fbq('track', 'Purchase', {
            currency: 'BRL',
            value: plano.valor,
          });
          setLink(response.data.urlRetorno);
          window.open(response.data.urlRetorno, '_blank');
          onClose();
        })
        .catch((err) => {
          toast.error(
            err.response.data.mensagem || 'Algo deu errado, tente novamente'
          );
        });
      setLoading(false);
    }

    return (
      <>
        <Warn>
          Seu acesso será liberado automaticamente após o processamento do
          pagamento. <br />
          Boleto bancário, o prazo é de até 2 dias úteis.
        </Warn>

        <Button
          color="primary"
          variant="contained"
          fullWidth
          disabled={!plano || loading}
          type="button"
          onClick={() => handleMercadoLivreCheckout()}
        >
          Ir para pagamento
        </Button>
      </>
    );
  }

  // TODO:isolar função em componente funcional
  function renderPicPay(idUsuario, plano) {
    async function handlePicPayCheckout() {
      setLoading(true);

      await api
        .post('/pagar/picPay', {
          idUsuario,
          idPlano: plano.id,
          idCupom: coupon ? coupon.id : undefined,
        })
        .then((response) => {
          ReactPixel.fbq('track', 'Purchase', {
            currency: 'BRL',
            value: plano.valor,
          });
          window.open(response.data.urlRetorno, '_blank');
          setLink(response.data.urlRetorno);
          onClose();
        })
        .catch((err) => {
          toast.error(
            err.response.data.mensagem || 'Algo deu errado, tente novamente'
          );
        })
        .finally(() => setLoading(false));
    }

    return (
      <>
        <Warn>
          Seu acesso será liberado automaticamente após o processamento do
          pagamento.
        </Warn>

        <Button
          color="primary"
          variant="contained"
          fullWidth
          disabled={!plano || loading}
          type="button"
          onClick={() => handlePicPayCheckout()}
        >
          Ir para pagamento
        </Button>
      </>
    );
  }

  return (
    <ModalContainer open={open} onClose={onClose} position="5px">
      <Container>
        <Title>Carrinho</Title>
        <Scroll>
          <Item>
            <SubTitle>Plano selecionado</SubTitle>
            <Row>{plan && plan.titulo}</Row>
            <CouponContainer success={!!coupon}>
              <Grid container>
                <Grid item xs={8}>
                  <TextField
                    onChange={(e) => {
                      setCouponError('');
                      setCouponInput(e.target.value.toUpperCase());
                    }}
                    value={couponInput}
                    placeholder="Cupom de desconto"
                    helperText={
                      coupon ? 'Cupom aplicado com sucesso' : couponError
                    }
                    error={!!couponError}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    onClick={() => validateCoupon(couponInput)}
                    disabled={couponLoading}
                  >
                    Aplicar
                  </Button>
                </Grid>
              </Grid>
            </CouponContainer>
            <Row className="none-bg">
              <BigText>TOTAL</BigText>
              <TotalContainer>
                <BigText previous={!!coupon}>
                  {plan && formatPrice(plan.valor)}
                </BigText>
                {coupon && (
                  <BigText>
                    {formatPrice(
                      plan.valor - (coupon.percentual / 100) * plan.valor
                    )}
                  </BigText>
                )}
              </TotalContainer>
            </Row>
          </Item>

          {/* <Item>
            <SubTitle>Meios de pagamento</SubTitle>

            <Row className="no-padding gray-bg grid">
              {methods.map(
                (way) =>
                  way.status === 1 && (
                    <PaymentOption
                      key={way.id}
                      selected={option === way.id}
                      onClick={() => setOption(way.id)}
                    >
                      <span>{way.nome}</span>
                    </PaymentOption>
                  )
              )}
            </Row>
          </Item> */}
          <Option>
            {/* {Number(option) === 1 && renderMercadoLivre(user.id, plan)}
            {Number(option) === 2 && (
              <PayMeeCheckout
                idUsuario={user.id}
                plan={plan}
                close={onClose}
                coupon={coupon}
              />
            )}
            {Number(option) === 3 && renderPicPay(user.id, plan)} */}
            {renderMercadoLivre(user.id, plan)}
          </Option>
        </Scroll>
      </Container>
    </ModalContainer>
  );
}
