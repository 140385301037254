import React from 'react';
import { Typography, Paper } from '@material-ui/core';

import { Container } from './styles';

function NextGameRow({ shield, position, host }) {
  return (
    <Paper elevation={0}>
      <Container>
        <Typography variant="h5">Próximo adversário</Typography>
        <img alt="Escudo próximo adversário" src={shield} />
        <Typography variant="subtitle1">
          Pontuações cedidas para{' '}
          {position === 'lateral' ? 'laterais' : `${position.toLowerCase()}s`}{' '}
          que jogaram {host}, nas últimas 5 rodadas
        </Typography>
      </Container>
    </Paper>
  );
}

export default NextGameRow;
