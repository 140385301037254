import styled from 'styled-components';
import { Container as MuiContainer } from '@material-ui/core';
import MuiTheme from '../../styles/MuiTheme';

export const Container = styled(MuiContainer)`
  padding: 10px;

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.9rem;

    color: #000000;
  }

  div.MuiGrid-container {
    flex-direction: row;
  }

  /* div {
    justify-content: center;
    align-items: center;
  } */
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 30px 10px;

  width: 100%;

  h2 {
    /* font-size: 1.6rem; */

    color: #fff;
    /* ${MuiTheme.breakpoints.up('md')} { */
      color: #000;
      font-size: 2.3rem;
    /* } */
  }
`;
