import styled from 'styled-components';

export const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;
  white-space: nowrap;

  > div {
    justify-content: flex-start;
  }
`;
