import React from 'react';

import Card from '../Card';
import InfoPlan from './InfoPlan';

import {
  Container,
  Title,
  CardContainer,
  PlanButton,
  ButtonContainer,
} from './styles';

function PlanAndPrice({ title, price, color, spotligth, data = [], onClick }) {
  return (
    <Card style={{ padding: 0 }} elevation={spotligth ? 10 : undefined}>
      <CardContainer>
        <Container color={color}>
          {/* <ImgContainer
            spotligth={spotligth}
            src={img}
            alt="Imagem dos Planos e Preços"
          /> */}
          <Title color="#fff">{title}</Title>
        </Container>
        <Container>
          <Title color="#000" spotligth={spotligth}>
            {price}
          </Title>
          {data.map((info) => (
            <InfoPlan icon="check">{info}</InfoPlan>
          ))}
          <ButtonContainer>
            <PlanButton
              variant={spotligth ? 'contained' : 'outlined'}
              color={!spotligth ? 'primary' : 'secondary'}
              onClick={onClick}
            >
              Selecionar Plano
            </PlanButton>
          </ButtonContainer>
        </Container>
      </CardContainer>
    </Card>
  );
}

export default PlanAndPrice;
