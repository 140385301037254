import { format } from 'date-fns';

export function formatShortDate(date) {
  const formatted = new Date(date);
  return format(formatted, 'dd/MM/yyyy');
}

export function formatShortDateAndTime(date) {
  if (date && date !== undefined) {
    const formatted = new Date(date);
    return format(formatted, 'dd/MM/yyyy HH:mm');
  }
  return; //eslint-disable-line
}
