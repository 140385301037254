/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { MdPerson } from 'react-icons/md';

import history from '../../services/history';
import { Container, Badge, Options, Option, Wrapper } from './styles';

function useOutsideAlerter(ref, hide) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      hide(false);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

export default function UserIcon({ onClick, children }) {
  const [visible, setVisible] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setVisible);

  function handleToggleVisible() {
    setVisible(!visible);
  }

  function navigate(url) {
    setVisible(!visible);
    history.push(url);
  }

  return (
    <Container ref={wrapperRef}>
      {(children && (
        <Wrapper onClick={handleToggleVisible}>{children}</Wrapper>
      )) || (
        <Badge onClick={handleToggleVisible}>
          <MdPerson size={30} color="#000" /> <span>Conta</span>
        </Badge>
      )}

      <Options visible={visible}>
        <Option onClick={() => navigate('/meus-pagamentos')}>
          Meus Pagamentos
        </Option>
        <Option onClick={() => navigate('/alterar-cadastro')}>
          Alterar Dados
        </Option>
        <Option onClick={() => navigate('/alterar-senha')}>
          Alterar Senha
        </Option>
        <Option onClick={onClick}>Sair</Option>
      </Options>
    </Container>
  );
}
