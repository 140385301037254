import styled from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';

import respond from '../../styles/respond';

export const Container = styled.div``;

export const Title = styled.div`
  padding: 1rem 1.6rem;
  display: flex;
  justify-content: space-between;

  ${respond.tabPort} {
    flex-direction: column;
    align-items: center;
  }
`;

export const SearchContainer = styled.div`
  margin-bottom: 2rem;
  max-width: 40rem;
  display: flex;
  align-items: center;

  > div {
    margin-right: 1rem;
  }
  svg {
    font-size: 2.5rem;
  }
`;

export const PagintationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
`;

export const Action = styled.div`
  font-size: 2.1rem;
  /* display: flex;
  align-items: flex-end; */

  svg {
    cursor: pointer;

    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const EditContainer = styled.div`
  width: 40rem;
  ${respond.tabPort} {
    width: 95vw;
  }
  form {
    display: flex;
    flex-direction: column;
  }
`;

export const InputGroup = styled.div`
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: column;

  margin-bottom: 2rem;
`;

export const Scroll = styled(PerfectScrollBar)`
  max-height: 70vh;
  padding: 5px 10px;
`;
