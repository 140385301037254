import React from 'react';

import { Pill } from './styles';

function ScoutPill({ children, negative = false, color, width, ...props }) {
  return (
    <Pill
      color={color}
      width={width}
      negative={negative}
      elevation={1}
      {...props}
    >
      <span>{children}</span>
    </Pill>
  );
}

export default ScoutPill;
