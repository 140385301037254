import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export const Item = styled.div``;
export const Label = styled.span`
  font-size: 1.4rem;
  font-weight: bold;
`;
export const Value = styled.span`
  font-size: 1.2rem;
  margin-left: 5px;
`;

export const Number = styled.span`
  font-size: 1.6rem;
  font-weight: 600;
`;