import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TooltipContainer = styled.div``;

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5rem 1.5rem 1.5rem 1.5rem;
  grid-gap: 1.5rem;
  align-items: center;
  justify-content: center;
`;
