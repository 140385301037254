import React, { useState, useEffect } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { MdTouchApp } from 'react-icons/md';

import { DropdownTop, DropdownContainer } from './styles';

function Dropdown({ onClick, title, open, children, icon, selected }) {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setToggle(open === undefined ? false : open);
  }, [open]);

  return (
    <div>
      <DropdownTop
        className={selected ? 'selected' : undefined}
        onClick={onClick || (() => setToggle(!toggle))}
      >
        {icon || <MdTouchApp />}
        <span>{title}</span>
        {toggle ? (
          <ArrowDropUpIcon className="arrow" />
        ) : (
          <ArrowDropDownIcon className="arrow" />
        )}
      </DropdownTop>
      {toggle && <DropdownContainer>{children}</DropdownContainer>}
    </div>
  );
}

export default Dropdown;
