import React, { useCallback } from 'react';
import { Form as FormikForm, Field } from 'formik';
import {
  TextField,
  Button,
  InputAdornment,
  Switch,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormControl,
} from '@material-ui/core';
import { format } from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';
import AdminForm from '../../../components/AdminForm';

// import { Container } from './styles';

function Form({ readOnly, plan = null, create, update, loading }) {
  const handleSubmit = useCallback(
    async (values) => {
      if (!plan) {
        await create({
          ...values,
          vigencia:
            typeof values.vigencia === 'string'
              ? values.vigencia.split('/').reverse().join('-')
              : format(values.vigencia, 'dd/MM/yyyy')
                  .split('/')
                  .reverse()
                  .join('-'),
        });
        return;
      }
      await update({
        ...plan,
        ...values,
        vigencia:
          typeof values.vigencia === 'string'
            ? values.vigencia.split('/').reverse().join('-')
            : format(values.vigencia, 'dd/MM/yyyy')
                .split('/')
                .reverse()
                .join('-'),
      });
    },
    [create, plan, update]
  );

  return (
    <AdminForm
      initialValues={{
        titulo: plan ? plan.titulo : '',
        descricao: plan ? plan.descricao : '',
        valor: plan ? plan.valor : '',
        status: plan ? plan.status : true,
        destaque: plan ? plan.destaque : false,
        vigencia: plan
          ? new Date(`${plan.vigencia} `)
          : format(new Date(), 'dd/MM/yyyy'),
      }}
      handleSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <FormikForm>
          <Field name="titulo">
            {({ field }) => (
              <TextField
                {...field}
                disabled={readOnly || loading}
                margin="normal"
                required
                label="Título"
                id="titulo"
                autoFocus
              />
            )}
          </Field>
          <Field name="descricao">
            {({ field }) => (
              <TextField
                {...field}
                disabled={readOnly || loading}
                margin="normal"
                required
                label="Descrição"
                id="descricao"
                autoFocus
                multiline
              />
            )}
          </Field>
          <Field name="valor">
            {({ field }) => (
              <TextField
                {...field}
                disabled={readOnly || loading}
                margin="normal"
                required
                label="Valor"
                id="valor"
                autoFocus
                type="number"
                startAdornment={
                  <InputAdornment position="start">R$</InputAdornment>
                }
              />
            )}
          </Field>
          <KeyboardDatePicker
            autoOk
            disabled={readOnly || loading}
            name="vigencia"
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Vigência"
            id="vigencia"
            KeyboardButtonProps={{
              'aria-label': 'Selecionar uma data',
            }}
            minDate={new Date()}
            value={values.vigencia}
            onChange={(date, _value) => setFieldValue('vigencia', date)}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">Destaque</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Field
                    name="destaque"
                    disabled={readOnly || loading}
                    color="primary"
                    component={Switch}
                    checked={Boolean(values.destaque)}
                    onChange={() => setFieldValue('destaque', !values.destaque)}
                  />
                }
                label={values.destaque ? 'Destaque' : 'Normal'}
              />
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Status</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Field
                    name="status"
                    disabled={readOnly || loading}
                    color="primary"
                    component={Switch}
                    checked={Boolean(values.status)}
                    onChange={() => setFieldValue('status', !values.status)}
                  />
                }
                label={values.status ? 'Habilitado' : 'Desabilitado'}
              />
            </FormGroup>
          </FormControl>
          {!readOnly && (
            <Button type="submit" variant="contained" color="primary">
              Salvar
            </Button>
          )}
        </FormikForm>
      )}
    </AdminForm>
  );
}

export default Form;
