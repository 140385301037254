import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  padding-top: 7.4rem;
  /* padding-bottom: 15.2rem; */
  position: relative;

  > div {
    min-height: 100%;
  }
  @media only screen and (max-width: 1023px) {
    padding-top: 5rem;
  }
`;
