import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';

import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { TableTitle, Game, GameRound, TitleContainer } from './styles';
import SmallComparison from '../SmallComparison';

export default function LastGamesTable({
  gamesHost,
  gamesVis,
  hostName,
  visName,
}) {
  function renderGames() {
    const length =
      gamesHost.length > gamesVis.length ? gamesHost.length : gamesVis.length;
    let i = 0;
    const elementsToRender = [];
    while (i < length) {
      elementsToRender.push(
        <TableRow
          key={
            (gamesHost[i] && gamesHost[i].rodada) ||
            (gamesVis[i] && gamesVis[i].rodada)
          }
        >
          <TableCell align="left">
            {gamesHost[i] && (
              <Game>
                <GameRound>Rodada #{gamesHost[i].rodada}</GameRound>
                <SmallComparison
                  hostShield={gamesHost[i].fotoClubeCasa}
                  hostScore={gamesHost[i].placarCasa}
                  visShield={gamesHost[i].fotoClubeVisitante}
                  visScore={gamesHost[i].placarVisitante}
                />
              </Game>
            )}
          </TableCell>
          <TableCell align="right">
            {gamesVis[i] && (
              <Game>
                <GameRound>Rodada #{gamesVis[i].rodada}</GameRound>
                <SmallComparison
                  hostShield={gamesVis[i].fotoClubeCasa}
                  hostScore={gamesVis[i].placarCasa}
                  visShield={gamesVis[i].fotoClubeVisitante}
                  visScore={gamesVis[i].placarVisitante}
                />
              </Game>
            )}
          </TableCell>
        </TableRow>
      );
      i += 1;
    }

    return elementsToRender;
  }

  return (
    <TableContainer component={Paper}>
      <TitleContainer>
        <Typography variant="h5">Últimos jogos</Typography>
      </TitleContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <TableTitle>
                <span>{hostName}</span>
                <font>Mandante</font>
              </TableTitle>
            </TableCell>
            <TableCell align="right">
              <TableTitle>
                <span>{visName}</span>
                <font>Visitante</font>
              </TableTitle>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderGames()}</TableBody>
      </Table>
    </TableContainer>
  );
}
