import styled, { css } from 'styled-components';
import { Checkbox } from '@material-ui/core';
import muiTheme from '../../../styles/MuiTheme';

export const Container = styled.div`
  cursor: pointer;

  *.MuiButtonBase-root {
    position: absolute;
    top: -15px;
    left: -15px;
  }
`;

export const PlayerContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div.scouts {
    flex: 1;

    ${({ comparable }) =>
      comparable &&
      css`
        > div {
          &:first-child {
            padding-left: 25px;
          }
        }
      `}
  }

  ${({ card }) =>
    card &&
    css`
      ${muiTheme.breakpoints.up('sm')} {
        align-items: unset;
        height: 220px;
      }
    `}
`;

export const ShortStatistics = styled.div`
  display: flex;
  flex-direction: column;

  div.statistics {
    display: grid;
    ${({ short }) =>
      short
        ? css`
            grid-template-columns: 1fr;
          `
        : css`
            grid-template-columns: 1fr 1fr;
          `}
    /* grid-template-rows: 1fr 1fr; */
    grid-gap: 5px;

    *.next-game {
      grid-column: 1/3;
    }

    *.fullGrid {
      grid-area: 1 / 1 / 3 / 3;
      ${muiTheme.breakpoints.up('md')} {
        grid-area: 1 / 2 / 3 / 5;
      }
    }

    & .customStat {
      grid-column: 10;
      flex-wrap: nowrap;
      justify-self: flex-end;
    }
    ${({ card }) =>
      !card &&
      css`
        ${muiTheme.breakpoints.up('md')} {
          grid-template-columns: repeat(6, 1fr);
          max-height: 40px;
          grid-template-rows: unset;

          *.next-game {
            grid-column: unset;
          }

          div.scouts {
            grid-column: 1/6;
          }
        }
      `}
  }
`;

export const ScoutsContainer = styled.div`
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 39px);
  grid-gap: 3px;
`;

export const ComparableCheckBox = styled(Checkbox)`
  position: absolute;
`;
