import React from 'react';
import { FaInstagram, FaFacebook, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Container,
  FooterMain,
  Logo,
  Column,
  FooterBottom,
  Copyright,
  SocialIcons,
  Wrapper,
  Button,
} from './styles';
import logo from '../../assets/logo-stats.png';
import AdBanner from '../AdBanner';

export default function Footer() {
  return (
    <Wrapper>
      <Container>
        <FooterMain>
          <Logo>
            <img
              alt="Catimba Footer Logo"
              src={logo}
              style={{ height: '113px' }}
            />
            <SocialIcons>
              <a target="blank" href="https://instagram.com/catimba_stats">
                <FaInstagram />
              </a>

              <a
                target="blank"
                href="https://www.facebook.com/Catimba-Stats-2276730959231783"
              >
                <FaFacebook />
              </a>
              <a
                target="blank"
                href="https://www.youtube.com/channel/UCmnXnDXL7qAw9YL8wF78bog"
              >
                <FaYoutube />
              </a>
            </SocialIcons>
          </Logo>
          <Column>
            <h3>Serviços Catimba</h3>
            <ul>
              <li>
                <a href="https://catimbascores.com" target="blank">
                  Catimba Scores
                </a>
              </li>
            </ul>
          </Column>
          <Column>
            <h3>Contato</h3>
            <ul>
              <li>
                <a href="mailto:contato@catimbascores.com">
                  contato@catimbascores.com
                </a>
              </li>
              <li>
                <Button
                  href="https://catimbascores.zendesk.com/hc/pt-br"
                  target="blank"
                >
                  Central de Ajuda
                </Button>
              </li>
            </ul>
          </Column>
          <Column>
            <h3>Termos de uso</h3>
            <ul>
              <li>
                <Link to="/politica-de-privacidade">
                  Política de privacidade
                </Link>
              </li>
              <li>
                <Link to="/termos-e-condicoes">Termos e condições de uso</Link>
              </li>
            </ul>
          </Column>
          {/* <Column>
            <h3>Parceiros</h3>
            <ul>
              <li>
                <a href="https://gurudocartola.com" target="blank">
                  Guru do Cartola
                </a>
              </li>
            </ul>
          </Column> */}
        </FooterMain>
      </Container>
      <AdBanner />
      {/* <div style={{ height: 30, overflow: 'hidden' }}>
        <AdSense.Google
          client="ca-pub-9222482921461579"
          slot="f08c47fec0942fa0"
          style={{ height: 30 }}
        />
      </div> */}
      <FooterBottom>
        <Copyright>
          <span>© 2020, Catimba Stats | Todos os direitos Reservados </span>
        </Copyright>
      </FooterBottom>
    </Wrapper>
  );
}
