import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
`;

export const ImgInfo = styled.img`
  opacity: 1;
  width: 65px;
  height: 65px;
  margin-right: 15px;
  margin-left: 10px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleInfo = styled.h1`
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 5px;
`;

export const TextInfo = styled.h2`
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-weight: normal;
`;
