import styled, { css } from 'styled-components';
import MuiTheme from '../../../styles/MuiTheme';

export const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  span {
    font-size: 14px;
  }
`;

export const ImageContainer = styled.div`
  img {
    height: 2.5rem;

    ${MuiTheme.breakpoints.up('sm')} {
      height: 3rem;
    }
  }
`;

export const TableRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
`;

export const InnerTableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 16px;
  align-items: center;
`;

export const Bar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  text-align: right;
  font-size: 1.2rem;
  padding: 2px 5px;
  width: 68%;
  background: red;
  justify-content: flex-end;
  align-self: flex-end;
  margin-left: auto;
  margin-right: 5px;
  border-radius: 5px;
`;

export const HSBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ reverse }) =>
    !reverse
      ? css`
          margin-right: 5px;
          flex-direction: row-reverse;
        `
      : css`
          margin-left: 5px;
        `}

  > div {
    margin-bottom: 2px;
  }

  span {
    font-size: 1.4rem;
    font-weight: bold;

    ${MuiTheme.breakpoints.up('sm')} {
      font-size: 1.8rem;
    }
  }
  div#hsbarAim_text {
    display: none !important;
  }
  svg {
    height: 1rem;

    ${({ reverse }) =>
      !reverse
        ? css`
            border-radius: 20px 0 0px 20px;
            margin-right: 4px;
          `
        : css`
            border-radius: 0px 20px 20px 0px;
            margin-left: 4px;
          `}
  }
`;
