import styled from 'styled-components';
import MuiTheme from '../../../styles/MuiTheme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 65;
    margin-top: 10px;
  }
  h6 {
    margin-top: 10px;
    text-align: center;

    /* ${MuiTheme.breakpoints.up('md')}{
      text-align
    } */

  }
`;
