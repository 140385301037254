import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
`;

export const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  > div {
    &:first-child {
      align-items: flex-start;
      text-align: left;
      > div {
        align-items: flex-start;
      }
    }

    &:last-child {
      align-items: flex-end;
      text-align: right;
      > div {
        align-items: flex-end;
      }
    }
  }
`;
