import styled from 'styled-components';

export const GameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;

  img {
    height: 16px;
  }
`;
