import React from 'react';

import { Typography } from '@material-ui/core';
import { Container, SpotlightPlayers } from './styles';
import Card from '../../Card';
import HighlightPlayer from '../HighlightPlayer';

function BestPlayers({
  title,
  descriptionP1,
  descriptionP2,
  pictureP1,
  playerNameP1,
  titleP1,
  valueP1,
  positionP1,
  pictureP2,
  playerNameP2,
  titleP2,
  valueP2,
  positionP2,
}) {
  return (
    <Card>
      <Container>
        <Typography variant="h5">{title}</Typography>
        <SpotlightPlayers>
          <HighlightPlayer
            description={descriptionP1}
            picture={pictureP1}
            playerName={playerNameP1}
            title={titleP1}
            value={valueP1}
            position={positionP1}
          />
          <HighlightPlayer
            reverse
            description={descriptionP2}
            picture={pictureP2}
            playerName={playerNameP2}
            title={titleP2}
            value={valueP2}
            position={positionP2}
          />
        </SpotlightPlayers>
      </Container>
    </Card>
  );
}

export default BestPlayers;
