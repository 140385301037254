/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import ReactPixel from 'react-facebook-pixel';

import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Formik, Field, Form } from 'formik';
import { TextField, Button } from '@material-ui/core';
import ReactInputMask from 'react-input-mask';
import BB from '../../../assets/BB.png';
import Bradesco from '../../../assets/Bradesco.png';
import BS2 from '../../../assets/BS2.png';
import Caixa from '../../../assets/Caixa.png';
import Inter from '../../../assets/Inter.png';
import Itau from '../../../assets/Itau.png';
import Original from '../../../assets/Original.png';
import Santander from '../../../assets/Santander.png';

import {
  Banks,
  Bank,
  Overlay,
  OverlayContainer,
  CheckoutInfo,
  Scroll,
} from './styles';
import { Warn, SubTitle } from '../styles';
import api from '../../../services/catimbaStats/_api';
import ModalContainer from '../../ModalContainer';

const schema = Yup.object().shape({
  agencia: Yup.string()
    .min(4, 'Agência contém 4 dígitos')
    .max(4, 'Agência contém 4 dígitos')
    .required('Agência não pode estar vazia'),
  conta: Yup.string().required('Conta não pode estar vazia'),
});

export default function PayMeeCheckout({ idUsuario, plan, close, coupon }) {
  const [selectedBank, setSelectedBank] = useState();
  const [instructions, setInstructions] = useState();
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [banks, setBanks] = useState([
    { name: 'BB_TRANSFER', img: BB },
    { name: 'BRADESCO_TRANSFER', img: Bradesco },
    { name: 'ITAU_TRANSFER_GENERIC', img: Itau },
    { name: 'CEF_TRANSFER', img: Caixa },
    { name: 'ORIGINAL_TRANSFER', img: Original },
    { name: 'SANTANDER_TRANSFER', img: Santander },
    { name: 'INTER_TRANSFER', img: Inter },
    { name: 'BS2_TRANSFER', img: BS2 },
  ]);
  function insert(str, index, value) {
    return str.substr(0, index) + value + str.substr(index);
  }
  // function handleTogglePopup() {
  //   if (showPopup) {
  //     history.push('/');
  //     close();
  //   }
  //   setShowPopup(!showPopup);
  // }

  async function handlePayMeeCheckout(data) {
    const { agencia, conta } = data;

    setLoading(true);
    await api
      .post('/pagar/payMee', {
        idUsuario,
        idPlano: plan.id,
        idCupom: coupon ? coupon.id : undefined,
        banco: selectedBank,
        agencia,
        conta,
      })
      .then((response) => {
        ReactPixel.fbq('track', 'Purchase', {
          currency: 'BRL',
          value: plan.valor,
        });
        setInstructions(response.data);
        setShowPopup(true);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          toast.error(
            err.response.data.mensagem || 'Algo deu errado, tente novamente'
          );
          return;
        }
        toast.error('Algo deu errado, tente novamente');
      });
    setLoading(false);
  }

  function handleBankAccountChange(e, setFieldValue) {
    if (e.target.value.length === 0) {
      setFieldValue('');
      return;
    }

    const newValue = e.target.value.replace(/\D/g, '').replace('-', '');
    setFieldValue('conta', insert(newValue, newValue.length - 1, '-'));
  }

  return (
    <>
      <ModalContainer
        open={instructions && showPopup}
        onClose={() => {
          setShowPopup(false);
          close();
        }}
        position="5px"
      >
        <CheckoutInfo>
          <h3>Siga as Instruções para finalizar o pagamento:</h3>
          <Scroll maxWidth="70vw">
            <ul>
              {instructions &&
                instructions.response.instructions.steps.map((phrase) => (
                  <li>{phrase}</li>
                ))}
            </ul>
            <button
              type="button"
              onClick={() => {
                close();
              }}
            >
              OK
            </button>
          </Scroll>
        </CheckoutInfo>
      </ModalContainer>
      <Warn>
        Seu acesso será liberado automaticamente após o processamento do
        pagamento. <br />
        <br />
        Utilize apenas contas em que o usuário é o titular!
      </Warn>

      <SubTitle>Selecione o banco de sua preferência</SubTitle>
      <Banks>
        {banks.map((bank, index) => {
          if (banks.length / 2 < index + 1) {
            return (
              <OverlayContainer
                key={bank.name}
                onClick={() => setSelectedBank(bank.name)}
              >
                <Bank src={bank.img} />
                <Overlay selected={bank.name === selectedBank} />
              </OverlayContainer>
            );
          }
        })}
      </Banks>
      <Banks>
        {banks.map((bank, index) => {
          if (banks.length / 2 >= index + 1) {
            return (
              <OverlayContainer
                key={bank.name}
                onClick={() => setSelectedBank(bank.name)}
              >
                <Bank src={bank.img} />
                <Overlay selected={bank.name === selectedBank} />
              </OverlayContainer>
            );
          }
        })}
      </Banks>

      <Formik
        schema={schema}
        initialValues={{
          agencia: '',
          conta: '',
        }}
        onSubmit={handlePayMeeCheckout}
      >
        {({ setFieldValue, values }) => (
          <Form>
            {selectedBank ? (
              <>
                <SubTitle>Informe sua agência e conta</SubTitle>
                <Field
                  name="agencia"
                  render={({ field }) => (
                    <ReactInputMask {...field} mask="9999">
                      <TextField
                        variant="outlined"
                        required
                        id="agencia"
                        type="string"
                        placeholder="Agência"
                      />
                    </ReactInputMask>
                  )}
                />
                <Field
                  name="conta"
                  onChange={(e) => {
                    handleBankAccountChange(e, setFieldValue);
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      required
                      id="conta"
                      type="string"
                      placeholder="Conta"
                      style={{
                        marginTop: 10,
                      }}
                    />
                  )}
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={
                    !plan || loading || !values.agencia || !values.conta
                  }
                  type="submit"
                  style={{
                    marginTop: 15,
                  }}
                  // onClick={handlePayMeeCheckout}
                >
                  Instruções de pagamento
                </Button>
              </>
            ) : null}
          </Form>
        )}
      </Formik>
    </>
  );
}
