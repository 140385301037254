import React, { useCallback, useState } from 'react';
import { Form as FormikForm, Field } from 'formik';
import {
  TextField,
  Button,
  Switch,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormControl,
  Grid,
  MenuItem,
} from '@material-ui/core';
import * as Yup from 'yup';
import validarCpf from 'validar-cpf';
import AdminForm from '../../../components/AdminForm';
import globalConst from '../../../utils/globalConst';

// import { Container } from './styles';

const schema = Yup.object().shape({
  nome: Yup.string().required('Nome não pode estar vazio'),
  cpf: Yup.string().required('CPF não pode estar vazio'),
  celular: Yup.string().required('Telefone não pode estar vazio'),
  cep: Yup.string().required('CEP não pode estar vazio'),
  uf: Yup.string().required('Estado não pode estar vazio'),
  cidade: Yup.string().required('Cidade não pode estar vazio'),
  bairro: Yup.string().required('Bairro não pode estar vazio'),
  rua: Yup.string().required('Rua não pode estar vazio'),
  numero: Yup.string().required('Número não pode estar vazio'),
  complemento: Yup.string(),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('E-mail não pode estar vazio'),
  novaSenha: Yup.string().min(6, 'No mínimo 6 digitos'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('novaSenha'), undefined],
    'As senhas devem ser iguais'
  ),
  perfil: Yup.number().required('Informe um perfil'),
});
function Form({ readOnly, user = null, create, update, loading }) {
  const [cpfError, setCpfError] = useState('');

  const handleSubmit = useCallback(
    async (values) => {
      if (!user) {
        await create({
          ...values,
          status: Number(values.status),
          cpf: values.cpf.replace(/[^0-9]/g, ''),
          celular: values.celular.replace(/[^0-9]/g, ''),
          cep: values.cep.replace(/[^0-9]/g, ''),
        });
        return;
      }
      await update({
        ...user,
        ...values,
        status: Number(values.status),
        cpf: values.cpf.replace(/[^0-9]/g, ''),
        celular: values.celular.replace(/[^0-9]/g, ''),
        cep: values.cep.replace(/[^0-9]/g, ''),
        senha: values.novaSenha ? values.novaSenha : null,
        origem: 1,
        tipoAlteracao: 0,
      });
    },
    [create, user, update]
  );

  return (
    <AdminForm
      initialValues={{
        nome: user ? user.nome : '',
        email: user ? user.email : '',
        cpf: user ? user.cpf : '',
        celular: user ? user.celular : '',
        perfil: user ? user.perfil : 4,
        status: user ? user.status : true,
        cep: user ? user.cep : '',
        rua: user ? user.rua : '',
        numero: user ? user.numero : '',
        complemento: user ? user.complemento : '',
        bairro: user ? user.bairro : '',
        cidade: user ? user.cidade : '',
        uf: user ? user.uf : '',
        novaSenha: '',
        passConfirm: '',
        emailValidado: user ? user.emailValidado : 0,
        resetPwSolicitado: user ? user.resetPwSolicitado : 0,
        resetPwConfirmado: user ? user.resetPwConfirmado : 0,
      }}
      handleSubmit={handleSubmit}
      schema={schema}
    >
      {({ values, touched, errors, handleChange, setFieldValue }) => (
        <FormikForm>
          <FormControl component="fieldset">
            <FormLabel component="legend">Email Confirmado</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    disabled
                    checked={values.emailValidado === 1}
                    value={values.emailValidado === 1}
                    color="primary"
                  />
                }
                label={values.emailValidado === 1 ? 'Sim' : 'Não'}
              />
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Reset Solicitado</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    disabled
                    checked={values.resetPwSolicitado === 1}
                    value={values.resetPwSolicitado === 1}
                    color="primary"
                  />
                }
                label={values.resetPwSolicitado === 1 ? 'Sim' : 'Não'}
              />
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Reset Confirmado</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    disabled
                    checked={values.resetPwConfirmado === 1}
                    value={values.resetPwConfirmado === 1}
                    color="primary"
                  />
                }
                label={values.resetPwConfirmado === 1 ? 'Sim' : 'Não'}
              />
            </FormGroup>
          </FormControl>

          <FormControl component="fieldset">
            <FormLabel component="legend">Status</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Field
                    disabled={readOnly || loading}
                    checked={Boolean(values.status)}
                    color="primary"
                    name="status"
                    onChange={() => setFieldValue('status', !values.status)}
                    as={Switch}
                  />
                }
                label={values.status ? 'Habilitado' : 'Bloqueado'}
              />
            </FormGroup>
          </FormControl>
          <TextField
            disabled={readOnly || loading}
            margin="normal"
            select
            required
            id="perfil"
            label="Perfil"
            name="perfil"
            onChange={handleChange}
            value={values.perfil}
          >
            {globalConst.perfil.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name="nome"
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    disabled={readOnly || loading}
                    fullWidth
                    id="nome"
                    label="Nome"
                    autoFocus
                    error={errors.firstName && touched.firstName}
                    helperText={touched.firstName && errors.firstName}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    disabled={readOnly || loading}
                    fullWidth
                    id="email"
                    label="Email"
                    autoComplete="email"
                    error={touched.email && errors.email}
                    helperText={touched.email && errors.email}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="cpf"
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="cpf"
                    label="CPF"
                    disabled={readOnly || loading}
                    autoComplete="lname"
                    error={
                      (errors.cpf && touched.cpf) || (touched.cpf && cpfError)
                    }
                    helperText={
                      (touched.cpf && errors.cpf) || (touched.cpf && cpfError)
                    }
                    // InputProps={{
                    //   inputComponent: TextMaskCustom,
                    //   inputProps: { mask: '999.999.999-99' },
                    // }}
                    onChange={(event) => {
                      handleChange(event);
                      setCpfError('');
                      if (
                        event.target.value.replace(/[^0-9]/g, '').length ===
                          11 &&
                        !validarCpf(event.target.value.replace(/[^0-9]/g, ''))
                      ) {
                        setCpfError('CPF está inválido');
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="celular"
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="celular"
                    label="Celular"
                    disabled={readOnly || loading}
                    error={touched.celular && errors.celular}
                    helperText={touched.celular && errors.celular}
                    // InputProps={{
                    //   inputComponent: TextMaskCustom,
                    //   inputProps: { mask: '(99) 99999-9999' },
                    // }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="cep"
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="cep"
                    label="CEP"
                    disabled={readOnly || loading}
                    // InputProps={{
                    //   inputComponent: TextMaskCustom,
                    //   inputProps: { mask: '99999-999' },
                    // }}
                    error={touched.cep && errors.cep}
                    helperText={touched.cep && errors.cep}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="uf"
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="uf"
                    label="Estado"
                    autoComplete="lname"
                    disabled={readOnly || loading}
                    error={touched.uf && errors.uf}
                    helperText={touched.uf && errors.uf}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                name="cidade"
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="cidade"
                    label="Cidade"
                    autoComplete="lname"
                    disabled={readOnly || loading}
                    error={touched.cidade && errors.cidade}
                    helperText={touched.cidade && errors.cidade}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="bairro"
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="bairro"
                    label="Bairro"
                    autoComplete="lname"
                    disabled={readOnly || loading}
                    error={touched.bairro && errors.bairro}
                    helperText={touched.bairro && errors.bairro}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="rua"
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="rua"
                    label="Rua"
                    disabled={readOnly || loading}
                    error={touched.rua && errors.rua}
                    helperText={touched.rua && errors.rua}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="numero"
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="numero"
                    label="Número"
                    autoComplete="lname"
                    disabled={readOnly || loading}
                    error={touched.numero && errors.numero}
                    helperText={touched.numero && errors.numero}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                name="complemento"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    id="complemento"
                    label="Complemento"
                    disabled={readOnly || loading}
                    error={touched.complemento && errors.complemento}
                    helperText={touched.complemento && errors.complemento}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="novaSenha"
                disabled={readOnly || loading}
                type="password"
                margin="normal"
                fullWidth
                id="novaSenha"
                label="Nova senha"
                value={values.novaSenha}
                error={touched.novaSenha && errors.novaSenha}
                helperText={touched.novaSenha && errors.novaSenha}
                as={TextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                disabled={readOnly || loading}
                type="password"
                margin="normal"
                fullWidth
                id="passConfirm,"
                name="passConfirm"
                label="Confirmar senha"
                error={touched.passConfirm && errors.passConfirm}
                helperText={touched.passConfirm && errors.passConfirm}
                as={TextField}
              />
            </Grid>
            {user && !readOnly && <></>}
          </Grid>

          {!readOnly && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={readOnly || loading}
            >
              Salvar
            </Button>
          )}
        </FormikForm>
      )}
    </AdminForm>
  );
}

export default Form;
