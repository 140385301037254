import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { mdiCards, mdiHelp, mdiHospitalBox, mdiCheck } from '@mdi/js';

import { FirstName, Surname, Container, StatusPlayer } from './styles';

const data = {
  2: (
    <SvgIcon style={{ color: 'orange' }}>
      <path d={mdiHelp} />
    </SvgIcon>
  ),
  3: (
    <SvgIcon style={{ color: 'red' }}>
      <path d={mdiCards} />
    </SvgIcon>
  ),
  5: (
    <SvgIcon style={{ color: 'red' }}>
      <path d={mdiHospitalBox} />
    </SvgIcon>
  ),
  7: (
    <SvgIcon style={{ color: 'green' }}>
      <path d={mdiCheck} />
    </SvgIcon>
  ),
};

function Name({ first, second, statusPlayer, size = 'normal' }) {
  return (
    <Container>
      <StatusPlayer>
        {!!statusPlayer && data[statusPlayer]}
        <FirstName size={size}>{first}</FirstName>
      </StatusPlayer>
      {second && <Surname size={size}>{second}</Surname>}
    </Container>
  );
}

export default Name;
