import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableWrapper from '../../TableWrapper/index';

export default function PerPositionTable({ data = [] }) {
  return (
    <TableWrapper title="Pontos por posição">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell size="small" align="center">
              Posicao
            </TableCell>
            <TableCell size="small" align="center">
              Pontos
            </TableCell>
            <TableCell size="small" align="center">
              Média
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.posicao}>
              <TableCell size="small" align="center">
                {row.posicao}
              </TableCell>
              <TableCell align="center">{row.pontos}</TableCell>
              <TableCell size="small" align="center">
                {row.media}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}
