import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { grey } from '@material-ui/core/colors';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: 48,
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconButton: {
    opacity: 0.54,
    transform: 'scale(1, 1)',
    transition: 'transform 200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
  },
  iconButtonHidden: {
    transform: 'scale(0, 0)',
    '& > $icon': {
      opacity: 0,
    },
  },
  iconButtonDisabled: {
    opacity: 0.38,
  },
  searchIconButton: {
    marginRight: -48,
  },
  icon: {
    opacity: 0.54,
    transition: 'opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
  },
  input: {
    width: '100%',
  },
  searchContainer: {
    margin: 'auto 16px',
    width: 'calc(100% - 48px - 32px)', // 48px button + 32px margin
  },
}));

/**
 * Material design search bar
 * @see [Search patterns](https://material.io/guidelines/patterns/search.html)
 */
function SearchBar({
  cancelOnEscape,
  className,
  closeIcon,
  disabled,
  onCancelSearch,
  onRequestSearch,
  searchIcon,
  style,
  value: propsValue,
  onFocus,
  onBlur,
  onChange,
  onKeyUp,
  ...inputProps
}) {
  const classes = useStyles();
  const inputRef = useRef(null);

  const [value, setValue] = useState('');

  useEffect(() => {
    if (propsValue) {
      setValue(propsValue);
    }
  }, [propsValue]);

  // static getDerivedStateFromProps (props, state) {
  //   if (props.value !== state.value) {
  //     return { value: props.value }
  //   }
  //   return null
  // }

  const handleFocus = (e) => {
    // setFocus(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e) => {
    // setFocus(false);

    if (value.trim().length === 0) {
      setValue('');
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleInput = (e) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const handleCancel = () => {
    // setActive(false);
    setValue('');
    if (onCancelSearch) {
      onCancelSearch();
    }
  };

  const handleRequestSearch = () => {
    if (onRequestSearch) {
      onRequestSearch(value);
    }
  };

  const handleKeyUp = (e) => {
    if (e.charCode === 13 || e.key === 'Enter') {
      handleRequestSearch();
    } else if (cancelOnEscape && (e.charCode === 27 || e.key === 'Escape')) {
      handleCancel();
    }
    if (onKeyUp) {
      onKeyUp(e);
    }
  };

  /**
   * @public
   * Focus the input component.
   */
  // const focusInput = () => {
  //   inputRef.current.focus();
  // };

  // /**
  //  * @public
  //  * Blur the input component.
  //  */
  // const blur = () => {
  //   inputRef.current.blur();
  // };

  return (
    <Paper className={classNames(classes.root, className)} style={style}>
      <div className={classes.searchContainer}>
        <Input
          {...inputProps}
          inputRef={inputRef}
          onBlur={handleBlur}
          value={value}
          onChange={handleInput}
          onKeyUp={handleKeyUp}
          onFocus={handleFocus}
          fullWidth
          className={classes.input}
          disableUnderline
          disabled={disabled}
        />
      </div>
      <IconButton
        onClick={handleRequestSearch}
        classes={{
          root: classNames(classes.iconButton, classes.searchIconButton, {
            [classes.iconButtonHidden]: value !== '',
          }),
          disabled: classes.iconButtonDisabled,
        }}
        disabled={disabled}
      >
        {React.cloneElement(searchIcon, {
          classes: { root: classes.icon },
        })}
      </IconButton>
      <IconButton
        onClick={handleCancel}
        classes={{
          root: classNames(classes.iconButton, {
            [classes.iconButtonHidden]: value === '',
          }),
          disabled: classes.iconButtonDisabled,
        }}
        disabled={disabled}
      >
        {React.cloneElement(closeIcon, {
          classes: { root: classes.icon },
        })}
      </IconButton>
    </Paper>
  );
}

SearchBar.defaultProps = {
  className: '',
  closeIcon: <ClearIcon style={{ color: grey[500] }} />,
  disabled: false,
  placeholder: 'Procurar',
  searchIcon: <SearchIcon style={{ color: grey[500] }} />,
  style: null,
  value: '',
  cancelOnEscape: undefined,
  classes: undefined,
  onCancelSearch: undefined,
  onChange: undefined,
  onRequestSearch: undefined,
};

SearchBar.propTypes = {
  /** Whether to clear search on escape */
  cancelOnEscape: PropTypes.bool,
  /** Override or extend the styles applied to the component. */
  classes: PropTypes.object,
  /** Custom top-level class */
  className: PropTypes.string,
  /** Override the close icon. */
  closeIcon: PropTypes.node,
  /** Disables text field. */
  disabled: PropTypes.bool,
  /** Fired when the search is cancelled. */
  onCancelSearch: PropTypes.func,
  /** Fired when the text value changes. */
  onChange: PropTypes.func,
  /** Fired when the search icon is clicked. */
  onRequestSearch: PropTypes.func,
  /** Sets placeholder text for the embedded text field. */
  placeholder: PropTypes.string,
  /** Override the search icon. */
  searchIcon: PropTypes.node,
  /** Override the inline-styles of the root element. */
  style: PropTypes.object,
  /** The value of the text field. */
  value: PropTypes.string,
};

export default SearchBar;
