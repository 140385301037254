import React from 'react';
import { Typography } from '@material-ui/core';
import HSBar from 'react-horizontal-stacked-bar-chart';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import Card from '../../Card';
import {
  Wrapper,
  Teams,
  TeamShield,
  HSBarContainer,
  Legend,
  Text,
  DateInfo,
  Date,
  Hour,
  GameContainer,
  Stadium,
} from './styles';

function ShortComparison({
  hostShield,
  visShield,
  hostName,
  visName,
  probability,
  title,
  minHeight,
  date,
  hour,
  stadium,
}) {
  return (
    <Card>
      <Wrapper minHeight={minHeight}>
        <Typography variant="h5">{title}</Typography>
        <GameContainer>
          <DateInfo>
            <Date>{date}</Date>
            <Stadium>{stadium}</Stadium>
            <Hour>{hour}</Hour>
          </DateInfo>
          <Teams>
            <TeamShield>
              <img alt={`Escudo ${hostShield}`} src={hostShield} />
              <span>{hostName}</span>
            </TeamShield>

            <span>X</span>
            <TeamShield>
              <img alt={`Escudo ${visShield}`} src={visShield} />
              <span>{visName}</span>
            </TeamShield>
          </Teams>
        </GameContainer>
        <HSBarContainer>
          <HSBar
            showTextUp
            id="chances"
            data={[
              {
                value: Number(probability.vitoria.replace('%', '')),
                description: probability.vitoria,
                color: 'green',
              },
              {
                value: Number(probability.empate.replace('%', '')),
                description: probability.empate,
                color: 'gray',
              },
              {
                value: Number(probability.derrota.replace('%', '')),
                description: probability.derrota,
                color: 'red',
              },
            ]}
          />
        </HSBarContainer>
        <Legend>
          <Text color="green">
            <DotIcon fontSize="small" color="inherit" />{' '}
            <Typography color="textPrimary" variant="caption">
              Vitória Mandante
            </Typography>
          </Text>
          <Text color="gray">
            <DotIcon fontSize="small" color="inherit" />{' '}
            <Typography color="textPrimary" variant="caption">
              Empate
            </Typography>
          </Text>
          <Text color="red">
            <DotIcon fontSize="small" color="inherit" />{' '}
            <Typography color="textPrimary" variant="caption">
              Vitória Visitante
            </Typography>
          </Text>
        </Legend>
      </Wrapper>
    </Card>
  );
}

export default ShortComparison;
