import React from 'react';

import { Container, TeamLogo, Score, Cross } from './styles';

function SmallComparison({
  hostShield,
  hostScore,
  visShield,
  visScore,
  ...rest
}) {
  return (
    <Container {...rest}>
      <TeamLogo alt="Escudo Casa" src={hostShield} />
      <Score>{hostScore}</Score>
      <Cross>X</Cross>
      <Score>{visScore}</Score>
      <TeamLogo alt="Escudo Visitante" src={visShield} />
    </Container>
  );
}

export default SmallComparison;
