import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  LocationInfo,
  TeamContainer,
  TeamName,
  TeamShield,
  Date,
  Stadium,
  Hour,
  Score,
  Cross,
  More,
  GameInfo,
  LastGames,
  TeamNameWrapper,
  TeamPosition,
  Probability,
} from './styles';

const scoreColor = {
  v: 'green',
  d: 'red',
  e: 'gray',
};

function Team({ scores, shield, name, reverse = false, pos }) {
  return (
    <TeamContainer reverse={reverse}>
      <LastGames>
        {scores &&
          scores.map(
            (score) =>
              score && (
                <span key={Math.random()} style={{ color: scoreColor[score] }}>
                  •
                </span>
              )
          )}
      </LastGames>
      <TeamNameWrapper>
        <TeamName>{name}</TeamName>
        <TeamPosition reverse={reverse}>{pos}º</TeamPosition>
      </TeamNameWrapper>
      <TeamShield src={shield} />
    </TeamContainer>
  );
}

function Game({
  id,
  posVis,
  posHost,
  shieldHost,
  shieldVis,
  scoreHost,
  scoreVist,
  stadium,
  lastScoresHost,
  lastScoresVis,
  chanceHost,
  chanceVis,
  date,
  hour,
  hostName,
  visName,
  currentRound,
  round,
}) {
  const history = useHistory();

  return (
    <Container
      onClick={
        !currentRound || currentRound === round
          ? () => history.push(`jogos/${id}`)
          : undefined
      }
      pointer={!currentRound || currentRound === round}
    >
      <LocationInfo>
        <Date>{date}</Date> <Stadium>{stadium}</Stadium>
        <Hour>{hour}</Hour>
      </LocationInfo>
      <GameInfo>
        <Team
          shield={shieldHost}
          name={hostName}
          scores={lastScoresHost}
          pos={posHost}
        />
        <Score>{scoreHost}</Score>
        <Cross>X</Cross>
        <Score>{scoreVist}</Score>
        <Team
          shield={shieldVis}
          name={visName}
          scores={lastScoresVis && lastScoresVis.reverse()}
          pos={posVis}
          reverse
        />
      </GameInfo>
      <Probability>
        <strong style={{ color: chanceHost > chanceVis ? 'green' : 'red' }}>
          {!currentRound || currentRound === round ? chanceHost : ' '}
        </strong>
        {!currentRound || currentRound === round ? (
          <span>Chance de vitória</span>
        ) : (
          <span>Partida finalizada</span>
        )}

        <strong style={{ color: chanceVis > chanceHost ? 'green' : 'red' }}>
          {!currentRound || currentRound === round ? chanceVis : ' '}
        </strong>
      </Probability>
      {(!currentRound || currentRound === round) && <More>Saiba mais</More>}
    </Container>
  );
}

export default Game;
