import React from 'react';

import { Pill, Container } from './styles';

function Card({ children, title, style, elevation = 1, ...props }) {
  return (
    <Container style={style} elevation={elevation} {...props}>
      {title && <Pill>{title}</Pill>}
      {children}
    </Container>
  );
}

export default Card;
