import styled, { css } from 'styled-components';
import muiTheme from '../../../styles/MuiTheme';

export const Container = styled.div`
  cursor: pointer;

  *.MuiButtonBase-root {
    position: absolute;
    top: -15px;
    left: -15px;
  }
`;

export const PlayerContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div.scouts {
    flex: 1;

    ${({ comparable }) =>
      comparable &&
      css`
        > div {
          &:first-child {
            padding-left: 25px;
          }
        }
      `}
  }

  ${({ card }) =>
    card &&
    css`
      ${muiTheme.breakpoints.up('sm')} {
        align-items: unset;
        height: 220px;
      }
    `}
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
