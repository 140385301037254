import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import TableWrapper from '../../TableWrapper';
import SmallComparison from '../../GameComponents/SmallComparison';
import { GameContainer } from './styles';
import { arrayFiller } from '../../../utils/arrayFiller';

function GoalsTable({ title }) {
  return (
    <TableWrapper title={title}>
      <Table>
        <TableHead>
          <TableCell size="small" align="left">
            Partida
          </TableCell>
          <TableCell size="small" align="right">
            Autor do Gol
          </TableCell>
        </TableHead>
        <TableBody>
          {arrayFiller(11).map((e) => (
            <TableRow key={e}>
              <TableCell size="small" align="left">
                <GameContainer>
                  <span>Rodada #38</span>
                  <SmallComparison
                    hostShield="https://s.glbimg.com/es/sde/f/organizacoes/2016/05/03/inter65.png"
                    visShield="https://s.glbimg.com/es/sde/f/organizacoes/2016/05/03/inter65.png"
                  />
                </GameContainer>
              </TableCell>
              <TableCell size="small" align="right">
                Matheus Fernandes
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}

export default GoalsTable;
