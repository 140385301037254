import React from 'react';

import Card from '../../Card';
import { Container, Stats } from './styles';
import { SingleStat } from '../../PlayerComponents/PlayerStats';
import TeamData from '../TeamData';
// https://s2.glbimg.com/67uKOOcm-96vbuOSAr48uI734jg=/https://s3.glbimg.com/v1/AUTH_58d78b787ec34892b5aaa0c7a146155f/cartola_svg_138/escudo/45/52/06/00bf817a12-1ee5-4789-b7e9-a5ec43435a4520190422195206
function GeneralVision({
  teamName,
  shield,
  teamOwner,
  total,
  average,
  patrimonio,
}) {
  return (
    <Card>
      <Container>
        <TeamData shield={shield} teamName={teamName} teamOwner={teamOwner} />
        <Stats>
          <SingleStat
            value={total && total.toFixed(2)}
            description="Total"
            details="pontos"
            horizontal
          />
          <SingleStat
            value={average && average.toFixed(2)}
            description="Média"
            details="pontos"
            horizontal
          />
          <SingleStat
            value={patrimonio && patrimonio.toFixed(2)}
            description="Patrimônio"
            details="cartoletas"
            horizontal
          />
        </Stats>
      </Container>
    </Card>
  );
}

export default GeneralVision;
