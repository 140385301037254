import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: ${({ pointer }) => pointer && 'pointer'};
  *:not(*:last-child) {
    margin-bottom: 3px;
  }
`;

export const LocationInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.2rem;
`;
export const GameInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  font-size: 1.3rem;
  align-items: center;
  margin: 0 1rem;
  img {
    ${({ reverse }) => (reverse ? 'margin-right: 3px' : 'margin-left: 3px')};
  }
`;

export const TeamNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const TeamName = styled.span`
  text-transform: uppercase;
  font-weight: medium;
`;

export const TeamPosition = styled.span`
  font-weight: bold;
  font-size: 1.1rem;
  position: absolute;
  top: 1.2rem;
  ${({ reverse }) => (reverse ? 'left: 0' : 'right 0')};
`;

export const TeamShield = styled.img`
  height: 30px;
`;

export const Probability = styled.div`
  display: flex;
  flex-direction: row;

  strong {
    font-size: 1.4rem;

    color: red;
    &:first-child {
      color: green;
    }
  }
  span {
    margin: 0 5px;
    font-size: 1.2rem;
  }
`;

export const LastGames = styled.span`
  /* color: green; */
  font-size: 30px;
  margin: 0 5px;
`;

export const Date = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 5px;
`;
export const Stadium = styled.span`
  text-transform: uppercase;
  margin-right: 5px;
`;

export const Hour = styled.span`
  font-weight: bold;
`;

export const Score = styled.span`
  font-size: 1.4rem;
  font-weight: bolder;
`;

export const Cross = styled.span`
  color: #999;
  margin: 0.5rem;
`;
export const More = styled.span`
  display: flex;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
`;
