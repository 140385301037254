import React from 'react';
import { Typography } from '@material-ui/core';
import Card from '../Card';

import { Container } from './styles';

function NoResults({ text = '' }) {
  return (
    <Card elevation={2}>
      <Container>
        <Typography variant="subtitle1">
          {text || 'Não existem resultados para os filtros selecionados'}
        </Typography>
      </Container>
    </Card>
  );
}

export default NoResults;
