import React from 'react';
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  ResponsiveContainer,
} from 'recharts';

function Tick(props) {
  const { x, y, payload, textAnchor } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={7} textAnchor={textAnchor} fill="#666">
        {payload.value}
      </text>
    </g>
  );
}

export default function RadarChar({ playerAName, playerBName, data = [] }) {
  return (
    <ResponsiveContainer>
      <RadarChart data={data}>
        <PolarGrid />
        <PolarAngleAxis dataKey="scout" tick={Tick} />
        {/* <PolarRadiusAxis angle={75} /> */}
        <Radar
          name={playerAName}
          dataKey="playerA"
          stroke="#1A572A"
          fill="#1A572A"
          fillOpacity={0.8}
        />
        <Radar
          name={playerBName}
          dataKey="playerB"
          stroke="#FFD700"
          fill="#FFD700"
          fillOpacity={0.8}
        />
        <Legend layout="vertical" />
      </RadarChart>
    </ResponsiveContainer>
  );
}
