import { toast } from 'react-toastify';
import api from './_api';

export async function post(url, data, action) {
  await api
    .post(url, data)
    .then((res) => {
      if (res.data) {
        toast.success(res.data.mensagem || 'Ação realizada com sucesso');
      } else {
        toast.success('Ação realizada com sucesso');
      }
      action();
    })
    .catch((err) => {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.mensagem
      ) {
        toast.warn(err.response.data.mensagem);
        return;
      }
      toast.error('Ocorreu um erro');
    });
}

export async function put(url, data, action) {
  await api
    .put(url, data)
    .then((res) => {
      // console.tron.log(res);

      if (res.data) {
        toast.success(res.data.mensagem || 'Ação realizada com sucesso');
      } else {
        toast.success('Ação realizada com sucesso');
      }
      action();
    })
    .catch((err) => {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.mensagem
      ) {
        toast.warn(err.response.data.mensagem);
        return;
      }
      toast.error('Ocorreu um erro');
    });
}

export async function erase(url, action, data) {
  await api
    .delete(url, { data: { ...data } })
    .then((res) => {
      if (res.data) {
        toast.success(res.data.mensagem || 'Ação realizada com sucesso');
      } else {
        toast.success('Ação realizada com sucesso');
      }
      action();
    })
    .catch((err) => {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.mensagem
      ) {
        toast.warn(err.response.data.mensagem);
        return;
      }
      toast.error('Ocorreu um erro');
    });
}
