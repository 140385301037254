import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    margin-bottom: 10px;
  }
`;

export const RoundContainer = styled.div`
  display: flex;
  padding: 5px 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const ScoutsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > div {
    margin-top: 3px;
  }
`;

export const Wrapper = styled.div`
  *.MuiTableCell-sizeSmall {
    padding: 6px 6px 6px 6px;
  }
`;

export const PlayerName = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    &:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: justify;
    }
  }

  img {
    margin-right: 5px;
    height: 25px;
  }
`;

export const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > div {
    justify-content: left;
  }
`;

export const PointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  word-break: keep-all;
  > div {
    display: flex;
    flex-direction: column;
    > div {
      justify-content: flex-end;
    }
  }
  > span {
    font-weight: bold;
    font-size: 18px;
  }
`;
