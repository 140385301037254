export function saveUsersSuccess(user) {
  return {
    payload: { user },
    type: '@auth/SAVE_USER_SUCCESS',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function newMember() {
  return {
    type: '@auth/TURN_MEMBER',
  };
}

export function udpdateUser(user) {
  return {
    type: '@auth/UPDATE_USER',
    payload: { user },
  };
}
