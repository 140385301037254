import React from 'react';

import { Container, Label, Value } from './styles';

function TwoTimes({ value }) {
  return (
    <Container>
      <Label>{value}</Label>
      <Value>x1.5</Value>
    </Container>
  );
}

export default TwoTimes;
