import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;

  background: ${(props) => props.color || '#fff'};
`;

export const Title = styled.span`
  color: #666666;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  margin-left: 5px;
  text-align: center;
`;

export const HorizontalBar = styled.div`
  width: 173px;
  border: 1px solid #b4b4b4;

  &:last-child {
    border: none;
  }
`;
