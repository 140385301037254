import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core';

import { MdDelete, MdEdit, MdRemoveRedEye } from 'react-icons/md';
import Pagination from '@material-ui/lab/Pagination';
import WarnMessage from '../../components/WarnMessage';

import { Action } from './styles';
import AdminActionButtons from '../../components/AdminActionButtons';
import Form from './Form';
import AdminTableContainer from '../../components/AdminTableContainer';
import ModalContainer from '../../components/ModalContainer';
import { getCoupon } from '../../services/catimbaStats/adminCoupon';
import { post, put, erase } from '../../services/catimbaStats/admin';
import AdminTableLoading from '../../components/AdminTableLoading';

export default function AdminCoupon() {
  const [popup, setPopup] = useState({
    show: false,
    option: '',
    coupon: null,
  });
  const [coupons, setCoupons] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState();

  const handleGetCoupons = useCallback(async () => {
    setLoading(true);

    getCoupon({ page })
      .then((res) => {
        setCoupons(res.data.content);
        setTotalPages(res.data.totalPages);
      })
      .finally(() => setLoading(false));
  }, [page]);

  useEffect(() => {
    handleGetCoupons();
  }, [handleGetCoupons]);

  const handleClosePopup = useCallback(
    (update) => {
      setPopup({
        show: false,
        option: '',
        coupon: null,
      });

      if (update) {
        handleGetCoupons();
      }
    },
    [handleGetCoupons]
  );

  const handleUpdateCoupon = useCallback(
    (data) => {
      // setLoading(true);

      put('/cupom', data, () => handleClosePopup(true)).finally(() =>
        setLoading(false)
      );
    },
    [handleClosePopup]
  );

  const handleCreateCoupon = useCallback(
    (data) => {
      // setLoading(true);

      post('/cupom', data, () => handleClosePopup(true)).finally(() =>
        setLoading(false)
      );
    },
    [handleClosePopup]
  );

  const handleDeleteCoupon = useCallback(
    (id) => {
      // setLoading(true);

      erase(`/cupom/${id}`, () => handleClosePopup(true)).finally(() =>
        setLoading(false)
      );
    },
    [handleClosePopup]
  );

  return (
    <>
      <ModalContainer
        open={popup.show}
        onClose={handleClosePopup}
        position={popup.option === 'warn' && '5px'}
      >
        {popup.option === 'warn' ? (
          <WarnMessage
            onCancel={() =>
              setPopup({
                show: false,
                option: '',
                coupon: null,
              })
            }
            onConfirm={() => handleDeleteCoupon(popup.coupon.id)}
          >
            Tem certeza que deseja excluir o Cupom?
          </WarnMessage>
        ) : (
          <Form
            coupon={popup.coupon}
            update={handleUpdateCoupon}
            create={handleCreateCoupon}
            readOnly={popup.option === 'view'}
          />
        )}
      </ModalContainer>
      <AdminTableContainer
        title="Cupom de desconto"
        addFunc={() =>
          setPopup({
            ...popup,
            show: true,
            option: 'add',
          })
        }
      >
        <TableContainer>
          {!loading ? (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell align="right">Percentual</TableCell>
                  <TableCell align="right">Validade</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {coupons.map((coupon) => (
                  <TableRow key={coupon.id}>
                    <TableCell>{coupon.id}</TableCell>
                    <TableCell component="th" scope="coupon">
                      {coupon.nome}
                    </TableCell>
                    <TableCell align="right">{coupon.percentual}%</TableCell>
                    <TableCell align="right">
                      {coupon.validade.split('-').reverse().join('/')}
                    </TableCell>
                    <TableCell align="right">
                      {coupon.status ? 'Habilitado' : 'Desabilitado'}
                    </TableCell>
                    <TableCell align="right">
                      <Action>
                        <AdminActionButtons
                          size="small"
                          title="Visualizar"
                          onClick={() =>
                            setPopup({
                              show: true,
                              option: 'view',
                              coupon,
                            })
                          }
                        >
                          <MdRemoveRedEye />
                        </AdminActionButtons>
                        <AdminActionButtons
                          size="small"
                          title="Editar"
                          onClick={() =>
                            setPopup({
                              show: true,
                              option: 'edit',
                              coupon,
                            })
                          }
                        >
                          <MdEdit />
                        </AdminActionButtons>
                        <AdminActionButtons
                          size="small"
                          title="Excluir"
                          onClick={() =>
                            setPopup({
                              show: true,
                              option: 'warn',
                              coupon,
                            })
                          }
                        >
                          <MdDelete />
                        </AdminActionButtons>
                      </Action>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <AdminTableLoading />
          )}
        </TableContainer>
        <Box display="flex" justifyContent="center" paddingBottom="1rem">
          {!!totalPages && (
            <Pagination
              count={totalPages}
              page={page + 1}
              onChange={(_, p) => setPage(p - 1)}
              siblingCount={0}
              boundaryCount={1}
            />
          )}
        </Box>
      </AdminTableContainer>
    </>
  );
}
