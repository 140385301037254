import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

import { Container, Title } from './styles';

function InfoPlan({ children, icon }) {
  return (
    <>
      <Container>
        {icon === 'check' ? (
          <CheckIcon color="primary" />
        ) : (
          <ClearIcon color="error" />
        )}
        <Title>{children}</Title>
      </Container>
      {/* <HorizontalBar /> */}
    </>
  );
}

export default InfoPlan;
