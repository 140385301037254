import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import colors from '../../styles/colors';

export const Container = styled(Paper)`
  position: relative;
  /* height: 3rem; */
  padding: 1rem;
`;

export const Pill = styled.span`
  position: absolute;
  top: -1rem;
  background-color: ${colors.primary};
  padding: 2px 5px;
  border-radius: 50px;
  color: #fff;
  font-size: 1.1rem;
`;
