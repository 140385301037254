import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import { getTeamRank } from '../../services/catimbaStats/statistics';
import Loader from '../../components/Loader';
import TeamRank from '../../components/TeamRank';

// import { Container } from './styles';

function ClubRanking() {
  const [ranks, setRanks] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getTeamRank()
      .then((res) => setRanks(res.data))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Container>
      {ranks && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <TeamRank
              title="Média de pontos"
              subtitle="Conquistados em casa"
              data={ranks.mediaPontosCasa}
              type='1'
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TeamRank
              title="Média de pontos"
              subtitle="Conquistados fora"
              data={ranks.mediaPontosFora}
              type='1'
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TeamRank
              title="Melhor mandante"
              subtitle="Jogando em casa"
              data={ranks.melhorMandanteCasa}
              type='2'
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TeamRank
              title="Melhor visitante"
              subtitle="Jogando fora"
              data={ranks.melhorMandanteFora}
              type='2'
            />
          </Grid>
        </Grid>
      )}
      {loading && <Loader />}
    </Container>
  );
}

export default ClubRanking;
