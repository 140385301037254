import axios from 'axios';
import api from './_api';

// var CancelToken = axios.CancelToken;
// var call1 = CancelToken.source();

const base = 'estatisticas';

function getAppretiationList() {
  let call;
  return async ({
    page = 0,
    size = 50,
    idPosicao,
    idStatus,
    idClube,
    nomeAtleta,
  }) => {
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();
    return api
      .get(`${base}/valorizacao`, {
        cancelToken: call.token,
        params: {
          page,
          size,
          idPosicao,
          idStatus,
          idClube,
          nomeAtleta,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          return Promise.reject(thrown);
        }
        return Promise.reject(thrown);
      });
  };
}

function getBasicAverageList() {
  let call;
  return async ({
    page = 0,
    size = 50,
    idPosicao,
    idStatus,
    idClube,
    nomeAtleta,
  }) => {
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();
    return api
      .get(`${base}/mediaBasica`, {
        cancelToken: call.token,
        params: {
          page,
          size,
          idPosicao,
          idStatus,
          idClube,
          nomeAtleta,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          return Promise.reject(thrown);
        }
        return Promise.reject(thrown);
      });
  };
}

function getMostEscaledList() {
  let call;
  return async () => {
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();
    return api
      .get(`${base}/maisEscalados`, {
        cancelToken: call.token,
        params: {},
      })
      .then((response) => {
        return response;
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          return Promise.reject(thrown);
        }
        return Promise.reject(thrown);
      });
  };
}

function getScoutsComparisonList() {
  let call;
  return async ({ scout = 'RB', idPosicao = '5', rodada = 0, tipo = 1 }) => {
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();
    return api
      .get(`${base}/cruzamentoScouts`, {
        cancelToken: call.token,
        params: {
          idPosicao,
          scout,
          rodada,
          tipo,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          return Promise.reject(thrown);
        }
        return Promise.reject(thrown);
      });
  };
}

function getAimList() {
  let call;
  return async ({
    page,
    size = 50,
    idPosicao,
    idStatus,
    idClube,
    nomeAtleta,
  }) => {
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();
    return api
      .get(`${base}/pontaria`, {
        cancelToken: call.token,
        params: {
          page,
          size,
          idPosicao,
          idStatus,
          idClube,
          nomeAtleta,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          return Promise.reject(thrown);
        }
        return Promise.reject(thrown);
      });
  };
}

function getPontosCedidosList() {
  let call;
  return async ({ mando = 1, idPosicao = 0, rodada = 0 }) => {
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();
    return api
      .get(`${base}/pontosCedidos`, {
        cancelToken: call.token,
        params: {
          mando,
          idPosicao,
          rodada,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          return Promise.reject(thrown);
        }
        return Promise.reject(thrown);
      });
  };
}

export function getGoalsDifference() {
  return api.get(`${base}/golsProContra`);
}

export function getMyTeamStats(id) {
  return api.get(`${base}/time/${id}`);
}

export function getMyTeamEscalation(id, rodada = '') {
  return api.get(`time/${id}/escalacao?rodada=${rodada}`);
}

export function getTeamRank() {
  return api.get(`${base}/rankingClubes`);
}

export function getDashboardInfo() {
  return api.get(`${base}/dashboard`);
}

export const getPontosCedidos = getPontosCedidosList();
export const getAppreciation = getAppretiationList();
export const getBasicAverage = getBasicAverageList();
export const getMostEscaled = getMostEscaledList();
export const getScoutsComparison = getScoutsComparisonList();
export const getAim = getAimList();
