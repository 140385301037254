import styled from 'styled-components';
import { lighten } from 'polished';
import MuiContainer from '@material-ui/core/Container';
import respond from '../../styles/respond';
import colors from '../../styles/colors';

export const Wrapper = styled.footer`
  bottom: 0;
  width: 100%;
  clear: both;
  background: #212121;
  position: inherit;
  display: flex;
  flex-direction: column;

  .adsbygoogle {
    height: 30px !important;
  }
`;
export const Container = styled(MuiContainer)`
  padding: 4rem 0rem 2rem 0rem;

  color: #fff;

  ${respond.phone} {
    /* padding: 4rem 6rem 2rem 6rem; */
  }
`;

export const FooterMain = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  /* align-items: center; */

  ${respond.tabPort} {
    display: flex;
    flex-direction: column;
  }
`;
export const Button = styled.a`
  background: none;

  border: 1px solid #fff;
  border-bottom: 1px solid #fff !important;
  border-radius: 50px;
  font-weight: 500;
  text-transform: uppercase;
  width: 90%;
  max-width: 20rem;
  min-width: 15rem;
  font-size: 1.4rem;
  ${respond.tabPort} {
    width: 100%;
  }
  cursor: pointer;

  display: inline-block;

  padding: 0rem 0.8rem;
  margin-top: 1rem;
  text-align: center;
`;
export const Logo = styled.div`
  grid-column: 1;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Column = styled.div`
  grid-column: span 1;
  ${respond.tabPort} {
    margin: 0 auto;
    text-align: center;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 2rem;
    ${respond.tabPort} {
      margin-bottom: 0.6rem;
      margin-top: 1.7rem;
    }
  }

  ul {
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 2.5rem;

    &.center {
      width: fit-content;
      text-align: center;
    }

    a {
      color: inherit;
      transition: 0.2s all;
      img {
        height: 50px;
        display: inline-block;
        &.appstore {
          height: 38px;
        }
        &.playstore {
          left: 0;
        }
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const FooterBottom = styled.div`
  background: #000;
  width: 100%;
  padding: 0.6rem 2rem;
  color: #fff;
  font-size: 1.2rem;

  display: flex;
  justify-content: center;

  ${respond.tabPort} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Copyright = styled.div`
  font-weight: 300;
`;

export const Credits = styled.div`
  a {
    color: ${colors.accent};

    &:hover {
      color: ${lighten(0.3, colors.accent)};
    }
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 0.8rem;
  a {
    margin-right: 2rem;
    color: #fff;
    text-decoration: none;

    &:last-child {
      margin-right: 0;
    }
  }
  svg {
    font-size: 2.3rem;
  }
`;
