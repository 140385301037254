import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';
import SmallComparison from '../../GameComponents/SmallComparison';
import TableWrapper from '../../TableWrapper';
import ScoutPill from '../ScoutPill';
import scoutsToArrayConverter from '../../../utils/scoutsToArrayConverter';
// import PlayerInfo from '../PlayerInfo';

import {
  ScoutsContainer,
  Wrapper,
  PlayerName,
  StatsContainer,
  PointsContainer,
} from './styles';
import Points from '../Points';

export default function PlayerNextGameTable({ title, data = [] }) {
  return (
    <Wrapper>
      <TableWrapper title={title}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell size="small" align="left">
                Jogador
              </TableCell>
              {/* <TableCell size="small" align="left" /> */}
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.rodada}>
                <TableCell component="th" scope="row" align="left">
                  <StatsContainer>
                    <PlayerName>
                      <div>
                        <img
                          src={row.fotoClubeJogador}
                          alt={`Clube ${row.nomeJogador}`}
                        />
                        <Typography variant="body2">
                          {row.nomeJogador}
                        </Typography>
                      </div>
                    </PlayerName>

                    <ScoutsContainer>
                      <ScoutsContainer>
                        {scoutsToArrayConverter(row.scoutsPositivo).map(
                          (scout) => (
                            <ScoutPill key={scout}>{scout}</ScoutPill>
                          )
                        )}
                        {scoutsToArrayConverter(row.scoutsNegativo).map(
                          (scout) => (
                            <ScoutPill key={scout} negative>
                              {scout}
                            </ScoutPill>
                          )
                        )}
                      </ScoutsContainer>
                    </ScoutsContainer>
                  </StatsContainer>
                </TableCell>
                {/* <TableCell size="small" align="left">

                </TableCell> */}
                <TableCell align="right">
                  <PointsContainer>
                    <div>
                      <SmallComparison
                        hostScore={row.placarClubeCasa}
                        visScore={row.placarClubeVisitante}
                        hostShield={row.fotoClubeCasa}
                        visShield={row.fotoClubeVisitante}
                      />
                    </div>
                    Rodada #{row.rodada}
                    <Points value={row.pontos}>{row.pontos}</Points>
                  </PointsContainer>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </Wrapper>
  );
}
