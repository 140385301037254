import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { AppBar, Grid, Tab, Tabs, Typography } from '@material-ui/core';

import ChartComponent from '../../components/ChartComponents/Chart';
import ShortComparison from '../../components/GameComponents/ShortComparison';
import PriceTable from '../../components/PlayerComponents/PriceTable';
import AverageTable from '../../components/PlayerComponents/AverageTable';

import { GridContainer } from './styles';
import { getPlayerStats } from '../../services/catimbaStats/players';
import scoutsToArrayConverter from '../../utils/scoutsToArrayConverter';
import PlayerNextGameTable from '../../components/PlayerComponents/PlayerNextGameTable';
import NextGameRow from '../../components/PlayerComponents/NextGameRow';
import Loader from '../../components/Loader';
import PlayerDetails from '../../components/PlayerDetails';
import PlayerLastGames from '../../components/PlayerComponents/PlayerLastGames';

function PlayerStats({ match }) {
  const [player, setPlayer] = useState();

  useEffect(() => {
    getPlayerStats(match.params.id).then((res) => {
      setPlayer(res.data);
    });
  }, [match.params.id]);

  const convertedProbability = useMemo(() => {
    if (player) {
      const probability = { ...player.confronto.probabilidade };
      Object.keys(probability).forEach((key) => {
        probability[key] = `${probability[key]}%`;
      });
      return probability;
    }
  }, [player]);

  const convertScoutsToChartData = useCallback((scouts) => {
    if (scouts) {
      const data = [['', 'Pontos']];
      const scoutsArr = Object.entries(scouts);

      scoutsArr.sort((a, b) => b[1] - a[1]);
      return data.concat(
        scoutsArr
          .map((scout) => [scout[0].toUpperCase(), scout[1]])
          .filter((e) => e[1] !== 0)
      );
    }
  }, []);

  const convertedPricePerRound = useMemo(() => {
    if (player) {
      const data = [['', 'Preço']];

      player.preco.forEach((e) => {
        data.push([`#${e.rodada}`, e.preco]);
      });
      return data;
    }
  }, [player]);

  const convertedAverage = useMemo(() => {
    const data = [];
    if (player) {
      data.push(['', 'M. Básica', 'M. Geral']);
      player.mediaBasicaGeral.forEach((e) => {
        data.push([`# ${e.rodada}`, e.mediaBasica, e.mediaGeral]);
      });
    }
    return data;
  }, [player]);

  return (
    <div>
      {player ? (
        <Grid container spacing={1} alignItems="flex-start">
          <PlayerDetails
            jogosCasa={player.jogosCasa}
            jogosFora={player.jogosFora}
            appreciation={player.geral.variacao.toFixed(2)}
            generalAvg={player.medias.mediaGeral.toFixed(2)}
            hostAvg={player.medias.mediaCasa.toFixed(2)}
            minAppreciation={player.geral.minimoValorizacao.toFixed(2)}
            flutuation={player.valorizacao.valoriza.toFixed(2)}
            price={player.geral.preco}
            points={player.geral.pontosTotal.toFixed(2)}
            pointsExpected={player.geral.pontuacaoEsperada.toFixed(2)}
            appreciationExpected={player.geral.valorizacaoEsperada.toFixed(2)}
            visAvg={player.medias.mediaFora.toFixed(2)}
            playerPicture={player.geral.foto_140}
            playerName={player.geral.nome}
            teamName={player.geral.nomeClube}
            teamPicture={player.geral.fotoClube}
            playerPosition={player.geral.detalhePosicao}
            basicAvg={player.medias.mediaBasica.toFixed(2)}
            last={player.valorizacao.ultima.toFixed(2)}
            needs={player.valorizacao.precisa.toFixed(2)}
            positiveScouts={scoutsToArrayConverter(player.geral.scoutsPositivo)}
            negativeScouts={scoutsToArrayConverter(player.geral.scoutsNegativo)}
            avgs={player.medias}
            appreciations={player.valorizacao}
          />
          <Grid item xs={12} md={3}>
            <ShortComparison
              title="Próximo Jogo"
              probability={convertedProbability}
              hostName={player.confronto.nomeClubeCasa}
              hostShield={player.confronto.fotoClubeCasa}
              visName={player.confronto.nomeClubeVisitante}
              visShield={player.confronto.fotoClubeVisitante}
              stadium={player.confronto.local}
              date={player.confronto.dataPartida
                .split(' ')[0]
                .replace(/[-]/g, '/')}
              hour={player.confronto.dataPartida.split(' ')[1].substr(0, 5)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ChartComponent
              data={convertScoutsToChartData(player.scoutsPositivos)}
              title="Scouts Positivos"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ChartComponent
              data={convertScoutsToChartData(player.scoutsNegativos)}
              title="Scouts Negativos"
              color={['#e10000']}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PlayerLastGames
              data={player.jogosCasa}
              title="Pontuação em Casa"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PlayerLastGames
              data={player.jogosFora}
              title="Pontuação Fora de casa"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AverageTable data={player.mediaBasicaGeral} />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <ChartComponent
              data={convertedAverage}
              type="Line"
              title="Média básica X Média geral"
              subtitles={[
                { color: '#e10000', subtitle: 'M.Geral' },
                { color: '#158D49', subtitle: 'M.Básica' },
              ]}
            />
          </Grid>
          <GridContainer container spacing={1}>
            <Grid item xs={12}>
              <NextGameRow
                shield={player.proximoAdversario.fotoClubeAdversario}
                position={player.geral.detalhePosicao}
                host={
                  player.geral.idClube === player.confronto.idClubeCasa
                    ? 'em casa'
                    : 'fora'
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ChartComponent
                data={convertScoutsToChartData(
                  player.proximoAdversario.scoutsPositivo
                )}
                title="Scouts Positivos"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ChartComponent
                data={convertScoutsToChartData(
                  player.proximoAdversario.scoutsNegativo
                )}
                title=" Scouts Negativos"
                color={['#e10000']}
              />
            </Grid>

            <Grid item xs={12}>
              <PlayerNextGameTable
                data={player.proximoAdversario.jogadores}
                // title="Próximo adversário - Scouts Gerais"
              />
            </Grid>
          </GridContainer>
          <Grid item xs={12} md={4} lg={9}>
            <ChartComponent
              data={convertedPricePerRound}
              title="Variação por rodada"
              type="Line"
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <PriceTable prices={player.preco} />
          </Grid>
        </Grid>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default PlayerStats;
