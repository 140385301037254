import styled from 'styled-components';
import { Modal } from '@material-ui/core';

export const Container = styled(Modal)``;

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: fit-content;
  /* width: 100%; */
  /* height: 100%; */
  > button {
    position: absolute;
    top: -5px;
    right: ${({ right }) => right || '25px'};
    background: none;
    border: none;
    font-size: 3rem;
    z-index: 100;
    cursor: pointer;
  }
`;
