import React, { useEffect, useState } from 'react';

import { Grid, Box } from '@material-ui/core';
import Select from 'react-select/async';
import Skeleton from '@material-ui/lab/Skeleton';
import Game from '../../components/GameComponents/Game';
import Card from '../../components/Card';
import selectStyles from '../../styles/selectStyles';

import { Wrapper, SelectContainer } from './styles';
import { getGames } from '../../services/catimbaStats/games';
import { getFilter } from '../../services/catimbaStats/filter';
import NoResults from '../../components/NoResults';

function Games() {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rodada, setRodada] = useState();
  const [rodadaAtual, setRodadaAtual] = useState();

  useEffect(() => {
    setLoading(true);
    getGames(undefined, rodada)
      .then((res) => {
        setGames(res.data.partidas);
        setRodadaAtual(res.data.rodadaAtual);
      })
      .finally(() => setLoading(false));
  }, [rodada]);

  return (
    <Wrapper maxWidth="lg">
      {/* <BackButton to="/" text="Voltar" /> */}

      <SelectContainer>
        <Select
          styles={selectStyles}
          defaultValue={{ label: 'Rodada Atual', value: undefined }}
          loadOptions={() => getFilter('rodadas', 'id', 'descricao')}
          onChange={(option) => setRodada(option.value)}
          cacheOptions
          defaultOptions
          isSearchable={false}
        />
      </SelectContainer>

      <Grid container spacing={1}>
        {!loading && !games.length && (
          <Grid item xs={12}>
            <NoResults text="Não há dados para exibir" />
          </Grid>
        )}
        {!loading
          ? games.map((game) => (
              <Grid key={game.idPartida} item xs={12} md={4}>
                <Card>
                  <Game
                    id={game.idPartida}
                    posHost={game.posicaoClubeCasa}
                    posVis={game.posicaoClubeVisitante}
                    chanceHost={game.percentualVitoriaCasa}
                    chanceVis={game.percentualVitoriaVisitante}
                    stadium={game.local}
                    shieldHost={game.fotoClubeCasa}
                    shieldVis={game.fotoClubeVisitante}
                    lastScoresHost={game.aproveitamentoCasa.split(';') || []}
                    lastScoresVis={game.aproveitamentoFora.split(';') || []}
                    scoreHost={game.placarCasa}
                    scoreVist={game.placarVisitante}
                    date={game.partidaData.split(' ')[0].replace(/[-]/g, '/')}
                    hour={game.partidaData.split(' ')[1].substr(0, 5)}
                    currentRound={rodadaAtual}
                    round={game.rodada}
                    hostName={game.nomeClubeCasaAbrev}
                    visName={game.nomeClubeVisitanteAbrev}
                  />
                </Card>
              </Grid>
            ))
          : [1, 2, 3, 4, 5, 6, 7, 8, 9].map((e) => (
              <Grid key={e} item xs={12} sm={6} md={4}>
                <Box width="100%">
                  <Skeleton variant="rect" width="100%" height={150} />
                </Box>
              </Grid>
            ))}
      </Grid>
    </Wrapper>
  );
}

export default Games;
