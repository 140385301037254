import React from 'react';

import { CardWrapper, InnerCard } from './styles';

function NewsTeamCard({ selected, shield, name, onSelect }) {
  return (
    <InnerCard onClick={onSelect}>
      <CardWrapper selected={selected}>
        <img src={shield} alt={`${name} escudo`} />
        {/* <div /> */}
      </CardWrapper>
    </InnerCard>
  );
}

export default NewsTeamCard;
