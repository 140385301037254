import styled from 'styled-components';

export const Container = styled.div``;

export const TeamShield = styled.div``;

export const Stats = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr; */
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
  /* > div {

  } */
`;

export const Best = styled.div`
  display: grid;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
