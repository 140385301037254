import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Container,
} from '@material-ui/core';
import AdminTableContainer from '../../components/AdminTableContainer';
import AdminTableLoading from '../../components/AdminTableLoading';
import AdminInfoCard from '../../components/AdminInfoCard';
import ChartComponent from '../../components/ChartComponents/Chart';
import { getFinances } from '../../services/catimbaStats/plans';
import { formatPrice } from '../../utils/formatPrice';

export default function Adminfinance() {
  const [finances, setFinances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalUser, setTotalUser] = useState(0);
  const [setTotalIncome, setSetTotalIncome] = useState(0);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getFinances()
      .then((res) => {
        setFinances(res.data.planos);
        setTotalUser(res.data.totalUsuarios);
        setSetTotalIncome(res.data.valorTotal);

        const data = [['', 'Valor']];

        res.data.planos.forEach((plano) => {
          data.push([plano.nomePlano, plano.valorReceita]);
        });

        setChartData(data);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Container maxWidth="lg" style={{ marginBottom: 10 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} alignItems="center">
            <Grid container spacing={0} style={{ height: '100%' }}>
              <Grid item xs={12}>
                <AdminInfoCard title="Total de usuário" value={totalUser} />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 5 }}>
                <AdminInfoCard
                  title="Renda total"
                  value={formatPrice(setTotalIncome)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <ChartComponent
              title="Receita por plano"
              data={chartData}
              type="PieChart"
              legend
            />
          </Grid>
        </Grid>
      </Container>
      <AdminTableContainer title="Financeiro">
        <TableContainer>
          {!loading ? (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">ID</TableCell>
                  <TableCell>Plano</TableCell>
                  <TableCell align="right">Qtd. Sócios</TableCell>
                  <TableCell align="right">Receita</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {finances.map((finance) => (
                  <TableRow key={finance.idPlano}>
                    <TableCell size="small">{finance.idPlano}</TableCell>
                    <TableCell component="th" scope="finance">
                      {finance.nomePlano}
                    </TableCell>
                    <TableCell align="right">
                      {finance.qtdeAssinantes}
                    </TableCell>
                    <TableCell align="right">
                      R$
                      {finance.valorReceita &&
                        finance.valorReceita.toFixed(2).replace('.', ',')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <AdminTableLoading />
          )}
        </TableContainer>
      </AdminTableContainer>
    </>
  );
}
