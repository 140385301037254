import React, { useState, useEffect } from 'react';
import { Container, Grid, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ShortComparison from '../../components/GameComponents/ShortComparison';
import LastGamesTable from '../../components/GameComponents/LastGamesTable';
import GoalsStats from '../../components/GameComponents/GoalsStats';
import EscalationTable from '../../components/GameComponents/EscalationTable';
import { getGames } from '../../services/catimbaStats/games';
import ChartComponent from '../../components/ChartComponents/Chart';
import MuiTheme from '../../styles/MuiTheme';
import Loader from '../../components/Loader';

// import { Container } from './styles';

function GameInfo({ match }) {
  const history = useHistory();
  const [game, setGame] = useState();
  const mdDown = useMediaQuery(MuiTheme.breakpoints.down('md'));

  useEffect(() => {
    if (!match || !match.params || !match.params.id) {
      history.push('/jogos');
      return;
    }
    getGames(match.params.id).then((res) => setGame(res.data));
  }, [match, history]);

  function convertToChartData(avg1, avg2, club1, club2) {
    const data = [];
    data.push(['', club1, club2]);
    Object.keys(avg1).forEach((key) => {
      data.push([key.toUpperCase(), avg1[key], avg2[key]]);
    });
    return data;
  }

  return (
    <Container maxWidth="lg">
      {game ? (
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <ShortComparison
              hostName={game.clubeCasa.nome}
              hostShield={game.clubeCasa.foto}
              visShield={game.clubeVisitante.foto}
              visName={game.clubeVisitante.nome}
              probability={game.clubeCasa.probabilidades}
              minHeight={!mdDown ? '227px' : undefined}
              stadium={game.local}
              date={game.dataPartida.split(' ')[0].replace(/[-]/g, '/')}
              hour={game.dataPartida.split(' ')[1].substr(0, 5)}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <GoalsStats
              shield={game.clubeCasa.foto}
              name={game.clubeCasa.nome}
              asHostAvg={game.clubeCasa.mediaGolsMando}
              generalGoalsDifference={game.clubeCasa.saldoGolsGeral}
              generalAvg={game.clubeCasa.mediaGolsGeral}
              takenAsHost={game.clubeCasa.mediaGolsSofridosMando}
              takenGeneral={game.clubeCasa.mediaGolsSofridosGeral}
              position={game.clubeCasa.posicao}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <GoalsStats
              shield={game.clubeVisitante.foto}
              name={game.clubeVisitante.nome}
              asHostAvg={game.clubeVisitante.mediaGolsMando}
              generalGoalsDifference={game.clubeVisitante.saldoGolsGeral}
              generalAvg={game.clubeVisitante.mediaGolsGeral}
              takenAsHost={game.clubeVisitante.mediaGolsSofridosMando}
              takenGeneral={game.clubeVisitante.mediaGolsSofridosGeral}
              host={false}
              position={game.clubeVisitante.posicao}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <ChartComponent
              data={convertToChartData(
                game.clubeCasa.medias.conquistada,
                game.clubeVisitante.medias.cedida,
                game.clubeCasa.nome,
                game.clubeVisitante.nome
              )}
              title="Média por posição"
              subtitle={`Conquistada ${game.clubeCasa.nome} X Cedida ${game.clubeVisitante.nome}`}
              subtitles={[
                { color: '#158D49', subtitle: game.clubeCasa.nome },
                { color: '#e10000', subtitle: game.clubeVisitante.nome },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <ChartComponent
              data={convertToChartData(
                game.clubeVisitante.medias.conquistada,
                game.clubeCasa.medias.cedida,
                game.clubeVisitante.nome,
                game.clubeCasa.nome
              )}
              title="Média por posição"
              subtitle={`Conquistada ${game.clubeVisitante.nome} X Cedida ${game.clubeCasa.nome}`}
              subtitles={[
                { color: '#158D49', subtitle: game.clubeVisitante.nome },
                { color: '#e10000', subtitle: game.clubeCasa.nome },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <EscalationTable
              teamName={game.clubeCasa.nome}
              athletes={game.clubeCasa.escalacao}
              teamShield={game.clubeCasa.foto}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <EscalationTable
              teamName={game.clubeVisitante.nome}
              athletes={game.clubeVisitante.escalacao}
              teamShield={game.clubeVisitante.foto}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <LastGamesTable
              hostName={game.clubeCasa.nome}
              visName={game.clubeVisitante.nome}
              gamesHost={game.clubeCasa.jogos}
              gamesVis={game.clubeVisitante.jogos}
            />
          </Grid>
        </Grid>
      ) : (
        <Loader />
      )}
    </Container>
  );
}

export default GameInfo;
