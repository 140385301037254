import styled from 'styled-components';

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     marginTop: theme.spacing(8),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   avatar: {
//     height: 100,
//     width: 100,
//     paddingBottom: 10,
//   },
//   form: {
//     width: '100%', // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
// }));

export const Wrapper = styled.div`
  /* marginTop: theme.spacing(8), */
  display: flex;
  flex-direction: column;
  align-items: center;

  h5 {
    text-align: center;
  }
  img {
    height: 9.5rem;
    width: 9rem;
    padding-bottom: 1rem;
  }

  form {
    width: 100%;
    margin-top: 1rem;
  }
  button {
    margin: 3rem 0 2rem;
  }

  .MuiIconButton-root {
    margin: 0;
    padding: 0px;
  }
`;
