import React from 'react';

import { Container } from './styles';
import AdminSidebar from '../../../components/AdminSidebar';

export default function AdminLayout({ children, title }) {
  return (
    <Container>
      <AdminSidebar title={title}>{children}</AdminSidebar>
    </Container>
  );
}
