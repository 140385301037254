import styled from 'styled-components';

export const SubtitleColor = styled.div`
  height: 1.5rem;
  width: 2.5rem;
  border-radius: 3px;
  margin: 10px;
`;

export const SubtitleText = styled.text`
  color: #000;
  font-size: 1.2rem;
`;
