import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Wrapper = styled.div`
  /* background: ${colors.primary}; */
  height: max-content;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  overflow: auto;
`;
