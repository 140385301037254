import styled from 'styled-components';
import { Container as MuiContainer } from '@material-ui/core';

export const Container = styled(MuiContainer)`
  span {
    font-weight: bold !important;

    font-size: 1.4rem !important;
  }
`;

export const PlayersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  > div {
    flex: 1;
  }
`;

export const TableRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  border-bottom: 1px solid #e0e0e0;
  padding: 5px 0;
`;

export const InnerTableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 16px;
  align-items: center;
  text-align: center;
`;
