import styled from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Form } from 'formik';
import { Container as MuiContainer } from '@material-ui/core';

export const Container = styled(MuiContainer)`
  form {
    display: flex;
    flex-direction: column;
    width: 85vw;
    max-width: 50rem;

    button {
      margin-top: 1rem;
      align-self: center;
    }
  }
`;

export const InputGroup = styled.div`
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: column;

  margin-bottom: 2rem;
`;

export const Scroll = styled(PerfectScrollBar)`
  max-height: 70vh;
  padding: 5px 10px;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex: 1;
  flex-direction: column;
  > div.input-field {
    margin-bottom: 2.7rem;
  }
`;
