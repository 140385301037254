import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import history from '../../services/history';

import { GameContainer } from './styles';

function DashboardPlayerTable({ players, title }) {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: '6px 10px 6px 5px' }}>
              {title}
            </TableCell>
            <TableCell align="right" style={{ padding: '6px 5px 6px 0px' }}>
              média
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {players &&
            players.map((player) => (
              <TableRow
                key={player.id}
                onClick={() => history.push(`/atletas/${player.id}`)}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                <TableCell style={{ padding: '6px 10px 6px 5px' }}>
                  <GameContainer style={{ justifyContent: 'flex-start' }}>
                    <img
                        alt={`Escudo ${player.nome}`}
                        src={player.fotoClube}
                      />{' '}
                    {player.nome}
                  </GameContainer>
                </TableCell>
                <TableCell align="right" style={{ padding: '6px 5px 6px 0px' }}>
                  {player.media}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DashboardPlayerTable;
