import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */

  h3 {
    font-size: 1.6rem;
    line-height: 23px;
    color: #144b20;
    margin-bottom: 5px;
    ${({ reverse }) =>
      reverse
        ? css`
            text-align: right;
          `
        : css`
            text-align: left;
          `}
  }

  p {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 21px;
    color: #000000;

    & + p {
      font-size: 1.2rem;
      line-height: 20px;
      color: #979797;
    }
  }
  > div {
    margin-bottom: 5px;
    /* align-items: center; */

    ${({ reverse }) =>
      reverse
        ? css`
            text-align: right;

            h3 {
              text-align: right;
            }

            > div {
              &:last-child {
                align-items: flex-end;
              }
            }
          `
        : css`
            text-align: left;
            h3 {
              text-align: left;
            }

            > div {
              &:last-child {
                align-items: flex-start;
              }
            }
          `}
  }
  /* div {
    align-items: flex-start;
  } */

  /* div {
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
`;

export const Points = styled.div`
  display: flex;
  font-size: 1.6rem;
  flex-direction: column;

  span {
    font-size: 1.3rem;
  }

  ${({ reverse }) =>
    reverse
      ? css`
          align-items: flex-end;
          strong {
            margin-left: 10px;
          }
        `
      : css`
          align-items: flex-start;
          strong {
            margin-right: 10px;
          }
        `}
`;
