import React from 'react';
import Card from '../../Card';
import TeamData from '../TeamData';
import { Container, ValuesContainer, Item, Value, Number } from './styles';
import Points from '../../PlayerComponents/Points';

function EscalationInfoHeader({
  teamName,
  teamOwner,
  shield,
  points,
  price,
  appreciation,
}) {
  return (
    <Card>
      <Container>
        <TeamData shield={shield} teamName={teamName} teamOwner={teamOwner} />

        <ValuesContainer>
          <Item>
            <Value>Pontos </Value>
            <Number>
              <Points value={points} style={{ fontSize: '2.4rem' }}>
                {points && points.toFixed(2)}
              </Points>
            </Number>
          </Item>
          <Item>
            <Value>Preço </Value>
            <Number>C$ {price && price.toFixed(2)}</Number>
          </Item>

          <Item>
            <Value>Valorização </Value>
            <Number>
              <Points value={price} style={{ fontSize: '1.6rem' }}>
                C$ {(appreciation && appreciation.toFixed(2)) || 0}
              </Points>
            </Number>
          </Item>
        </ValuesContainer>
      </Container>
    </Card>
  );
}

export default EscalationInfoHeader;
