import styled from 'styled-components';
import { Container as MuiContainer } from '@material-ui/core';
import MuiTheme from '../../styles/MuiTheme';

export const Container = styled(MuiContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
  ${MuiTheme.breakpoints.up('md')} {
    margin: 10rem 0;
  }
`;
