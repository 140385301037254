import React, { useRef, useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import validarCpf from 'validar-cpf';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import logo from '../../../assets/logo.png';
import { getAdressByCep } from '../../../services/viaCep';
import { register, updateProfile } from '../../../services/catimbaStats/auth';
import GoBackAndHome from '../../../components/GoBackAndHome';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    height: 100,
    width: 95,
    paddingBottom: 10,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  button: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default function SignUp() {
  const formRef = useRef(null);
  const classes = useStyles();
  const location = useLocation();
  const [showPassword, setshowPassword] = useState(false);

  const [cpfError, setCpfError] = useState('');
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.auth.profile);

  // TODO: adicionar busca do usuário

  const schema = Yup.object().shape({
    firstName:
      location.pathname !== '/alterar-cadastro'
        ? Yup.string().required('Nome não pode estar vazio')
        : Yup.string(),
    lastName:
      location.pathname !== '/alterar-cadastro'
        ? Yup.string().required('Sobrenome não pode estar vazio')
        : Yup.string(),
    cpf: Yup.string().required('CPF não pode estar vazio'),
    celphone: Yup.string().required('Telefone não pode estar vazio'),
    cep: Yup.string().required('CEP não pode estar vazio'),
    state: Yup.string().required('Estado não pode estar vazio'),
    city: Yup.string().required('Cidade não pode estar vazio'),
    neighborhood: Yup.string().required('Bairro não pode estar vazio'),
    street: Yup.string().required('Rua não pode estar vazio'),
    number: Yup.string().required('Número não pode estar vazio'),
    complement: Yup.string(),
    email: Yup.string()
      .email('Insira um e-mail válido')
      .required('E-mail não pode estar vazio'),
    confirmEmail:
      location.pathname !== '/alterar-cadastro'
        ? Yup.string()
            .oneOf([Yup.ref('email'), undefined], 'Os emails devem ser iguais')
            .required('Confirmação de email é obrigatório')
        : Yup.string(),
    password: Yup.string()
      .min(6, 'No mínimo 6 digitos')
      .required('Senha não pode estar vazia'),
    confirmPassword:
      location.pathname !== '/alterar-cadastro'
        ? Yup.string()
            .oneOf(
              [Yup.ref('password'), undefined],
              'As senhas devem ser iguais'
            )
            .required('Confirmação de senha é obrigatório')
        : Yup.string(),
  });

  const handleSubmit = useCallback(
    async ({
      firstName,
      lastName,
      cpf,
      celphone,
      cep,
      state,
      city,
      neighborhood,
      street,
      number,
      complement,
      email,
      password,
    }) => {
      if (validarCpf(cpf.replace(/[^0-9]/g, ''))) {
        setLoading(true);
        if (location.pathname === '/alterar-cadastro' && user) {
          await updateProfile({
            id: user.id,
            nome: firstName,
            email,
            senha: password,
            cpf,
            celular: celphone,
            cep,
            rua: street,
            numero: number,
            complemento: complement,
            bairro: neighborhood,
            cidade: city,
            uf: state,
          }).finally(() => {
            setLoading(false);
          });
          return;
        }
        await register({
          nome: `${firstName} ${lastName}`,
          email,
          senha: password,
          cpf,
          celular: celphone,
          cep,
          rua: street,
          numero: number,
          complemento: complement,
          bairro: neighborhood,
          cidade: city,
          uf: state,
        }).finally(() => {
          setLoading(false);
        });
        return;
      }
      setCpfError('Seu CPF está incorreto');
    },
    [location, user]
  );

  const handleCepChange = useCallback((cep, setFieldValue, setFieldError) => {
    setFieldValue('cep', cep);
    if (cep.length >= 9) {
      return getAdressByCep(cep.replace('-', ''))
        .then((res) => {
          if (res.data && !res.data.erro) {
            setFieldValue('street', res.data.logradouro);
            setFieldValue('neighborhood', res.data.bairro);
            setFieldValue('city', res.data.localidade);
            setFieldValue('state', res.data.uf);
            setFieldValue('complement', res.data.complemento);
            return;
          }
          setFieldError('cep', 'Seu CEP está inválido');
        })
        .then(() => {
          setFieldError('cep', 'Ocorreu um erro ao buscar seu cep');
        });
    }
  }, []);

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="md">
      <div className={classes.paper}>
        {location.pathname !== '/alterar-cadastro' && (
          <>
            {/* <GoBackAndHome className={classes.button} /> */}

            <Link to="/">
            <img
              src={logo}
              alt="Logo do Catimba Stats"
              className={classes.avatar}
            />
            </Link>
            <Typography variant="h5">Crie sua conta</Typography>
          </>
        )}
        <Formik
          initialValues={{
            firstName: user && user.nome ? user.nome : '',
            lastName: '',
            cpf: user && user.cpf ? user.cpf : '',
            celphone: user && user.celular ? user.celular : '',
            cep: user && user.cep ? user.cep : '',
            state: user && user.uf ? user.uf : '',
            city: user && user.cidade ? user.cidade : '',
            neighborhood: user && user.bairro ? user.bairro : '',
            street: user && user.rua ? user.rua : '',
            number: user && user.numero ? user.numero : '',
            complement: user && user.complemento ? user.complemento : '',
            email: user && user.email ? user.email : '',
            confirmEmail: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            setFieldValue,
            setFieldError,
            handleChange,
          }) => (
            <Form className={classes.form} ref={formRef}>
              <Grid container spacing={2}>
                {location.pathname === '/alterar-cadastro' ? (
                  <Grid item xs={12}>
                    <Field
                      name="firstName"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          autoComplete="fname"
                          variant="outlined"
                          required
                          fullWidth
                          id="firstName"
                          label="Nome"
                          autoFocus
                          error={errors.firstName && touched.firstName}
                          helperText={touched.firstName && errors.firstName}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="firstName"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            autoComplete="fname"
                            variant="outlined"
                            required
                            fullWidth
                            id="firstName"
                            label="Nome"
                            autoFocus
                            error={errors.firstName && touched.firstName}
                            helperText={touched.firstName && errors.firstName}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="lastName"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            required
                            fullWidth
                            id="lastName"
                            label="Sobrenome"
                            autoComplete="lname"
                            error={errors.lastName && touched.lastName}
                            helperText={touched.lastName && errors.lastName}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6}>
                  <Field
                    name="cpf"
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        mask="999.999.999-99"
                        onChange={(event) => {
                          handleChange(event);
                          setCpfError('');
                          if (
                            event.target.value.replace(/[^0-9]/g, '').length ===
                              11 &&
                            !validarCpf(
                              event.target.value.replace(/[^0-9]/g, '')
                            )
                          ) {
                            setFieldError('cpf', 'Seu CPF está inválido');
                            setCpfError('Seu CPF está inválido');
                          }
                        }}
                      >
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="cpf"
                          label="CPF"
                          error={
                            (errors.cpf && touched.cpf) ||
                            (touched.cpf && cpfError)
                          }
                          helperText={
                            (touched.cpf && errors.cpf) ||
                            (touched.cpf && cpfError)
                          }
                        />
                      </InputMask>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="celphone"
                    render={({ field }) => (
                      <InputMask {...field} mask="(99) 99999-9999">
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="celphone"
                          label="Celular"
                          autoComplete="lname"
                          error={touched.celphone && errors.celphone}
                          helperText={touched.celphone && errors.celphone}
                        />
                      </InputMask>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Field
                    name="cep"
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        mask="99999-999"
                        onChange={(e) =>
                          handleCepChange(
                            e.target.value,
                            setFieldValue,
                            setFieldError
                          )
                        }
                      >
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="cep"
                          label="CEP"
                          error={touched.cep && errors.cep}
                          helperText={touched.cep && errors.cep}
                        />
                      </InputMask>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Field
                    name="state"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        required
                        fullWidth
                        id="state"
                        label="Estado"
                        autoComplete="lname"
                        error={touched.state && errors.state}
                        helperText={touched.state && errors.state}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="city"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        required
                        fullWidth
                        id="city"
                        label="Cidade"
                        autoComplete="lname"
                        error={touched.city && errors.city}
                        helperText={touched.city && errors.city}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Field
                    name="neighborhood"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        required
                        fullWidth
                        id="neighborhood"
                        label="Bairro"
                        autoComplete="lname"
                        error={touched.neighborhood && errors.neighborhood}
                        helperText={touched.neighborhood && errors.neighborhood}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Field
                    name="street"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        required
                        fullWidth
                        id="street"
                        label="Rua"
                        autoComplete="lname"
                        error={touched.street && errors.street}
                        helperText={touched.street && errors.street}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Field
                    name="number"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        required
                        fullWidth
                        id="number"
                        label="Número"
                        autoComplete="lname"
                        error={touched.number && errors.number}
                        helperText={touched.number && errors.number}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="complement"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        fullWidth
                        id="complement"
                        label="Complemento"
                        autoComplete="lname"
                        error={touched.complement && errors.complement}
                        helperText={touched.complement && errors.complement}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="email"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        autoComplete="email"
                        disabled={location.pathname === '/alterar-cadastro'}
                        error={touched.email && errors.email}
                        helperText={touched.email && errors.email}
                      />
                    )}
                  />
                </Grid>

                {location.pathname === '/alterar-cadastro' ? (
                  <Grid item xs={12}>
                    <Field
                      name="password"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          label="Senha atual"
                          type={showPassword ? 'text' : 'password'}
                          id="password"
                          autoComplete="current-password"
                          error={errors.password && touched.password}
                          helperText={touched.password && errors.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {!showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Field
                        name="confirmEmail"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            required
                            fullWidth
                            id="emailconfirm"
                            label="Confirme seu email"
                            autoComplete="email"
                            error={touched.confirmEmail && errors.confirmEmail}
                            helperText={
                              touched.confirmEmail && errors.confirmEmail
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="password"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Senha"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            error={errors.password && touched.password}
                            helperText={touched.password && errors.password}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment>
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {!showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="confirmPassword"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Senha atual"
                            type={showPassword ? 'text' : 'password'}
                            id="confirmPassword"
                            error={
                              errors.confirmPassword && touched.confirmPassword
                            }
                            helperText={
                              touched.confirmPassword && errors.confirmPassword
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment>
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {!showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
              >
                {location.pathname === '/alterar-cadastro'
                  ? 'Alterar cadastro'
                  : 'Criar conta'}
              </Button>
              {location.pathname !== '/alterar-cadastro' && (
                <Grid container justify="flex-end">
                  <Grid item>
                    <Link to="/login">
                      <Typography color="primary" variant="body2">
                        Já tem conta? Login
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
