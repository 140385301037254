import styled from 'styled-components';

export const Action = styled.div`
  font-size: 2.1rem;
  display: flex;
  justify-content: flex-end;

  svg {
    cursor: pointer;

    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;
