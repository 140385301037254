import styled from 'styled-components';

export const Container = styled.div`
  > h5 {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
`;

export const SpotlightPlayers = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  word-wrap: break-word;
  /* word-break: break-all; */
  /* text-align: center; */
  row-gap: 10px;
`;
