import styled from 'styled-components';

export const DropdownTop = styled.div`
  font-size: 1.35rem;
  color: #a4a6b3;
  text-decoration: none;
  padding: 1rem 2.7rem 1rem 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  &.selected {
    background: #9fa2b4;
    border-left: 2px solid #dde2ff;
    color: #dde2fe;
  }

  svg {
    font-size: 2rem;
    margin-right: 2rem;
    min-width: 2rem;
    &.arrow {
      margin-left: auto;
      margin-right: 0;
    }
  }
`;

export const DropdownContainer = styled.div`
  a {
    padding-left: 3rem;
    background: #444857;
  }
`;
