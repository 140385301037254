/* eslint-disable react/prop-types */
import React from 'react';

import {
  TextField,
  MenuItem,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
} from '@material-ui/core';

import { Field, Form as FormikForm } from 'formik';
import AdminForm from '../../../components/AdminForm';
import globalConst from '../../../utils/globalConst';

export default function Form({
  readOnly,
  method = null,
  create,
  update,
  loading,
}) {
  async function handleSubmit(values) {
    const data = (() => ({
      ...values,
      status: Number(values.status),
    }))();

    if (method) {
      update({ ...data, id: method.id });
    } else {
      create(data);
    }
  }
  return (
    <AdminForm
      initialValues={{
        nome: method ? method.nome : '',
        tipo: method ? method.tipo : '',
        status: method ? method.status : '',
        percentualDesconto: method ? method.percentualDesconto : 0,
        key: method ? method.key : '',
        token: method ? method.token : '',
        urlCallBack: method ? method.urlCallBack : '',
      }}
      handleSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <FormikForm>
          <Field
            disabled={readOnly || loading}
            margin="normal"
            required
            id="nome"
            label="Nome"
            name="nome"
            autoFocus
            as={TextField}
          />
          <TextField
            disabled={readOnly || loading}
            margin="normal"
            select
            required
            id="tipo"
            label="Tipo"
            name="tipo"
            value={values.tipo}
            onChange={(event) => {
              setFieldValue('tipo', event.target.value);
            }}
          >
            {globalConst.paymentMethodType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Field
            disabled={readOnly || loading}
            margin="normal"
            number
            label="Percetual de desconto"
            id="percentualDesconto"
            name="percentualDesconto"
            as={TextField}
          />
          <Field
            disabled={readOnly || loading}
            margin="normal"
            label="Key"
            id="key"
            name="key"
            as={TextField}
          />
          <Field
            disabled={readOnly || loading}
            margin="normal"
            label="Token"
            id="token"
            name="token"
            as={TextField}
          />
          <Field
            disabled={readOnly || loading}
            margin="normal"
            label="URL Callback"
            id="urlCallBack"
            name="urlCallBack"
            as={TextField}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">Status</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Field
                    name="status"
                    disabled={readOnly || loading}
                    color="primary"
                    component={Switch}
                    checked={Boolean(values.status)}
                    onChange={() => setFieldValue('status', !values.status)}
                  />
                }
                label={values.status ? 'Habilitado' : 'Desabilitado'}
              />
            </FormGroup>
          </FormControl>

          {!readOnly && (
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              type="submit"
            >
              Salvar
            </Button>
          )}
        </FormikForm>
      )}
    </AdminForm>
  );
}
