import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Score = styled.span`
  margin: 3px;
  font-size: 1.6rem;
`;

export const TeamLogo = styled.img`
  height: 20px;
`;

export const Cross = styled.span`
  margin: 3px;
`;
