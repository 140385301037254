import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Home from '../pages/Home';
import SignIn from '../pages/Access/SignIn';
import SignUp from '../pages/Access/SignUp';
import AccountCode from '../pages/Access/AccountCode';
import ForgotPassword from '../pages/Access/ForgotPassword';
import PasswordRecover from '../pages/Access/PasswordRecover';
import NewPassword from '../pages/Access/NewPassword';
import Games from '../pages/Games';
import Players from '../pages/Players';
import PlayerAim from '../pages/PlayerAim';
import GameInfo from '../pages/GameInfo';
import PlayerStats from '../pages/PlayerStats';
import Appreciation from '../pages/Appreciation';
import MyTeams from '../pages/MyTeams';
import MyTeamStats from '../pages/MyTeamStats';
import BasicAverage from '../pages/BasicAvarage';
import ScoutsComparison from '../pages/ScoutsComparison';
import GivenPoints from '../pages/GivenPoints';
import EscalatedList from '../pages/EscalatedList';
import ProsVSConcededGoals from '../pages/ProsVSConcededGoals';
import AdminPlan from '../pages/AdminPlan';
import AdminPaymentMethods from '../pages/AdminPaymentMethods';
import AdminUsers from '../pages/AdminUsers';
import TeamStats from '../pages/TeamStats';
import AccountConfirmation from '../pages/Access/AccountConfirmation';
import TeamNews from '../pages/TeamNews';
import UpdatePassword from '../pages/Access/UpdatePassword';
import PlansAndPrices from '../pages/PlansAndPrices';
import Adminfinance from '../pages/AdminFinances';
import AdminCoupon from '../pages/AdminCoupon';
import MyPayments from '../pages/MyPayments';
import MyTeamEscalation from '../pages/MyTeamEscalation';
import AdminCouponReport from '../pages/AdminCouponReport';
import AdminBlogPost from '../pages/AdminBlogPost';
import TermsAndConditions from '../pages/TermsAndConditions';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import { useTracking } from '../utils/useTracking';
import RealTeamDetails from '../pages/RealTeamDetails';
import ClubRanking from '../pages/ClubRanking';
import Dashboard from '../pages/Dashboard';

export default function Routes() {
  useTracking();
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route
        path="/planos-e-precos"
        exact
        component={PlansAndPrices}
        title="Planos e Preços"
      />

      <Route
        path="/jogos"
        exact
        component={Games}
        isPrivate
        title="Jogos da rodada"
      />

      <Route
        path="/jogos/:id"
        exact
        component={GameInfo}
        isPrivate
        title="Detalhes da partida"
      />
      <Route
        path="/atletas"
        exact
        component={Players}
        isPrivate
        title="Atletas"
      />
      <Route
        path="/atletas/:id"
        exact
        component={PlayerStats}
        isPrivate
        title="Detalhes do Jogador"
      />
      <Route
        path="/pontaria"
        exact
        component={PlayerAim}
        isPrivate
        title="Pontaria dos Jogadores"
      />
      <Route
        path="/variacao"
        exact
        component={Appreciation}
        isPrivate
        title="Mínimo Valorização"
      />
      <Route
        path="/media-basica"
        exact
        component={BasicAverage}
        isPrivate
        title="Média básica"
      />
      <Route
        path="/cruzamento-scouts"
        exact
        component={ScoutsComparison}
        isPrivate
        title="Cruzamento de scouts"
      />

      <Route
        path="/dashboard"
        exact
        component={Dashboard}
        isPrivate
        title="Dashboard"
      />

      <Route
        path="/meus-times"
        exact
        component={MyTeams}
        isPrivate
        title="Desempenho"
      />
      <Route
        path="/meus-times/:id"
        exact
        component={MyTeamStats}
        isPrivate
        title="Desempenho do time"
      />
      <Route
        path="/meus-times/:id/escalacao"
        exact
        component={MyTeamEscalation}
        isPrivate
        title="Escalação"
      />
      <Route
        path="/pontos-cedidos"
        exact
        component={GivenPoints}
        isPrivate
        title="Pontos cedidos"
      />
      <Route
        path="/mais-escalados"
        exact
        component={EscalatedList}
        isPrivate
        title="Mais Escalados"
      />
      <Route
        path="/gols-pros-e-sofridos"
        exact
        component={ProsVSConcededGoals}
        isPrivate
        title="Gols prós x sofridos"
      />

      <Route
        path="/detalhes-time/:id"
        exact
        component={TeamStats}
        isPrivate
        title="Detalhes do time"
      />
      <Route
        path="/noticias"
        exact
        component={TeamNews}
        isPrivate
        title="Notícias"
      />
      <Route
        path="/ranking-clubes"
        exact
        component={ClubRanking}
        isPrivate
        title="Ranking de clubes"
      />
      <Route
        path="/meus-pagamentos"
        exact
        component={MyPayments}
        isPrivate
        title=""
      />
      <Route
        path="/clube/:id"
        exact
        component={RealTeamDetails}
        isPrivate
        title="Detalhes do clube"
      />
      <Route
        path="/alterar-cadastro"
        exact
        isPrivate
        component={SignUp}
        title="Alterar cadastro"
      />
      <Route
        path="/alterar-senha"
        exact
        isPrivate
        component={UpdatePassword}
        title="Alterar senha"
      />

      <Route
        path="/usuarios"
        exact
        component={AdminUsers}
        isAdmin
        isPrivate
        title=""
      />
      <Route
        path="/usuarios/:id/pagamentos"
        exact
        component={MyPayments}
        isAdmin
        isPrivate
        title=""
      />
      <Route
        path="/planos"
        exact
        component={AdminPlan}
        isAdmin
        isPrivate
        title=""
      />
      <Route
        path="/meios-de-pagamento"
        exact
        component={AdminPaymentMethods}
        isAdmin
        isPrivate
        title=""
      />
      <Route
        path="/financeiro"
        exact
        component={Adminfinance}
        isAdmin
        isPrivate
        title=""
      />
      <Route
        path="/cupom"
        exact
        component={AdminCoupon}
        isAdmin
        isPrivate
        title=""
      />
      <Route
        path="/relatorio-cupom"
        exact
        component={AdminCouponReport}
        isAdmin
        isPrivate
        title=""
      />
      <Route
        path="/editar-dicas"
        exact
        component={AdminBlogPost}
        isAdmin
        isPrivate
        title=""
      />
      <Route
        isAuth
        path="/login"
        exact
        component={SignIn}
        title="Fazer Login"
      />
      <Route
        path="/cadastro"
        exact
        component={SignUp}
        isAuth
        title="Fazer Cadastro"
      />
      <Route path="/termos-e-condicoes" exact component={TermsAndConditions} />
      <Route path="/politica-de-privacidade" exact component={PrivacyPolicy} />
      <Route isAuth path="/esqueci" component={ForgotPassword} />
      <Route isAuth path="/recuperar" component={PasswordRecover} />
      <Route isAuth path="/nova-senha" component={NewPassword} />
      <Route isAuth path="/receber-codigo" component={AccountCode} />
      <Route isAuth path="/confirmacao" component={AccountConfirmation} />
    </Switch>
  );
}
