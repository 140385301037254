import React from 'react';
import HSBar from 'react-horizontal-stacked-bar-chart';
import { useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PlayerInfo from '../PlayerInfo';
import Card from '../../Card';

import { GridContaier, PlayerStats, InnerContainer } from './styles';
import MuiTheme from '../../../styles/MuiTheme';

function PlayerAim({
  goals,
  aim,
  hit,
  error,
  picture,
  playerName,
  position,
  teamShield,
  playerId,
}) {
  const desktop = useMediaQuery(MuiTheme.breakpoints.up('xs'));
  const history = useHistory();

  return (
    <Card
      style={{ cursor: 'pointer' }}
      onClick={() => history.push(`atletas/${playerId}`)}
    >
      <InnerContainer>
        <PlayerInfo
          picture={picture}
          playerName={playerName}
          horizontal={desktop}
          position={position}
          teamShield={teamShield}
        />
        <PlayerStats>
          <div>
            <p>Gols</p>
            <p>{goals}</p>
          </div>
          <div>
            <p style={{ color: '#27AE60' }}>A</p>
            <p style={{ color: '#27AE60' }}>{hit}</p>
          </div>
          <div>
            <p style={{ color: '#EB5757' }}>E</p>
            <p style={{ color: '#EB5757' }}>{error}</p>
          </div>
        </PlayerStats>
      </InnerContainer>
      <GridContaier>
        <HSBar
          showTextUp
          id="hsbarAim"
          data={[
            {
              value: aim,
              description: `${aim}%`,
              color: '#02A0FC',
            },
            { value: 100 - aim, description: ' ', color: '#CCF8FE' },
          ]}
        />
        <p>Pontaria</p>
      </GridContaier>
    </Card>
  );
}

export default PlayerAim;
