import React from 'react';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import CancelIcon from '@material-ui/icons/Cancel';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import { useHistory } from 'react-router-dom';
import { Container, InfoContainer, TooltipContainer } from './styles';
import Card from '../../Card';
import NameInfo from '../../StatisticsComponents/NumberInfo';
import TeamData from '../TeamData';
import AdminActionButtons from '../../AdminActionButtons';

function TeamInfo({
  myTeams,
  realTeam,
  teamShield,
  teamName,
  madeGoals,
  takenGoals,
  games,
  goalsDifferent,
  teamOwner,
  teamId,
  onDelete,
}) {
  const history = useHistory();
  return (
    <Card>
      <Container>
        <TeamData
          shield={teamShield}
          teamName={teamName}
          teamOwner={teamOwner}
        />
        {myTeams && (
          <TooltipContainer>
            <AdminActionButtons
              title="Estatíticas"
              onClick={() => history.push(`/meus-times/${teamId}`)}
            >
              <EqualizerIcon />
            </AdminActionButtons>
            <AdminActionButtons
              title="Escalação"
              onClick={() => history.push(`/meus-times/${teamId}/escalacao`)}
            >
              <FormatListBulletedIcon />
            </AdminActionButtons>
            {/* <AdminActionButtons title="Parciais">
              <SportsSoccerIcon />
            </AdminActionButtons> */}

            <AdminActionButtons title="Excluir time" onClick={onDelete}>
              <CancelIcon />
            </AdminActionButtons>
          </TooltipContainer>
        )}
        {realTeam && (
          <InfoContainer>
            <NameInfo title="GP" value={madeGoals} />
            <NameInfo title="GC" value={takenGoals} />
            <NameInfo title="J" value={games} />
            <NameInfo isColored title="SG" value={goalsDifferent} />
          </InfoContainer>
        )}
      </Container>
    </Card>
  );
}

export default TeamInfo;
