import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Pill = styled(Paper)`
  width: ${({ width }) => width || '40px'};
  padding: 3px;
  text-align: center;

  &.MuiPaper-rounded {
    border-radius: 50px;
  }

  span {
    color: ${({ negative, color }) =>
      negative ? color || 'red' : color || 'green'};
    font-weight: 500;
    font-size: 1rem;
  }
`;
