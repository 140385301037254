import produce from 'immer';

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  admin: false,
  profile: null,
  member: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SAVE_USER_SUCCESS': {
        draft.token = action.payload.user.token;
        draft.profile = action.payload.user;
        draft.member = !!action.payload.user.assinante;
        draft.signed = true;
        if (action.payload.user.perfil < 4) {
          draft.admin = true;
        }
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.signed = false;
        draft.loading = false;
        draft.token = null;
        draft.admin = false;
        draft.profile = null;

        break;
      }

      case '@auth/TURN_MEMBER': {
        draft.member = true;

        break;
      }

      case '@auth/UPDATE_USER': {
        draft.profile = action.payload.user;
        draft.member = !!action.payload.user.assinante;
        break;
      }

      default:
    }
  });
}
