import React from 'react';

import { Container, Points } from './styles';
import PlayerInfo from '../../PlayerComponents/PlayerInfo';

function HighlightPlayer({
  title,
  description,
  value,
  reverse,
  playerName,
  picture,
  position,
}) {
  return (
    <Container reverse={reverse}>
      <h3>{title}</h3>
      <PlayerInfo
        horizontal
        size="small"
        picture={picture}
        playerName={playerName}
        position={position}
        alignRight={reverse}
      />
      <Points reverse={reverse}>
        <strong>{value}</strong>
        <span>{description}</span>
      </Points>
    </Container>
  );
}

export default HighlightPlayer;
