import colors from './colors';

export default {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#000',
    background: state.isSelected ? colors.primary : '#fff',
    fontSize: '1.6rem',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  control: (provided) => ({
    ...provided,
    background: colors.primary,
    fontSize: '1.6rem',
    color: '#fff',
    border: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  dropdownIndicator: () => ({
    color: '#fff',
    display: 'flex',
    padding: '8px',
    transition: 'color 150ms',
    boxSizing: 'border-box',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};
