import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Box, Avatar, CardActionArea } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    textDecoration: 'none',
    margin: '0 auto',
  },
  large: {
    width: 150,
    height: 150,
  },
});

export default function TestimonialCards({ avatar, name, type, text, link }) {
  const classes = useStyles();
  return (
    <Card className={classes.root} elevation={3}>
      <CardActionArea>
        <a
          href={link}
          target="blank"
          style={{ textDecoration: 'none', color: 'unset' }}
        >
          <Box
            height="200px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Avatar alt="Avatar" src={avatar} className={classes.large} />
          </Box>
          <CardContent>
            <Typography variant="h5" component="h2" align="center">
              {name}
            </Typography>
            <Typography gutterBottom variant="subtitle1" align="center">
              {type}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              align="justify"
            >
              {text ||
                `O Lorem Ipsum é um texto modelo da indústria tipográfica e de
          impressão. O Lorem Ipsum tem vindo a ser o texto padrão usado por
          estas indústrias desde o ano de 1500`}
            </Typography>
          </CardContent>
        </a>
      </CardActionArea>
    </Card>
  );
}
