import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Container,
} from '@material-ui/core';
import AdminTableContainer from '../../components/AdminTableContainer';
import AdminTableLoading from '../../components/AdminTableLoading';
import ChartComponent from '../../components/ChartComponents/Chart';
import { formatPrice } from '../../utils/formatPrice';
import { couponReport } from '../../services/catimbaStats/adminCoupon';

export default function AdminCouponReport() {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setLoading(true);
    couponReport()
      .then((res) => {
        setReports(res.data);

        const data = [['', 'Receita']];

        res.data.forEach((plano) => {
          data.push([plano.nome, plano.receita]);
        });

        setChartData(data);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Container maxWidth="lg" style={{ marginBottom: 10 }}>
        <Grid container justify="flex-end">
          <Grid item xs={12}>
            <ChartComponent title="Receita por Cupom" data={chartData} />
          </Grid>
        </Grid>
      </Container>
      <AdminTableContainer title="Relatório de cupons">
        <TableContainer>
          {!loading ? (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">ID</TableCell>
                  <TableCell>Plano</TableCell>
                  <TableCell align="right">Qtd. Sócios</TableCell>
                  <TableCell align="right">Receita</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports.map((report) => (
                  <TableRow key={report.id}>
                    <TableCell size="small">{report.id}</TableCell>
                    <TableCell component="th" scope="finance">
                      {report.nome}
                    </TableCell>
                    <TableCell align="right">{report.qtdSocios}</TableCell>
                    <TableCell align="right">
                      {formatPrice(report.receita)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <AdminTableLoading />
          )}
        </TableContainer>
      </AdminTableContainer>
    </>
  );
}
