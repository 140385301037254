import React, { useState, useEffect } from 'react';

import { Grid, Box, Container } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { getMostEscaled } from '../../services/catimbaStats/statistics';
import PlayerOneStat from '../../components/PlayerComponents/PlayerOneStat';
import { formatNumber } from '../../utils/formatPrice';

import NoResults from '../../components/NoResults';

function EscalatedList() {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getMostEscaled()
      .then((res) => {
        setPlayers(res.data);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={1}>
        {!loading && !players.length && (
          <Grid item xs={12}>
            <NoResults />
          </Grid>
        )}
        {!loading
          ? players &&
            players.map((player) => (
              <Grid item xs={12}>
                <PlayerOneStat
                  playerId={player.Atleta.atleta_id}
                  playerName={player.Atleta.apelido}
                  picture={player.Atleta.foto}
                  position={player.posicao}
                  teamShield={player.escudo_clube}
                  teamName={player.clube_nome}
                  title="Qtde escalações"
                  status={formatNumber(player.escalacoes)}
                  horizontal
                />
              </Grid>
            ))
          : [1, 2, 3, 4, 5, 6].map((e) => (
              <Grid key={e} item xs={12}>
                <Box width="100%">
                  <Skeleton variant="rect" width="100%" height={100} />
                </Box>
              </Grid>
            ))}

        {players && !!players.length && (
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 10,
              marginBottom: 10,
            }}
          />
        )}
      </Grid>
    </Container>
  );
}

export default EscalatedList;
