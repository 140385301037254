import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SmallComparison from '../../GameComponents/SmallComparison';
import TableWrapper from '../../TableWrapper';
import ScoutPill from '../ScoutPill';
import scoutsToArrayConverter from '../../../utils/scoutsToArrayConverter';
import {
  ScoutsContainer,
  Wrapper,
  PointsContainer,
  GameContainer,
} from './styles';
import Points from '../Points';

export default function PlayerLastGames({ title, data = [] }) {
  return (
    <Wrapper>
      <TableWrapper title={title}>
        <Table aria-label="simple table">
          <TableHead />
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.rodada}>
                {/* <TableCell size="small" component="th" scope="row" align="left">

                </TableCell> */}
                <TableCell size="small" align="left">
                  <GameContainer>
                    <SmallComparison
                      hostScore={row.placarClubeCasa}
                      visScore={row.placarClubeVisitante}
                      hostShield={row.fotoClubeCasa}
                      visShield={row.fotoClubeVisitante}
                    />
                    <ScoutsContainer>
                      {scoutsToArrayConverter(row.scoutsPositivo).map(
                        (scout) => (
                          <ScoutPill key={scout}>{scout}</ScoutPill>
                        )
                      )}
                      {scoutsToArrayConverter(row.scoutsNegativo).map(
                        (scout) => (
                          <ScoutPill key={scout} negative>
                            {scout}
                          </ScoutPill>
                        )
                      )}
                    </ScoutsContainer>
                  </GameContainer>
                </TableCell>
                <TableCell size="small" align="right">
                  <PointsContainer>
                    <span>Rodada# {row.rodada}</span>
                    <Points value={row.pontos}>{row.pontos}</Points>
                  </PointsContainer>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </Wrapper>
  );
}
