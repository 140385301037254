import React from 'react';
import { Modal } from '@material-ui/core';
import PlayerComparison from '../PlayerComparison';

// import { Container } from './styles';

function PlayerModal({ open = false, onClose, playerA, playerB }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PlayerComparison
        playerA={playerA}
        playerB={playerB}
        onCloseClick={onClose}
      />
    </Modal>
  );
}

export default PlayerModal;
