import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const CardContainer = styled.div`
  width: 100%;
  border-radius: 5px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  background: ${(props) => props.color || '#fff'};
`;

export const Title = styled.text`
  color: ${(props) => props.color};
  font-weight: 500;
  font-size: ${(props) => (props.spotligth && '2.4rem') || '2rem'};
  line-height: 4.2rem;
  text-align: center;
  /* padding: ${(props) => props.spotligth && '2rem'}; */
`;

export const ImgContainer = styled.img`
  /* height: ${(props) => (props.spotligth && '85px') || '75px'};
  width: ${(props) => (props.spotligth && '85px') || '75px'}; */

`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const ButtonContainer = styled.div`
  padding: 40px 0 20px;
`;

export const PlanButton = styled(Button)`
  background: ${(props) => props.color || '#fff'};
`;
