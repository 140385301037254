import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

// import { Container } from './styles';

function Loader() {
  return (
    <Box
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      display="flex"
      position="fixed"
      top={0}
      left={0}
      zIndex={0}
    >
      <CircularProgress size={50} />
    </Box>
  );
}

export default Loader;
