import styled from 'styled-components';

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    height: 3rem;
    margin-right: 1rem;
  }
`;
