export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export function formatNumber(price) {
  if (price) {
    price = price ? price.toFixed(2).split('.') : 0;
    price = `${price[0].split(/(?=(?:...)*$)/).join('.')}`;
    return price;
  }
  return '0';
}

