import React from 'react';

import { useHistory } from 'react-router-dom';
import Card from '../../Card';
import PlayerInfo from '../PlayerInfo';
import NumberInfo from '../../StatisticsComponents/NumberInfo';

import { Container, PlayerContainer, StatusContainer } from './styles';

function PlayerOneStat({
  playerId,
  playerName,
  picture,
  position,
  teamName,
  card = false,
  teamShield,
  statusPlayer,
  title,
  status,
  horizontal,
}) {
  const history = useHistory();
  return (
    <Container onClick={() => history.push(`atletas/${playerId}`)}>
      <Card>
        <PlayerContainer card={card}>
          <div className="scouts">
            <PlayerInfo
              playerId={playerId}
              playerName={playerName}
              picture={picture}
              position={position}
              teamShield={teamShield}
              teamName={teamName}
              statusPlayer={statusPlayer}
              horizontal={horizontal}
            />
          </div>
          <StatusContainer>
            <NumberInfo title={title} value={status} />
          </StatusContainer>
        </PlayerContainer>
      </Card>
    </Container>
  );
}

export default PlayerOneStat;
