import api from './_api';

const base = 'usuario';

export function getUser(id) {
  return api.get(`${base}/${id}`);
}

export function getUserPayments(id, { page }) {
  return api.get(`${base}/${id}/pagamentos`, { page, size: 20 });
}

export function getUserTeams(id, { page, size = 50 }) {
  return api.get(`${base}/${id}/times`, { page, size });
}

export function addTeam({
  teamId,
  userId,
  cartola,
  escudo,
  camisa,
  nome,
  slug,
  assinante,
}) {
  return api.post(`/time`, {
    id: Number(teamId),
    idUsuario: Number(userId),
    cartola,
    escudo,
    camisa,
    nome,
    slug,
    assinante: Number(assinante),
  });
}

export function deleteTeam({ id, idUsuario }) {
  return api.delete('/time', { data: { id, idUsuario } });
}

export function updateTeamShield(userId) {
  return api.post(`/time/${userId}/atualizarEscudos`);
}

export function cancelPayment(id) {
  return api.post(`/pagar/cancelamento/${id}`);
}

export function verifySubscription(id) {
  return api.get(`usuario/${id}/validarAssinatura`);
}
