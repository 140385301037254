import styled from 'styled-components';
import { Container as MuiContainer } from '@material-ui/core';
import ScrollBar from 'react-perfect-scrollbar';

export const Container = styled(MuiContainer)`
  height: 80vh;
  max-height: 50rem;
`;

export const ListContainer = styled(ScrollBar)`
  max-height: 60vh;
`;

export const TeamContainer = styled.div`
  width: 100%;
  padding: 5px;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  width: 80vw;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
