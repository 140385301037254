import React, { useState, useEffect } from 'react';

import { Grid, Box, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Card from '../../components/Card';
import TeamInfo from '../../components/MyTeamComponents/TeamInfo';

import { Container } from './styles';
import { getGoalsDifference } from '../../services/catimbaStats/statistics';
import NoResults from '../../components/NoResults';

function ProsVSConcededGoals() {
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    setLoading(true);
    getGoalsDifference()
      .then((res) => {
        setTeams(res.data);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <Card>
            <Typography variant="body2">
              Veja os gols prós (GP), gols sofridos (GC) e saldo de gols (SG) de cada
              equipe, até a rodada atual.
              {/* <br />
              Clique para ver detalhes do time. */}
            </Typography>
          </Card>
        </Grid>
        {!loading && !teams.length && (
          <Grid item xs={12}>
            <NoResults />
          </Grid>
        )}
        {!loading
          ? teams.map((team) => (
              <Grid key={team.idClube} item xs={12}>
                <Box width="100%">
                  <TeamInfo
                    realTeam
                    teamName={team.nome}
                    teamShield={team.foto}
                    madeGoals={team.golsMarcados}
                    takenGoals={team.golsSofridos}
                    games={team.jogos}
                    goalsDifferent={team.saldoGols}
                  />
                </Box>
              </Grid>
            ))
          : [1, 2, 3, 4, 5, 6, 7, 8].map((e) => (
              <Grid key={e} item xs={12}>
                <Box width="100%">
                  <Skeleton variant="rect" width="100%" height={70} />
                </Box>
              </Grid>
            ))}
      </Grid>
    </Container>
  );
}

export default ProsVSConcededGoals;
