import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core';

import { MdDelete, MdEdit, MdRemoveRedEye } from 'react-icons/md';
import Pagination from '@material-ui/lab/Pagination';
import WarnMessage from '../../components/WarnMessage';

import { Action } from './styles';
import AdminActionButtons from '../../components/AdminActionButtons';
import Form from './Form';
import AdminTableContainer from '../../components/AdminTableContainer';
import ModalContainer from '../../components/ModalContainer';
import globalConst from '../../utils/globalConst';
import { getPaymentMethods } from '../../services/catimbaStats/adminPaymentMethods';
import { post, put, erase } from '../../services/catimbaStats/admin';
import AdminTableLoading from '../../components/AdminTableLoading';

export default function AdminPaymentMethods() {
  const [popup, setPopup] = useState({
    show: false,
    option: '',
    method: null,
  });
  const [methods, setMethods] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState();

  const handleGetMethods = useCallback(async () => {
    setLoading(true);

    getPaymentMethods({ page })
      .then((res) => {
        setMethods(res.data.content);
        setTotalPages(res.data.totalPages);
      })
      .finally(() => setLoading(false));
  }, [page]);

  useEffect(() => {
    handleGetMethods();
  }, [handleGetMethods]);

  const handleClosePopup = useCallback(
    (update) => {
      setPopup({
        show: false,
        option: '',
        method: null,
      });

      if (update) {
        handleGetMethods();
      }
    },
    [handleGetMethods]
  );

  const handleUpdateMethod = useCallback(
    (data) => {
      setLoading(true);

      put('/meioPagamento', data, () => handleClosePopup(true)).finally(() =>
        setLoading(false)
      );
    },
    [handleClosePopup]
  );

  const handleCreateMethod = useCallback(
    (data) => {
      setLoading(true);

      post('/meioPagamento', data, () => handleClosePopup(true)).finally(() =>
        setLoading(false)
      );
    },
    [handleClosePopup]
  );

  const handleDeleteMethod = useCallback(
    (id) => {
      setLoading(true);

      erase(`/meioPagamento/${id}`, () => handleClosePopup(true)).finally(() =>
        setLoading(false)
      );
    },
    [handleClosePopup]
  );

  return (
    <>
      <ModalContainer
        open={popup.show}
        onClose={handleClosePopup}
        position={popup.option === 'warn' && '5px'}
      >
        {popup.option === 'warn' ? (
          <WarnMessage
            onCancel={() =>
              setPopup({
                show: false,
                option: '',
                method: null,
              })
            }
            onConfirm={() => handleDeleteMethod(popup.method.id)}
          >
            Tem certeza que deseja excluir o Plano?
          </WarnMessage>
        ) : (
          <Form
            method={popup.method}
            update={handleUpdateMethod}
            create={handleCreateMethod}
            readOnly={popup.option === 'view'}
          />
        )}
      </ModalContainer>
      <AdminTableContainer
        title="Meios de pagamento"
        addFunc={() =>
          setPopup({
            ...popup,
            show: true,
            option: 'add',
          })
        }
      >
        <TableContainer>
          {!loading ? (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell align="right">Tipo</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {methods.map((method) => (
                  <TableRow key={method.id}>
                    <TableCell>{method.id}</TableCell>
                    <TableCell component="th" scope="method">
                      {method.nome}
                    </TableCell>
                    <TableCell align="right">
                      {globalConst.paymentMethodType.map(
                        (option) => option.value === method.tipo && option.label
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {method.status ? 'Habilitado' : 'Desabilitado'}
                    </TableCell>
                    <TableCell align="right">
                      <Action>
                        <AdminActionButtons
                          size="small"
                          title="Visualizar"
                          onClick={() =>
                            setPopup({
                              show: true,
                              option: 'view',
                              method,
                            })
                          }
                        >
                          <MdRemoveRedEye />
                        </AdminActionButtons>
                        <AdminActionButtons
                          size="small"
                          title="Editar"
                          onClick={() =>
                            setPopup({
                              show: true,
                              option: 'edit',
                              method,
                            })
                          }
                        >
                          <MdEdit />
                        </AdminActionButtons>
                        <AdminActionButtons
                          size="small"
                          title="Excluir"
                          onClick={() =>
                            setPopup({
                              show: true,
                              option: 'warn',
                              method,
                            })
                          }
                        >
                          <MdDelete />
                        </AdminActionButtons>
                      </Action>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <AdminTableLoading />
          )}
        </TableContainer>
        <Box display="flex" justifyContent="center" paddingBottom="1rem">
          {!!totalPages && (
            <Pagination
              count={totalPages}
              page={page + 1}
              onChange={(_, p) => setPage(p - 1)}
              siblingCount={0}
              boundaryCount={1}
            />
          )}
        </Box>
      </AdminTableContainer>
    </>
  );
}
