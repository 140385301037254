import React, { useCallback, useState } from 'react';
import { Typography, Divider, Box, CircularProgress } from '@material-ui/core';

import ModalContainer from '../ModalContainer';
import Card from '../Card';
import TeamData from '../MyTeamComponents/TeamData';

import { Container, ListContainer, TeamContainer, Wrapper } from './styles';
import SearchBar from '../SearchBar';
import { getCartolaTeams } from '../../services/catimbaStats/cartola';

const SingleTeam = ({ shield, teamName, teamOwner, onClick }) => (
  <TeamContainer onClick={onClick}>
    <TeamData shield={shield} teamName={teamName} teamOwner={teamOwner} />
  </TeamContainer>
);

function AddTeam({ onClose, open, onSelect }) {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleSearch = useCallback(async (value) => {
    setLoading(true);
    getCartolaTeams({ query: value })
      .then((res) => setTeams(res.data))
      .finally(() => setLoading(false));
  }, []);

  return (
    <ModalContainer onClose={onClose} open={open} position="5px">
      <Card>
        <Container maxWidth="xs">
          <Wrapper>
            <Typography variant="h6">Adicionar time</Typography>
            <Box margin="10px 0">
              <SearchBar
                placeholder="Pesquisar"
                onRequestSearch={handleSearch}
              />
            </Box>
            {!loading && !!teams.length ? (
              <ListContainer>
                {teams.map((team) => (
                  <>
                    <SingleTeam
                      key={team.time_id}
                      teamName={team.nome}
                      teamOwner={team.nome_cartola}
                      shield={team.url_escudo_png}
                      onClick={() => onSelect(team)}
                    />
                    <Divider />
                  </>
                ))}
              </ListContainer>
            ) : (
              <Box
                display="flex"
                flex={1}
                alignItems="center"
                justifyContent="center"
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography variant="body1" align="center">
                    Digite o nome do time que deseja adicionar
                  </Typography>
                )}
              </Box>
            )}
          </Wrapper>
        </Container>
      </Card>
    </ModalContainer>
  );
}

export default AddTeam;
