import React from 'react';

import { SubtitleColor, SubtitleText } from './styles';

function Subtitles({ color, subtitle }) {
  return (
    <>
      <SubtitleColor style={{ backgroundColor: color }} />
      <SubtitleText>{subtitle}</SubtitleText>
    </>
  );
}

export default Subtitles;
