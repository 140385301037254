import { createGlobalStyle } from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  html, body, #root {
    height: 100%;
    font-size: 62.5%;
    background-color: #efefef
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  ul {
    list-style: none;
  }

  * {
    text-decoration: none;
  }

  /*

  *:focus{
    outline: 0;
  }



  body {
    --webkit-font-smoothing: antialised;

  }

  body, input, button {
    font: 14px 'Roboto', sans-serif;
  }

  * {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  } */

  .Toastify__toast-container{
    font-size: 1.5rem;
  }
`;
