import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { MdDelete, MdEdit, MdRemoveRedEye } from 'react-icons/md';

import { toast } from 'react-toastify';
import AdminActionButtons from '../../components/AdminActionButtons';
import { Action } from '../../components/AdminActionButtons/styles';
import AdminTableContainer from '../../components/AdminTableContainer';
import ModalContainer from '../../components/ModalContainer';
import Form from './Form';
import {
  getPlans,
  updatePlan,
  createPlan,
  deletePlan,
} from '../../services/catimbaStats/adminPlans';
import WarnMessage from '../../components/WarnMessage';
import AdminTableLoading from '../../components/AdminTableLoading';

export default function AdminPlan() {
  const [popup, setPopup] = useState({
    show: false,
    option: '',
    plan: null,
  });
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleGetPlans = useCallback(() => {
    setLoading(true);

    getPlans()
      .then((res) => setPlans(res.data))
      .finally(() => setLoading(false));
  }, [setLoading]);

  useEffect(() => {
    handleGetPlans();
  }, [handleGetPlans]);

  const handleClose = useCallback(() => {
    setPopup({ show: false, option: '', plan: null });
  }, []);

  const handleUpdatePlan = useCallback(
    (data) => {
      setLoading(true);

      updatePlan(data)
        .then(() => {
          setPopup({
            show: false,
            option: '',
            plan: null,
          });
          handleGetPlans();
          toast.success('Plano atualizado com sucesso!');
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.mensagem
          ) {
            toast.warn(err.response.data.mensagem);
            return;
          }
          toast.warn('Ocorreu um erro');
        })
        .finally(() => setLoading(false));
    },
    [handleGetPlans]
  );

  const handleCreatePlan = useCallback(
    (data) => {
      setLoading(true);

      createPlan(data)
        .then(() => {
          setPopup({
            show: false,
            option: '',
            plan: null,
          });
          handleGetPlans();
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.mensagem
          ) {
            toast.warn(err.response.data.mensagem);
            return;
          }
          toast.warn('Ocorreu um erro');
        })
        .finally(() => setLoading(false));
    },
    [handleGetPlans]
  );

  const handleDeletePlan = useCallback(
    (id) => {
      setLoading(true);

      deletePlan(id)
        .then(() => {
          setPopup({
            show: false,
            option: '',
            plan: null,
          });
          handleGetPlans();
          toast.success('Plano deletado com sucesso!');
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.mensagem
          ) {
            toast.warn(err.response.data.mensagem);
            return;
          }
          toast.warn('Ocorreu um erro');
        })
        .finally(() => setLoading(false));
    },
    [handleGetPlans]
  );

  return (
    <>
      <ModalContainer
        open={popup.show}
        onClose={handleClose}
        position={popup.option === 'warn' && '5px'}
      >
        {popup.option === 'warn' ? (
          <WarnMessage
            onCancel={() =>
              setPopup({
                show: false,
                option: '',
                plan: null,
              })
            }
            onConfirm={() => handleDeletePlan(popup.plan.id)}
          >
            Tem certeza que deseja excluir o Plano?
          </WarnMessage>
        ) : (
          <Form
            plan={popup.plan}
            update={handleUpdatePlan}
            create={handleCreatePlan}
            readOnly={popup.option === 'view'}
          />
        )}
      </ModalContainer>
      <AdminTableContainer
        title="Planos"
        addFunc={() =>
          setPopup({
            ...popup,
            show: true,
            option: 'add',
          })
        }
      >
        <TableContainer>
          {!loading ? (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell size="small">ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell align="right">Valor</TableCell>
                  <TableCell align="right">Vigência</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plans.map((plan) => (
                  <TableRow key={plan.id}>
                    <TableCell size="small">{plan.id}</TableCell>
                    <TableCell component="th" scope="plan">
                      {plan.titulo}
                    </TableCell>
                    <TableCell align="right">
                      R${plan.valor && plan.valor.toFixed(2).replace('.', ',')}
                    </TableCell>
                    <TableCell align="right">
                      {plan.vigencia &&
                        plan.vigencia.split('-').reverse().join('/')}
                    </TableCell>
                    <TableCell align="right">
                      <Action>
                        <AdminActionButtons
                          size="small"
                          title="Visualizar"
                          onClick={() =>
                            setPopup({
                              show: true,
                              option: 'view',
                              plan,
                            })
                          }
                        >
                          <MdRemoveRedEye />
                        </AdminActionButtons>
                        <AdminActionButtons
                          size="small"
                          title="Editar"
                          onClick={() => {
                            setPopup({
                              ...popup,
                              plan,
                              show: true,
                            });
                          }}
                        >
                          <MdEdit />
                        </AdminActionButtons>
                        <AdminActionButtons
                          size="small"
                          title="Excluir"
                          onClick={() => {
                            setPopup({
                              ...popup,
                              plan,
                              option: 'warn',
                              show: true,
                            });
                          }}
                        >
                          <MdDelete />
                        </AdminActionButtons>
                      </Action>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <AdminTableLoading />
          )}
        </TableContainer>
      </AdminTableContainer>
    </>
  );
}
